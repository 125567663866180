const geschichte = {
  firestorePath: 'Spiele/{spielID}',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'Geschichte',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getAlle: state => {
      return state.data
    },
    getGeschichte: state => {
      return state.data.geschichte
    },
    getWaehrungen: state => {
      return state.data.waehrungen
    },
    getAktWaehrung: state => {
      let temp =  state.data.waehrungen[state.data.aktiveWaehrung]
      temp = temp.sort((a, b) => (a.kurs < b.kurs ? 1 : -1));
      console.log(temp)
      return temp
    }

  },
  mutations: {},
  actions: {},
}

export default geschichte
<template>
  <v-card max-width="150px">
    <v-card-title>Würfel <v-spacer></v-spacer> <v-chip color="grey-lighten 3">{{ausgabe}}</v-chip> </v-card-title>
    <v-card-text>
      <v-row dense justify="space-between">
        <v-col v-for="zahl in [1,2,3,4,5,6,7,8,9,,10,,]" :key="zahl" cols="4">
          <v-btn @click="ausgabeBerechnen(zahl)" fab x-small v-if="zahl>0">{{zahl}}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable */
export default {
  data:()=>({
    nochmal:false,
    ausgabe:0
  }),
  methods:{
    ausgabeBerechnen(zahl){
      if (this.nochmal){
        if(zahl < 10){
          this.ausgabe+= zahl
          this.nochmal = false
          this.$emit('neuerWuerfelEingabe',this.ausgabe)
        }else{
          this.ausgabe += zahl
        }
      }else{
        if(zahl < 10){
          this.ausgabe = zahl
          this.$emit('neuerWuerfelEingabe',this.ausgabe)
        }else{
          this.ausgabe = zahl
          this.nochmal = true
        }
      }
    }
  }
}
</script>

<template>
  <v-container>
    <v-row justify="center" id="Avatar Level">
      <v-avatar color="indigo" size="62">
        <span class="white--text headline">{{ spieler.level }}</span>
      </v-avatar>
    </v-row>

    <v-row>
      <v-col cols="12" id="Persönliches">
        <v-card outlined color="rgb(255,255,255,0.8)">
          <v-card-title></v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="col-4 col-sm-4">
                <v-text-field v-model="spieler['name']" label="Name" readonly outlined></v-text-field>
              </v-col>
              <v-col class="col-4 col-sm-4">
                <v-text-field v-model="spieler['volk']" label="Volk" readonly outlined></v-text-field>
              </v-col>
              <v-col class="col-4 col-sm-4">
                <v-text-field v-model="spieler['beruf']" label="Beruf" readonly outlined></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12 col-sm-4" id="Attribute">
        <v-card outlined>
          <v-card-title>Attribute</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12">
                <v-chip large outlined class="mb-2" label>
                  Stärke:
                  <v-chip>{{ spieler.boni.st }}</v-chip>
                </v-chip>
              </v-col>
              <v-col cols="12">
                <v-chip large outlined class="mb-2" label>
                  Geschicklichkeit:
                  <v-chip>{{ spieler.boni.ge }}</v-chip>
                </v-chip>
              </v-col>
              <v-col cols="12">
                <v-chip large outlined class="mb-2" label>
                  Konstitution:
                  <v-chip>{{ spieler.boni.ko }}</v-chip>
                </v-chip>
              </v-col>
              <v-col cols="12">
                <v-chip large outlined class="mb-2" label>
                  Intelligenz:
                  <v-chip>{{ spieler.boni.in }}</v-chip>
                </v-chip>
              </v-col>
              <v-col cols="12">
                <v-chip large outlined class="mb-2" label>
                  Charisma:
                  <v-chip>{{ spieler.boni.ch }}</v-chip>
                </v-chip>
              </v-col>
            </v-row>
            <!-- <v-card>
              <th>KO:</th>
              <p align="right">{{ spieler.boni.ko }}</p>
            </v-card>-->
          </v-card-text>
        </v-card>
      </v-col>

      <!-- <v-col class="col-12 col-sm-4" id="Geld">
        <v-card outlined>
          <v-card-title>Geld</v-card-title>
          <v-card-text>
            <v-chip large outlined class="mb-2 d-flex justify-center">
              <img src="@/assets/Goldfalke.png" alt="Goldfalke" height="50px" width="50px" />
              {{ geld[0].toString().replace(",", "") + " GF" }}
            </v-chip>
            <v-chip large outlined class="mb-2 d-flex justify-center">
              <img src="@/assets/Triontaler.png" alt="Triontaler" height="50px" width="50px" />
              {{ geld[1] + " TT" }}
            </v-chip>
            <v-chip large outlined class="mb-2 d-flex justify-center">
              <img src="@/assets/Kupferling.png" alt="Kupferling" height="50px" width="50px" />
              {{ geld[2] + " KL" }}
            </v-chip>
            <v-chip large outlined class="mb-2 d-flex justify-center">
              <img src="@/assets/Muena.png" alt="Muena" height="50px" width="50px" />
              {{ geld[3] + " MU" }}
            </v-chip>
          </v-card-text>
        </v-card>
      </v-col> -->

      <v-col class="col-12 col-sm-4" id="GoldNeu">
        <v-card>
          <v-card-title>Geld</v-card-title>
          <v-card-text>
            <v-chip large outlined class="mb-2 d-flex justify-center" v-for="(item, index) in waehrung" :key="index">
              <v-img :src="item.imgurl" :alt="item.name" max-width="50px" />
              {{
                index != 0
                  ? parseInt((spieler.geld % waehrung[index - 1].kurs) / item.kurs) + " " + item.name
                  : parseInt(spieler.geld / item.kurs) + " " + item.name
              }}
            </v-chip>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12 col-sm-4" id="Stats">
        <v-card outlined>
          <v-card-title>Stats</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col class="col-6 col-sm-6">
                <v-card>
                  <th>TP:</th>
                  <p align="right">
                    {{ spieler["trefferpunkteAkt"] + "/" + spieler["trefferpunkteGesamt"] }}
                  </p>
                </v-card>
              </v-col>
              <v-col class="col-6 col-sm-6">
                <v-card>
                  <th>Rüstung:</th>
                  <p align="right">{{ spieler["ruestung"] }}</p>
                </v-card>
              </v-col>
              <v-col class="col-6 col-sm-6">
                <v-card>
                  <th>MP:</th>
                  <p align="right">
                    {{ spieler["magiepunkteAkt"] + "/" + spieler["magiepunkteGesamt"] }}
                  </p>
                </v-card>
              </v-col>
              <v-col class="col-6 col-sm-6">
                <v-card>
                  <th>AP:</th>
                  <p align="right">{{ ausbildungspunkte }}</p>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12" id="FertigkeitsTabelle">
        <v-card outlined>
          <v-card-title>Fertigkeiten</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="raenge"
              :items="fertigkeitsraenge"
              :mobile-breakpoint="0"
              :key="keyFertigkeiten"
              hide-default-footer
              disable-sort
              :disable-pagination="temp"
              calculate-widths
              :items-per-page="50"
              v-if="spieler.levelAufstieg"
            >
              <template v-for="(header, index) in raenge.slice(2)" v-slot:[generateName(header)]="{ item }">
                <v-row :key="index" justify="center">
                  <v-checkbox
                    v-for="(wert, i) in item[header.value]"
                    :key="i"
                    v-model="item[header.value][i]"
                    :disabled="disabled(item, header.value, raenge[index + 1].value, raenge[index].value, i)"
                    :readonly="readonly(item, header.value, raenge[index + 1].value, raenge[index].value, i)"
                    :success="success(item, header.value, i)"
                    @change="checkboxClicked(!wert, item.kosten, item, header.value, i)"
                  ></v-checkbox>
                  <!-- :readonly="readonly(item,header.value,raenge[index+1].value,raenge[index].value,i)" -->
                </v-row>
              </template>
            </v-data-table>

            <v-row v-if="spieler.levelAufstieg">
              <v-spacer></v-spacer>
              <v-btn @click="neueFertigkeitFunc('Neue Spruchliste')">Neue Spruchliste</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="neueFertigkeitFunc('Neue Waffe')">Neue Waffe</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="neueFertigkeitFunc('Neues Wissen')">Neues Wissen</v-btn>
              <v-spacer></v-spacer>
            </v-row>

            <v-data-table
              :headers="raengeNurWerte"
              :items="nurRaenge"
              disable-sort
              hide-default-footer
              v-if="!spieler.levelAufstieg"
              :mobile-breakpoint="0"
              dense
              :items-per-page="50"
            ></v-data-table>
          </v-card-text>
          <v-card-actions v-if="spieler.levelAufstieg">
            <v-spacer></v-spacer>
            <v-btn @click="bestaetigenFertigkeiten">Auswahl bestätigen</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col class="col-12" id="Waffen">
        <v-card outlined>
          <v-card-title>Waffen</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="waffenHeader"
              :items="spieler.waffen"
              disable-sort
              hide-default-footer
              :mobile-breakpoint="0"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12" id="Ruestung">
        <v-card outlined>
          <v-card-title>Rüstungen</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="ruestungenHeader"
              :items="spieler.ruestungen"
              disable-sort
              hide-default-footer
              :mobile-breakpoint="0"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12" id="Sonstiges">
        <v-card>
          <v-card-title>Sonstiges</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="sonstigeHeader"
              :items="sonstigeItems"
              :items-per-page="-1"
              hide-default-footer
              :mobile-breakpoint="0"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12" id="Zauber">
        <v-card>
          <v-card-title>Zauber</v-card-title>
          <v-card-text>
            <div v-for="(item, index) in spieler.spruchlisten" :key="index">
              <v-data-table
                :caption="index"
                :headers="[
                  { text: 'Rang', value: 'rang' },
                  { text: 'Name', value: 'name' },
                  { text: 'Beschreibung', value: 'beschreibung' },
                  { text: 'Reichweite', value: 'reichweite' },
                  { text: 'Gezielt', value: 'gezielt' }
                ]"
                :items="item.zauber"
                hide-default-footer
                disable-sort
                :mobile-breakpoint="0"
              >
                <template v-slot:item.beschreibung="{ item }">
                  {{ einsetzen(item, item.beschreibung) }}
                </template>

                <template v-slot:item.gezielt="{ item }">
                  <v-checkbox readonly v-model="item.gezielt"></v-checkbox>
                </template>
              </v-data-table>
              <v-divider></v-divider>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="neueFertigkeit.open" max-width="450">
      <v-card>
        <v-card-title>{{ neueFertigkeit.typ }}</v-card-title>
        <v-card-text v-if="neueFertigkeit.typ != 'Neues Wissen'">
          <v-select :items="neueFertigkeit.auswahl" v-model="neueFertigkeit.ausgewaehlt"></v-select>
        </v-card-text>

        <v-card-text v-else>
          <v-text-field v-model="neueFertigkeit.ausgewaehlt"></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="hinzufuegen(neueFertigkeit.typ != 'Neues Wissen' ? true : false)">Bestätigen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :fullscreen="uebernehmen != undefined" v-model="neuerSpielerErstellen" hide-overlay persistent>
      <v-card>
        <v-container v-if="uebernehmen == undefined" fluid fill-height>
          <v-card max-width="300" class="ma-auto">
            <v-card-title>Auswählen</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6"><v-btn @click="uebernehmen = true" tile outlined>Übernehmen</v-btn></v-col>
                <v-col cols="6"><v-btn @click="uebernehmen = false" tile outlined>Erstellen</v-btn></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
        <SpielerUebernehmen v-else-if="uebernehmen" />
        <SpielerErstellen v-else />
      </v-card>
    </v-dialog>

    <v-dialog :persistent="dialogWuerfel" v-model="dialogWuerfelOpen" max-width="200">
      <v-card>
        <v-card-title class="d-flex justify-center">Würfeln</v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-avatar color="green" class="ma-auto" size="48">{{ spieler.wuerfelErgebnis }}</v-avatar>
        </v-card-text>
        <v-card-text class="d-flex justify-center">
          <v-btn @click="wuerfelErgebnisAbschicken()" :disabled="!dialogWuerfel">Würfeln</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SpielerErstellen from "../components/SpielerErstellen.vue";
import SpielerUebernehmen from "../components/SpielerUebernehmen.vue";

export default {
  components: {
    SpielerUebernehmen,
    SpielerErstellen
  },
  data: () => ({
    dialogWuerfel: true,
    dialogWuerfelOpen: false,
    uebernehmen: undefined,
    neueFertigkeit: {
      open: false,
      typ: "",
      auswahl: [],
      ausgewaehlt: "",
      kosten: 0
    },
    bereitsBearbeitet: {},
    bearbeiten: false,
    loaded: false,
    raenge: [
      {
        text: "Fertigkeit",
        value: "name",
        width: "170px",
        divider: true,
        fixed: true
      },
      {
        text: "Kosten",
        value: "kosten",
        align: "right",
        width: "80px",
        divider: true
      },
      {
        text: "1",
        value: "eins",
        align: "center",
        width: "41px",
        divider: true
      },
      {
        text: "2",
        value: "zwei",
        align: "center",
        width: "41px",
        divider: true
      },
      {
        text: "3",
        value: "drei",
        align: "center",
        width: "41px",
        divider: true
      },
      {
        text: "4",
        value: "vier",
        align: "center",
        width: "41px",
        divider: true
      },
      {
        text: "5",
        value: "fuenf",
        align: "center",
        width: "41px",
        divider: true
      },
      {
        text: "6",
        value: "sechs",
        align: "center",
        width: "41px",
        divider: true
      },
      {
        text: "7",
        value: "sieben",
        align: "center",
        width: "73px",
        divider: true
      },
      {
        text: "8",
        value: "acht",
        align: "center",
        width: "73px",
        divider: true
      },
      {
        text: "9",
        value: "neun",
        align: "center",
        width: "73px",
        divider: true
      },
      {
        text: "10",
        value: "zehn",
        align: "center",
        width: "73px",
        divider: true
      },
      {
        text: "11",
        value: "elf",
        align: "center",
        width: "140px",
        divider: true
      },
      {
        text: "12",
        value: "zwoelf",
        align: "center",
        width: "140px",
        divider: true
      }
    ],
    fertigkeitsraenge: [],
    veraenderbareFertigkeitsraenge: {},
    fertigkeitsraengeKontrollgruppe: [],
    raengeNurWerte: [
      { text: "Fertigkeit", value: "name" },
      { text: "Rang", value: "rang", align: "center" }
    ],
    attr: [],
    temp: true,
    ruestungenHeader: [
      { text: "Rüstung", value: "name" },
      { text: "Mind. St", value: "minST", align: "right" },
      { text: "Rüstungsbonus", value: "ruestungsbonus", align: "right" },
      { text: "Manöver", value: "manoever", align: "right" },
      { text: "Gewicht", value: "kg", align: "right" }
    ],
    waffenHeader: [
      { text: "Waffe", value: "name" },
      { text: "Waffengruppe", value: "gruppe", align: "right" },
      { text: "Mind.ST", value: "minST", align: "right" },
      { text: "INI", value: "ini", align: "right" },
      { text: "Attribut", value: "attribut", align: "right" },
      { text: "KB", value: "kb", align: "right" },
      { text: "Schaden", value: "schaden", align: "right" },
      { text: "Besonderes", value: "beschreibung", align: "right" }
    ],
    sonstigeHeader: [
      { text: "Anzahl", value: "anzahl" },
      { text: "Name", value: "name" },
      { text: "Beschreibung", value: "beschreibung" }
    ],

    keyFertigkeiten: false,
    ausbildungspunkte: 0
  }),

  watch: {
    "spieler.levelAufstieg": function() {
      //eslint-disable-next-line
      this.fertigkeitsTabelleErstellen();
    },
    "spieler.wuerfeln": function(val) {
      if (val) {
        this.dialogWuerfelOpen = true;
        this.dialogWuerfel = true;
      }
    }
  },

  computed: {
    spieler: {
      get() {
        return this.$store.getters["SpielerEinzeln/getSpieler"];
        // let temp = window.location.href.split("/").reverse()[0];
        // temp = temp.replace("%20", " ");
        // console.log(temp);
        // return this.$store.getters["Spieler/getSpieler"](temp);
      },
      set(val) {
        console.log(val);
      }
    },
    nurRaenge() {
      let ergebnis = [];
      let arr = Object.keys(this.spieler.fertigkeitsraenge);
      arr.sort();
      arr.forEach(element => {
        ergebnis.push({
          name: element,
          rang: this.spieler.fertigkeitsraenge[element].rang
        });
      });
      return ergebnis;
    },
    waehrung() {
      let data = this.$store.getters["Geschichte/getAlle"];
      let aktiv = data.aktiveWaehrung;
      let waehrung = data.waehrungen[aktiv];
      waehrung.sort((a, b) => (a.kurs < b.kurs ? 1 : -1));
      return waehrung;
    },
    geld() {
      let temp = this.spieler.geld + "";
      temp = temp.split("");
      let laenge = temp.length;
      for (let i = 0; i < 4 - laenge; i++) {
        temp.unshift("0");
        laenge = 4;
      }
      return [
        temp.slice(0, laenge - 3),
        temp.slice(laenge - 3, laenge - 2),
        temp.slice(laenge - 2, laenge - 1),
        temp.slice(laenge - 1)
      ];
    },
    sonstigeItems() {
      let ergebnis = [];
      let namensliste = [];
      let temp = this.spieler.sonstigeGegenstaende;
      for (let i in temp) {
        if (namensliste.indexOf(temp[i].name) == -1) {
          ergebnis.push({
            anzahl: 1,
            name: temp[i].name,
            beschreibung: temp[i].beschreibung
          });
          namensliste.push(temp[i].name);
        } else {
          ergebnis[namensliste.indexOf(temp[i].name)].anzahl++;
        }
      }
      return ergebnis;
    },
    neuerSpielerErstellen: {
      get() {
        if (this.spieler.fertigErstellt) {
          return false;
        } else {
          return true;
        }
      },
      set(val) {
        // this.spieler.fertigErstellt = !val
        console.log(val);
      }
    }
  },

  created() {
    this.ausbildungspunkte = this.spieler.ausbildungspunkte;
    this.$store.commit("setHintergrundbild", "Pergament");
    if (this.spieler.wuerfeln) {
      (this.dialogWuerfelOpen = true), (this.dialogWuerfel = true);
    }
    if (this.spieler.levelAufstieg) {
      //eslint-disable-next-line
      this.fertigkeitsTabelleErstellen();
    }
  },

  methods: {
    getZufallszahl() {
      let temp = Math.ceil(Math.random() * 10);
      while (temp % 10 == 0) {
        temp += Math.ceil(Math.random() * 10);
      }
      return temp;
    },
    generateName(name) {
      return "item." + name.value;
    },
    stringNumberParseInt(name) {
      let db = {
        null: 0,
        eins: 1,
        zwei: 2,
        drei: 3,
        vier: 4,
        fuenf: 5,
        sechs: 6,
        sieben: 7,
        acht: 8,
        neun: 9,
        zehn: 10,
        elf: 11,
        zwoelf: 12
      };
      return db[name];
    },
    bestaetigenFertigkeiten() {
      // Waffen KB und so
      if (this.ausbildungspunkte >= 0) {
        let list = {};
        for (let i in this.fertigkeitsraenge) {
          let a = this.fertigkeitsraenge[i];
          let rang = "null";
          let zwischenRang = 0;
          for (let x in a) {
            zwischenRang = 0;
            if (a[x][a[x].length - 1] == true) {
              rang = x;
            } else {
              for (let y in a[x]) {
                if (a[x][y]) {
                  zwischenRang += 1;
                }
              }
            }
          }
          rang = this.stringNumberParseInt(rang);
          list[this.fertigkeitsraenge[i].name] = {
            kosten: a.kosten,
            rang: rang,
            zwischenRang: zwischenRang
          };
        }
        this.spieler.fertigkeitsraenge = list;
        let namenFertigkeiten = Object.keys(this.spieler.fertigkeitsraenge);
        namenFertigkeiten.forEach(element => {
          if (element.search("Spruchliste") >= 0) {
            let spruchlistenName = element.split('"')[1];
            if (this.spieler.spruchlisten[spruchlistenName] == undefined) {
              this.spieler.spruchlisten[spruchlistenName] = { zauber: [] };
            }
            if (
              this.spieler.spruchlisten[spruchlistenName].zauber.length < this.spieler.fertigkeitsraenge[element].rang
            ) {
              let spruch = this.$store.getters["Tabellen/getSpruchlisten"];
              spruch.forEach(element => {
                if (element.name == spruchlistenName) {
                  spruch = element;
                }
              });
              this.spieler.spruchlisten[spruchlistenName].zauber.push(
                spruch.zauber[this.spieler.spruchlisten[spruchlistenName].zauber.length]
              );
              if (
                this.spieler.spruchlisten[spruchlistenName].zauber.length < this.spieler.fertigkeitsraenge[element].rang
              ) {
                this.spieler.spruchlisten[spruchlistenName].zauber.push(
                  spruch.zauber[this.spieler.spruchlisten[spruchlistenName].zauber.length]
                );
              }
            }
          } else if (element.search("Waffe") >= 0) {
            // console.log("Waffe");
          }
        });
        let rangMagie = this.spieler.fertigkeitsraenge["Magie entwickeln"].rang;
        let berufeListe = ["Priester", "Priesterin", "Schamane", "Schamanin", "Barde", "Bardin"];
        if (berufeListe.indexOf(this.spieler.beruf) == -1) {
          this.spieler.magiepunkteGesamt = (this.spieler.boni.in + 3) * rangMagie;
        } else {
          this.spieler.magiepunkteGesamt = (this.spieler.boni.ch + 3) * rangMagie;
        }
        this.spieler.trefferpunkteGesamt = (this.spieler.berufTP + this.spieler.boni.ko) * this.spieler.level;
        if (this.spieler.volk == "Zwerg") {
          this.spieler.trefferpunkteGesamt += 2;
        }
        this.spieler.levelAufstieg = false;
        this.spieler.ausbildungspunkte = this.ausbildungspunkte;
        this.spielerWerteUpdaten();

        // this.$store.dispatch("Spieler/set", this.spieler);
        this.$store.dispatch("SpielerEinzeln/set", this.spieler);
      } else {
        this.$store.dispatch("setSnackbar", { text: "Zu wenig Ausbildungspunkte", color: "error", time: 4 });
      }
    },
    veraenderbar() {
      let ergebnis = {};
      for (let i in this.fertigkeitsraenge) {
        let temp = this.fertigkeitsraenge[i];
        Start: for (let a in temp) {
          if (a != "kosten" && a != "name") {
            for (let b in temp[a]) {
              if (!temp[a][b]) {
                b = parseInt(b);
                if (typeof this.fertigkeitsraenge[i].kosten == typeof 1) {
                  ergebnis[this.fertigkeitsraenge[i].name] = [[a, b]];
                } else {
                  if (temp[a].length == b + 1) {
                    let keyliste = Object.keys(temp);
                    ergebnis[this.fertigkeitsraenge[i].name] = [
                      [a, b],
                      [keyliste[keyliste.indexOf(a) + 1], 0]
                    ];
                  } else {
                    ergebnis[this.fertigkeitsraenge[i].name] = [
                      [a, b],
                      [a, b + 1]
                    ];
                  }
                }
                break Start;
              }
            }
          }
        }
      }
      this.veraenderbareFertigkeitsraenge = ergebnis;
    },
    waffenUpdaten() {
      let waffen = this.spieler.waffen;
      for (let i in waffen) {
        // console.log(waffen[i]);
        let bonus = -10;
        for (let wert in waffen[i].attribut) {
          if (this.spieler.boni[waffen[i].attribut[wert].toLowerCase()] > bonus) {
            bonus = this.spieler.boni[waffen[i].attribut[wert].toLowerCase()];
          }
        }
        let fertigkeit = -2;
        for (let wert in waffen[i].gruppe) {
          for (let zeile in this.spieler.fertigkeitsraenge) {
            if (zeile.search(waffen[i].gruppe[wert]) >= 0) {
              if (
                this.spieler.fertigkeitsraenge[zeile].rang > fertigkeit &&
                this.spieler.fertigkeitsraenge[zeile].rang > 0
              ) {
                fertigkeit = this.spieler.fertigkeitsraenge[zeile].rang;
              }
            }
          }
        }
        if (this.spieler.beruf == "Krieger") {
          if (fertigkeit < 0) {
            fertigkeit = 0;
          }
        }
        if (this.spieler.beruf == "Waldläufer") {
          if (waffen[i].gruppe == "Armbrust" || waffen[i].gruppe == "Bogen") {
            if (fertigkeit < 0) {
              fertigkeit = 0;
            }
          }
        }
        waffen[i].kb = parseInt(fertigkeit) + parseInt(bonus);
        waffen[i].ini = parseInt(waffen[i]["iniBonus"]) + parseInt(this.spieler.boni.ge);
      }
      this.spieler.waffen = waffen;
    },
    ruestungenUpdaten() {
      let ruestungswert = 5;
      for (let i in this.spieler.ruestungen) {
        ruestungswert += parseInt(this.spieler.ruestungen[i].ruestungsbonus);
      }
      if (this.spieler.beruf == "Dieb") {
        if (ruestungswert == 5) {
          ruestungswert += this.spieler.fertigkeitsraenge.Athletik.rang;
        }
      }
      if (this.spieler.volk == "Gnom" || this.spieler.volk == "Halbling" || this.spieler.volk == "Zwerg") {
        ruestungswert += 1;
      }
      this.spieler.ruestung = ruestungswert;
    },
    spielerWerteUpdaten() {
      this.waffenUpdaten();
      this.ruestungenUpdaten();
      // Ursprünglicher Code zum Updaten
      // let waffen = this.spieler.waffen
      // for (let i in waffen){
      //   console.log(waffen[i])
      //   let bonus = -10
      //   for (let wert in waffen[i].attribut){
      //     if (this.spieler.boni[waffen[i].attribut[wert].toLowerCase()] > bonus){
      //       bonus = this.spieler.boni[waffen[i].attribut[wert].toLowerCase()]
      //     }
      //   }
      //   let fertigkeit = -2
      //   for (let wert in waffen[i].gruppe){
      //     for (let zeile in this.spieler.fertigkeitsraenge){
      //       if (zeile.search(waffen[i].gruppe[wert]) >= 0){
      //         if (this.spieler.fertigkeitsraenge[zeile].rang > fertigkeit){
      //           fertigkeit = this.spieler.fertigkeitsraenge[zeile].rang
      //         }
      //       }
      //     }
      //   }
      //   if (this.spieler.beruf == "Krieger"){
      //     if (fertigkeit < 0){
      //       fertigkeit = 0
      //     }
      //   }
      //   if (this.spieler.beruf == "Waldläufer"){
      //     if (waffen[i].gruppe == "Armbrust" || waffen[i].gruppe == "Bogen"){
      //       if (fertigkeit < 0){
      //         fertigkeit = 0
      //       }
      //     }
      //   }
      //   waffen[i].kb = parseInt(fertigkeit) + parseInt(bonus)
      //   waffen[i].ini = parseInt(waffen[i]["iniBonus"]) + parseInt(this.spieler.boni.ge)
      // }
      // let ruestungswert = 5
      // for (let i in this.spieler.ruestungen){
      //   ruestungswert += parseInt(this.spieler.ruestungen[i].ruestungsbonus)
      // }
      // if (this.spieler.beruf == "Dieb"){
      //   if (ruestungswert == 5){
      //     ruestungswert += this.fertigkeitsraenge.Athletik.rang
      //   }
      // }
      // if (this.spieler.volk == "Gnom" || this.spieler.volk == "Halbling"){
      //   ruestungswert += 1
      // }
      // this.spieler.ruestung = ruestungswert;
      // this.spieler.waffen = waffen
      // console.log("Hier werden dann letztendlich alle Sachen geupdated");
    },

    fertigkeitsTabelle() {
      let list = [];
      // console.log(this.spieler.fertigkeitsraenge)
      let names = Object.keys(this.spieler.fertigkeitsraenge);
      names.sort();
      names.forEach(name => {
        let fertigkeitsrang = this.spieler.fertigkeitsraenge[name];
        let temp = {
          name: name,
          kosten: fertigkeitsrang.kosten
        };
        let zahlZuWort = [
          "eins",
          "zwei",
          "drei",
          "vier",
          "fuenf",
          "sechs",
          "sieben",
          "acht",
          "neun",
          "zehn",
          "elf",
          "zwoelf"
        ];
        for (let i = 0; i < 12; i++) {
          if (fertigkeitsrang.rang > i) {
            if (i < 6) {
              temp[zahlZuWort[i]] = [true];
            } else if (i < 10) {
              temp[zahlZuWort[i]] = [true, true];
            } else {
              temp[zahlZuWort[i]] = [true, true, true, true];
            }
          } else if (fertigkeitsrang == i) {
            if (i < 6) {
              temp[zahlZuWort[i]] = [false];
            } else if (i < 10) {
              if (fertigkeitsrang.zwischenRang == 1) temp[zahlZuWort[i]] = [true, false];
            } else {
              if (fertigkeitsrang.zwischenRang == 1) {
                temp[zahlZuWort[i]] = [true, false, false, false];
              }
              if (fertigkeitsrang.zwischenRang == 2) {
                temp[zahlZuWort[i]] = [true, true, false, false];
              }
              if (fertigkeitsrang.zwischenRang == 3) {
                temp[zahlZuWort[i]] = [true, true, true, false];
              }
            }
          } else {
            if (i < 6) {
              temp[zahlZuWort[i]] = [false];
            } else if (i < 10) {
              temp[zahlZuWort[i]] = [false, false];
            } else {
              temp[zahlZuWort[i]] = [false, false, false, false];
            }
          }
        }
        list.push(temp);
        //   list.push({
        //     name: name,
        //     kosten: fertigkeitsrang.kosten,
        //     eins: fertigkeitsrang[0],
        //     zwei: fertigkeitsrang[1],
        //     drei: fertigkeitsrang[2],
        //     vier: fertigkeitsrang[3],
        //     fuenf: fertigkeitsrang[4],
        //     sechs: fertigkeitsrang[5],
        //     sieben: fertigkeitsrang[6],
        //     acht: fertigkeitsrang[7],
        //     neun: fertigkeitsrang[8],
        //     zehn: fertigkeitsrang[9],
        //     elf: fertigkeitsrang[10],
        //     zwoelf: fertigkeitsrang[11],
        //   });
      });
      // for (let name in names) {
      //   let a = Object.values(this.spieler.fertigkeitsraenge[names[name]]);
      //   a = a.slice(0, a.length - 1);
      //   list.push({
      //     name: names[name],
      //     kosten: a[12],
      //     eins: a[0],
      //     zwei: a[1],
      //     drei: a[2],
      //     vier: a[3],
      //     fuenf: a[4],
      //     sechs: a[5],
      //     sieben: a[6],
      //     acht: a[7],
      //     neun: a[8],
      //     zehn: a[9],
      //     elf: a[10],
      //     zwoelf: a[11],
      //   });
      // }
      return list;
    },

    disabled(item, header, headerDavor, header2Davor, index) {
      let temp = this.veraenderbareFertigkeitsraenge[item.name];
      let ergebnis = this.kleiner(temp[temp.length - 1][0], header);
      if (ergebnis == -1) {
        return false;
      } else if (ergebnis == 0) {
        if (temp[temp.length - 1][1] >= index) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    readonly(item, header, headerDavor, header2Davor, index) {
      let temp = this.veraenderbareFertigkeitsraenge[item.name];
      let ergebnis = this.kleiner(temp[0][0], header);
      if (ergebnis == 1) {
        return false;
      } else if (ergebnis == 0) {
        if (temp[0][1] > index) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    success(item, header, index) {
      let temp = this.veraenderbareFertigkeitsraenge[item.name];
      for (let i in temp) {
        if (temp[i][0] == header && temp[i][1] == index) {
          return true;
        }
      }
      return false;
    },

    kleiner(wertA, wertB) {
      if (this.stringNumberParseInt(wertA) < this.stringNumberParseInt(wertB)) {
        return 1;
      } else if (this.stringNumberParseInt(wertA) == this.stringNumberParseInt(wertB)) {
        return 0;
      } else {
        return -1;
      }
    },

    checkboxClicked(val, kosten, item, header, index) {
      // console.log("Val", val, "Kosten", kosten, "item", item, "header", header, "index", index)
      if (typeof kosten == typeof 1) {
        if (val) {
          this.ausbildungspunkte -= parseInt(kosten);
        } else {
          this.ausbildungspunkte += parseInt(kosten);
        }
      } else {
        if (
          this.veraenderbareFertigkeitsraenge[item.name][0][0] == header &&
          this.veraenderbareFertigkeitsraenge[item.name][0][1] == index
        ) {
          if (val) {
            this.ausbildungspunkte -= parseInt(kosten[0]);
          } else {
            this.ausbildungspunkte += parseInt(kosten[0]);
          }
        } else {
          if (val) {
            this.ausbildungspunkte -= parseInt(kosten[1]);
          } else {
            this.ausbildungspunkte += parseInt(kosten[1]);
          }
        }
      }
    },

    neueFertigkeitFunc(typ) {
      this.neueFertigkeit.typ = typ;
      this.neueFertigkeit.auswahl = [];
      let temp = [];
      this.fertigkeitsraenge.forEach(element => {
        temp.push(element.name);
      });
      if (typ == "Neue Spruchliste") {
        this.neueFertigkeit.kosten = this.spieler.berufZauber;
        this.$store.getters["Tabellen/getSpruchlisten"].forEach(spruchliste => {
          if (
            spruchliste.fuer.indexOf(this.spieler.beruf) >= 0 &&
            temp.indexOf('Spruchliste "' + spruchliste.name + '"') == -1
          ) {
            this.neueFertigkeit.auswahl.push('Spruchliste "' + spruchliste.name + '"');
          }
        });
      }
      if (typ == "Neue Waffe") {
        this.neueFertigkeit.kosten = this.spieler.berufWaffe;
        this.$store.getters["Tabellen/getWaffengruppen"].forEach(waffe => {
          if (temp.indexOf('Waffe "' + waffe + '"') == -1) {
            this.neueFertigkeit.auswahl.push('Waffe "' + waffe + '"');
          }
        });
      }
      if (typ == "Neues Wissen") {
        this.neueFertigkeit.kosten = this.spieler.berufWissen;
        this.neueFertigkeit.auswahl = "";
      }
      this.neueFertigkeit.open = true;
    },
    hinzufuegen(bool) {
      let name = "";
      if (bool) {
        name = this.neueFertigkeit.ausgewaehlt;
      } else {
        name = 'Wissen "' + this.neueFertigkeit.ausgewaehlt + '"';
      }
      this.spieler.fertigkeitsraenge[name] = {
        kosten: this.neueFertigkeit.kosten,
        rang: 0,
        zwischenRang: 0
      };
      this.fertigkeitsTabelleErstellen();
      this.neueFertigkeit.open = false;
    },
    fertigkeitsTabelleErstellen() {
      this.ausbildungspunkte = this.spieler.ausbildungspunkte;
      this.fertigkeitsraenge = this.fertigkeitsTabelle();
      this.veraenderbar();
      this.keyFertigkeiten = !this.keyFertigkeiten;
    },
    einsetzen(item, text) {
      let liste = this.werteListe(text);
      for (let i in liste) {
        // console.log(liste, liste[i], item)
        text = text.replace("{{" + liste[i] + "}}", item[liste[i]].startWirkung);
      }
      return text;
    },
    werteListe(text) {
      let ergebnis = [];
      let liste = text.split("{{");
      let laenge = liste.length;
      for (let i = 1; i < laenge; i++) {
        ergebnis.push(liste[i].split("}}")[0]);
      }
      return ergebnis;
    },
    wuerfelErgebnisAbschicken() {
      this.spieler.wuerfelErgebnis = this.getZufallszahl();
      this.dialogWuerfel = false;
      this.spieler.wuerfeln = false;
      this.$store.dispatch("SpielerEinzeln/set", this.spieler);
    }
  }
};
</script>

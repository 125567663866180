<template>
  <v-card>
    <v-card-title>
      {{spieler.name}}
      <v-spacer></v-spacer>
      <v-icon @click="deleteNPC">mdi-delete</v-icon>
      </v-card-title>
    <v-card-text>
      <v-chip>
        <v-icon left @click="minus('tp')">mdi-minus</v-icon>
        TP {{spieler.trefferpunkteAkt}}/{{spieler.trefferpunkteGesamt}}
        <v-icon right @click="plus('tp')">mdi-plus</v-icon>
        <v-avatar right color="green"><v-icon @click="full('tp')">mdi-arrow-up-bold-outline</v-icon></v-avatar>
      </v-chip>
      <v-chip class="mt-1">
        <v-icon left @click="minus('mp')">mdi-minus</v-icon>
        MP {{spieler.magiepunkteAkt}}/{{spieler.magiepunkteGesamt}}
        <v-icon right @click="plus('mp')">mdi-plus</v-icon>
        <v-avatar right color="green"><v-icon @click="full('mp')">mdi-arrow-up-bold-outline</v-icon></v-avatar>
        </v-chip>
    </v-card-text>

  </v-card>
</template>

<script>
/* eslint-disable */

export default {
  props:["NPCName"],
  data:() => ({
  }),
  computed:{
    spieler:{
      get(){
        return this.$store.getters["NPCs/getNPC"](this.NPCName)
      },
      set(val){
        this.$store.dispatch("NPCs/set",val)
      }
    },
    waffenAuswahl(){
      return this.$store.getters["Tabellen/getWaffen"]
    }
  },
  methods:{
    minus(typ){
      if (typ == "tp"){
        if (this.spieler.trefferpunkteAkt > 0){
          this.spieler.trefferpunkteAkt --
          this.spieler = this.spieler
        }
      } else {
        if (this.spieler.magiepunkteAkt > 0) {
          this.spieler.magiepunkteAkt --
          this.spieler = this.spieler
        }
      }

    },
    plus(typ){
      if (typ == "tp"){
        if (this.spieler.trefferpunkteAkt < this.spieler.trefferpunkteGesamt){
          this.spieler.trefferpunkteAkt ++
          this.spieler = this.spieler
        }
      } else {
        if (this.spieler.magiepunkteAkt < this.spieler.magiepunkteGesamt) {
          this.spieler.magiepunkteAkt ++
          this.spieler = this.spieler
        }
      }
    },
    full(typ){
      if (typ == "tp"){
        this.spieler.trefferpunkteAkt = this.spieler.trefferpunkteGesamt
        // this.$store.dispatch("Spieler/set",this.spieler)
      } else {
        this.spieler.magiepunkteAkt = this.spieler.magiepunkteGesamt
        // this.$store.dispatch("Spieler/set",this.spieler)
      }
    },
    deleteNPC(){
      // console.log("Delete NPC", this.spieler.name)
      this.$store.dispatch("NPCs/delete",this.spieler.name)
    }
  }
}
</script>

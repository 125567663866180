<template>
  <v-card>
    <v-card-title>
      <div @click="openSpielerDetails">
        <v-avatar size="30" color="purple">{{ spieler.level }}</v-avatar>
        {{ spieler.name }}
      </div>
      <v-spacer></v-spacer>
      <v-avatar size="30" color="yellow"><v-icon @click="levelUp">mdi-arrow-up-bold-outline</v-icon></v-avatar>
    </v-card-title>
    <v-card-text>
      <v-chip outlined v-for="(item, index) in waehrung" :key="index">
        {{
          index != 0
            ? parseInt((spieler.geld % waehrung[index - 1].kurs) / item.kurs)
            : parseInt(spieler.geld / item.kurs)
        }}
        {{ abkuerzugenWaehrung[item.name] ? abkuerzugenWaehrung[item.name] : item.name }}
      </v-chip>
      <v-chip v-if="!erfahrungspunkte">
        EP: {{ spieler.erfahrungspunkte }}
        <v-avatar left><v-icon right @click="erfahrungspunkte = true">mdi-plus</v-icon></v-avatar>
      </v-chip>
      <v-text-field
        v-else
        v-model="erfahrungspunkteWert"
        outlined
        @keydown.enter="
          erfahrungspunkte = false;
          spieler.erfahrungspunkte += parseInt(erfahrungspunkteWert);
          erfahrungspunkteWert = 0;
          setSpieler();
        "
      ></v-text-field>
      <v-chip class="mt-1">
        <v-icon left @click="minus('tp')">mdi-minus</v-icon>
        TP {{ spieler.trefferpunkteAkt }}/{{ spieler.trefferpunkteGesamt }}
        <v-icon right @click="plus('tp')">mdi-plus</v-icon>
        <v-avatar right color="green"><v-icon @click="full('tp')">mdi-arrow-up-bold-outline</v-icon></v-avatar>
      </v-chip>
      <v-chip class="mt-1">
        <v-icon left @click="minus('mp')">mdi-minus</v-icon>
        MP {{ spieler.magiepunkteAkt }}/{{ spieler.magiepunkteGesamt }}
        <v-icon right @click="plus('mp')">mdi-plus</v-icon>
        <v-avatar right color="green"><v-icon @click="full('mp')">mdi-arrow-up-bold-outline</v-icon></v-avatar>
      </v-chip>

      <v-chip class="mt-1" @click="wuerfeln"> Wuerfeln: {{ spieler.wuerfelErgebnis }} </v-chip>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["spielerName"],
  data: () => ({
    erfahrungspunkte: false,
    erfahrungspunkteWert: 0,
    abkuerzugenWaehrung: { Goldfalke: "GF", Triontaler: "TT", Kupferling: "KL", Muena: "MU" }
  }),
  computed: {
    spieler() {
      return this.$store.getters["Spieler/getSpieler"](this.spielerName);
    },
    waehrung() {
      return this.$store.getters["Geschichte/getAktWaehrung"];
    }
  },
  methods: {
    minus(typ) {
      if (typ == "tp") {
        if (this.spieler.trefferpunkteAkt > 0) {
          this.spieler.trefferpunkteAkt--;
          this.$store.dispatch("Spieler/set", this.spieler);
        }
      } else {
        if (this.spieler.magiepunkteAkt > 0) {
          this.spieler.magiepunkteAkt--;
          this.$store.dispatch("Spieler/set", this.spieler);
        }
      }
    },
    plus(typ) {
      if (typ == "tp") {
        if (this.spieler.trefferpunkteAkt < this.spieler.trefferpunkteGesamt) {
          this.spieler.trefferpunkteAkt++;
          this.$store.dispatch("Spieler/set", this.spieler);
        }
      } else {
        if (this.spieler.magiepunkteAkt < this.spieler.magiepunkteGesamt) {
          this.spieler.magiepunkteAkt++;
          this.$store.dispatch("Spieler/set", this.spieler);
        }
      }
    },
    full(typ) {
      if (typ == "tp") {
        this.spieler.trefferpunkteAkt = this.spieler.trefferpunkteGesamt;
        this.$store.dispatch("Spieler/set", this.spieler);
      } else {
        this.spieler.magiepunkteAkt = this.spieler.magiepunkteGesamt;
        this.$store.dispatch("Spieler/set", this.spieler);
      }
    },
    levelUp() {
      this.spieler.level++;
      this.spieler.ausbildungspunkte += 8;
      this.spieler.levelAufstieg = true;
      let berufe = this.$store.getters["Tabellen/getBerufe"];
      let berufTP = 0;
      for (let i in berufe) {
        if (berufe[i].name == this.spieler.beruf) {
          berufTP = berufe[i].trefferpunkte;
        }
      }
      this.spieler.trefferpunkteGesamt = (this.spieler.boni.ko + berufTP) * this.spieler.level;
      this.spieler.trefferpunkteAkt = this.spieler.trefferpunkteGesamt;
      this.$store.dispatch("Spieler/set", this.spieler);
    },
    setSpieler() {
      this.$store.dispatch("Spieler/set", this.spieler);
    },
    wuerfeln() {
      this.spieler.wuerfeln = true;
      this.spieler.wuerfelErgebnis = 0;
      this.setSpieler();
    },
    openSpielerDetails() {
      let url = "/#/spielleiter/" + this.spieler.name;
      window.open(url, "_self");
    }
  }
};
</script>

<template>
  <v-container>
    <v-row>
      <!-- Große Aufteilung in zwei Bereiche -->

      <v-col cols="7">
        <v-container id="Zufallszahl">
          <v-row>
            <v-col cols="3">
              <v-card>
                <v-card-title>
                  <v-spacer></v-spacer>Zufallszahl
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <v-avatar size="48" color="green" class="d-flex justify-center">
                    <span class="headline">{{ zufallszahl }}</span>
                  </v-avatar>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="getZufallszahl">Würfeln</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="4" align-self="center">
              <clock :time="time" bg="white"></clock>
              <v-chip class="d-flex justify-center mt-1">{{ time }} {{ timeAM ? "AM" : "PM" }} </v-chip>
            </v-col>
            <v-col cols="5">
              <v-row no-gutters>
                <v-col><v-btn small class="ma-1" @click="zeitVerstreicht(1)">1 Stunde</v-btn></v-col>
                <v-col><v-btn small class="ma-1" @click="zeitVerstreicht(2)">2 Stunden</v-btn></v-col>
                <v-col><v-btn small class="ma-1" @click="zeitVerstreicht(3)">3 Stunden</v-btn></v-col>
                <v-col><v-btn small class="ma-1" @click="zeitVerstreicht(5)">5 Stunden</v-btn></v-col>
                <v-col><v-btn small class="ma-1" @click="zeitVerstreicht(12)">12 Stunden</v-btn></v-col>
                <v-col><v-btn small class="ma-1" @click="zeitVerstreicht(16)">16 Stunden</v-btn></v-col>
                <v-col align-self="center"
                  ><v-btn small class="ma-1 d-flex justify center" @click="zeitVerstreicht(24)">1 Tag</v-btn></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <Marktplatz />

        <Manoever />

        <Kampf :key="keyKampf" @reset="keyKampf = !keyKampf" />

        <!-- <KampfAlt /> -->

        <Zaubern />
      </v-col>

      <v-col cols="5">
        <v-container>
          <v-row dense>
            <v-col cols="4"><Geschichte /></v-col>
            <v-col>
              <v-btn @click="newWaehrung.dialog = true">Neue Währung</v-btn>
              <v-dialog v-model="newWaehrung.dialog">
                <v-card>
                  <v-card-title>
                    Neue Währung
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="neueWaehrung">
                      <v-text-field v-model="newWaehrung.name" label="Name" outlined :rules="nichtLeer"></v-text-field>
                      <v-btn
                        @click="
                          newWaehrung.einheiten.push({ name: '', imgData: undefined, url: '', uploadvalue: 0, kurs: 0 })
                        "
                        >Neue Einheit</v-btn
                      >
                      <v-row v-for="(item, index) in newWaehrung.einheiten" :key="index">
                        <v-text-field
                          v-model="item.name"
                          label="Einheitname (Optional)"
                          outlined
                          :rules="nichtLeer"
                        ></v-text-field>
                        <v-text-field
                          v-model="item.kurs"
                          label="Kurs zu Muena"
                          hint="Wie viel von dieser Währung entspricht einem Muena"
                          outlined
                          type="number"
                          :rules="nichtLeer"
                        ></v-text-field>
                        <v-file-input
                          label="Bild von Einheit"
                          outlined
                          accept="image/*"
                          prepend-icon="mdi-camera"
                          @change="getPhotoData($event, index)"
                        ></v-file-input>
                        <div v-if="item.url == null && item.value != 0 && item.value != 100">
                          <v-progress-linear v-model="item.uploadvalue"></v-progress-linear>
                        </div>
                        <div v-else>
                          <v-img :src="item.url" max-width="50"></v-img>
                        </div>
                        <v-icon @click="newWaehrung.einheiten.splice(index, 1)">mdi-minus</v-icon>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveNewWaehrung">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="4">
              <v-btn @click="copyToClipboard">Spielerlink</v-btn>
            </v-col>
            <v-col>
              <v-select :items="waehrungen" v-model="waehrung"></v-select>
            </v-col>
          </v-row>
          <v-card class="mt-2">
            <v-card-title
              >Spielerübersicht
              <!-- <v-spacer></v-spacer>
              <v-btn @click="zeitVerstreicht(1)">1 Stunde</v-btn>
              <v-btn @click="zeitVerstreicht(24)">1 Tag</v-btn> -->
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col v-for="name in spielerListe" :key="name" cols="6">
                  <Spielerkarte :spielerName="name" />
                </v-col>
              </v-row>
              <!-- Es wäre nicht schlecht das als eigenes Element zu machen -->
            </v-card-text>
          </v-card>

          <v-card class="mt-2">
            <!-- Hier ein if ob es gerade einen Kampf mit Gegnern gibt, bzw NPC's -->
            <v-card-title
              >NPC-Übersicht <v-spacer></v-spacer> <v-icon @click="openDialogNPC = true">mdi-plus</v-icon>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col v-for="name in npcListe" :key="name" cols="6">
                  <NPCs :NPCName="name" />
                </v-col>
              </v-row>
              <!-- vermutlich auch eigenes Element, Diese sollten einen einfachen Delete Button haben und auch ein Hinzufügebutton. Unterschiedliche Designs für als Gegner hinzugefügte und Freunde-->
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog v-model="openDialogNPC" max-width="600" @click:outside="npc = npcRestore">
      <v-card>
        <v-card-title>NPC erstellen</v-card-title>
        <v-card-text>
          <v-form ref="npc">
            <v-text-field v-model="npc.name" outlined label="Name" :rules="nichtLeer"></v-text-field>
            <v-row dense>
              <v-col cols="6"
                ><v-text-field
                  v-model="npc.tp"
                  outlined
                  label="Trefferpunkte"
                  type="number"
                  :rules="nichtLeer"
                ></v-text-field
              ></v-col>
              <v-col cols="6"
                ><v-text-field
                  v-model="npc.mp"
                  outlined
                  label="Magiepunkte"
                  type="number"
                  :rules="nichtLeer"
                ></v-text-field
              ></v-col>
              <v-col cols="6"
                ><v-text-field
                  v-model="npc.ruestung"
                  outlined
                  label="Rüstung"
                  type="number"
                  :rules="nichtLeer"
                ></v-text-field
              ></v-col>
              <v-col cols="6"
                ><v-text-field
                  v-model="npc.ini"
                  outlined
                  label="Initiative"
                  type="number"
                  :rules="nichtLeer"
                ></v-text-field
              ></v-col>
              <v-col cols="12">
                <h2>Waffen</h2>
                <v-row dense v-for="(waffe, index) in npc.waffen" :key="index" cols="12">
                  <v-col cols="4"
                    ><v-text-field
                      v-model="waffe.name"
                      label="Name"
                      outlined
                      hide-details
                      :rules="nichtLeer"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="3"
                    ><v-text-field
                      v-model="waffe.kb"
                      label="Kampfbonus"
                      outlined
                      hide-details
                      :rules="nichtLeer"
                      type="number"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="3"
                    ><v-text-field
                      v-model="waffe.schaden"
                      label="Schaden"
                      outlined
                      hide-details
                      :rules="nichtLeer"
                      type="number"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="1" v-if="npc.waffen.length - 1 == index" align-self="center"
                    ><v-btn
                      @click="index == 0 ? (npc.waffen = [{ name: '', kb: '', schaden: '' }]) : npc.waffen.pop()"
                      fab
                      small
                      ><v-icon>mdi-minus</v-icon></v-btn
                    ></v-col
                  >
                  <v-col cols="1" v-if="npc.waffen.length - 1 == index" align-self="center"
                    ><v-btn @click="npc.waffen.push({ name: '', kb: '', schaden: '' })" fab small
                      ><v-icon>mdi-plus</v-icon></v-btn
                    ></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createNPC">Erstellen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Manoever from "../components/Manoever.vue";
import Kampf from "../components/Kampf2.vue";
import Marktplatz from "../components/Marktplatz.vue";
import Spielerkarte from "../components/Spielerkarte.vue";
import NPCs from "../components/NPCKarte.vue";
import Zaubern from "../components/Zaubern.vue";
import Geschichte from "../components/Geschichte.vue";

import Clock from "vue-clock2";

//import KampfAlt from "../components/Kampf";
import { storage } from "firebase";

export default {
  components: { Manoever, Kampf, Marktplatz, Spielerkarte, NPCs, Zaubern, Geschichte, Clock },
  data: () => ({
    time: "10:40:0",
    timeAM: true,
    zufallszahl: 0,
    geschichteOpen: false,
    openDialogNPC: false,
    keyKampf: true,

    npc: {
      name: "",
      tp: "",
      mp: "",
      ruestung: "",
      ini: "",
      waffen: [{ name: "", kb: "", schaden: "" }]
    },
    npcRestore: {
      name: "",
      tp: "",
      mp: "",
      ruestung: "",
      ini: "",
      waffen: [{ name: "", kb: "", schaden: "" }]
    },

    newWaehrung: {
      dialog: false,
      name: "",
      einheiten: [{ name: "", imgData: undefined, url: "", uploadvalue: 0, kurs: 0 }]
    },

    nichtLeer: [val => val.length > 0 || "Darf nicht leer sein"]
  }),
  created() {
    this.$store.commit("setHintergrundbild", "Morgens");
    let self = this;
    setInterval(clock, 1000);
    function clock() {
      let zeitArray = self.time.split(":");
      let minuten = parseInt(zeitArray[1]);
      let sekunden = parseInt(zeitArray[2]) + 1;
      if (sekunden == 60) {
        minuten += 1;
        sekunden = 0;
        if (minuten == 60) {
          minuten = 0;
        }
      }
      zeitArray[1] = minuten.toString();
      zeitArray[2] = sekunden.toString();
      self.time = zeitArray.join(":");
    }
  },
  computed: {
    spielerListe() {
      return Object.keys(this.$store.getters["Spieler/getAlleSpieler"]);
    },
    npcListe() {
      return Object.keys(this.$store.getters["NPCs/getAlle"]);
    },
    waffen() {
      return this.$store.getters["Tabellen/getWaffen"];
    },
    waehrungen() {
      return Object.keys(this.$store.getters["Geschichte/getAlle"].waehrungen);
    },
    waehrung: {
      get() {
        let data = this.$store.getters["Geschichte/getAlle"];
        let aktiv = data.aktiveWaehrung;
        return aktiv;
      },
      set(val) {
        this.$store.dispatch("Geschichte/patch", { aktiveWaehrung: val });
      }
    }
  },
  methods: {
    getZufallszahl() {
      let temp = Math.ceil(Math.random() * 10);
      while (temp % 10 == 0) {
        temp += Math.ceil(Math.random() * 10);
      }
      this.zufallszahl = temp;
    },
    createNPC() {
      // console.log("CreateNPC")
      if (this.$refs.npc.validate()) {
        let obj = {
          id: this.npc.name,
          name: this.npc.name,
          ruestung: parseInt(this.npc.ruestung),
          ini: parseInt(this.npc.ini),
          waffen: this.npc.waffen,
          trefferpunkteAkt: parseInt(this.npc.tp),
          trefferpunkteGesamt: parseInt(this.npc.tp),
          magiepunkteAkt: parseInt(this.npc.mp),
          magiepunkteGesamt: parseInt(this.npc.mp)
        };
        this.$store.dispatch("NPCs/set", obj);
        this.npc = this.npcRestore;
        this.openDialogNPC = false;
      }
      //Via Dialog
    },
    waffeHinzufuegen() {
      this.npc.waffen;
    },
    copyToClipboard() {
      let str = "https://pp.margel.me/#/" + this.$store.state.spiel;
      const el = document.createElement("textarea");
      el.value = str;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$store.dispatch("setSnackbar", {
        text: "Link wurde in deine Zwischenablage kopiert",
        color: "success",
        time: 4
      });
    },
    zeitVerstreicht(zeit) {
      this.spielerListe.forEach(spielerName => {
        let spieler = this.$store.getters["Spieler/getSpieler"](spielerName);
        let koBonus = spieler.boni.ko;
        let heilRate = 1;
        if (koBonus > 0) {
          heilRate += koBonus;
        }
        let magieReg;
        if (spieler.beruf == "Priester" || spieler.beruf == "Schamane" || spieler.beruf == "Barde") {
          magieReg = spieler.boni.ch + 1;
        } else {
          magieReg = spieler.boni.in + 1;
        }
        if (magieReg < 0) {
          magieReg = 0;
        }
        let heilung = Math.round((heilRate * zeit) / 24);
        let magiePunkte = Math.round(magieReg * zeit);
        if (heilung > spieler.trefferpunkteGesamt - spieler.trefferpunkteAkt) {
          spieler.trefferpunkteAkt = spieler.trefferpunkteGesamt;
        } else {
          spieler.trefferpunkteAkt += heilung;
        }

        if (magiePunkte > spieler.magiepunkteGesamt - spieler.magiepunkteAkt) {
          spieler.magiepunkteAkt = spieler.magiepunkteGesamt;
        } else {
          spieler.magiepunkteAkt += magiePunkte;
        }
        this.$store.dispatch("Spieler/set", spieler);
      });
      if (zeit != 24) {
        let zeitArray = this.time.split(":");
        let stunden = parseInt(zeitArray[0]) + zeit;
        if (stunden < 12) {
          this.timeAM = true;
        } else if (stunden < 24) {
          this.timeAM = false;
        } else {
          stunden = stunden - 24;
          this.timeAM = true;
        }
        // if (stunden < 1){
        //   this.$store.commit("setHintergrundbild", "Nacht2")
        // } else if (stunden < 6) {
        //   this.$store.commit("setHintergrundbild", "Nacht3")
        // } else if (stunden < 11) {
        //   this.$store.commit("setHintergrundbild", "Morgen")
        // } else if (stunden < 16) {
        //   this.$store.commit("setHintergrundbild", "Mittag")
        // } else if (stunden < 21) {
        //   this.$store.commit("setHintergrundbild", "Abend")
        // } else {
        //   this.$store.commit("setHintergrundbild", "Nacht1")
        // }
        if (stunden < 6) {
          this.$store.commit("setHintergrundbild", "Nachts");
        } else if (stunden < 12) {
          this.$store.commit("setHintergrundbild", "Morgens");
        } else if (stunden < 16) {
          this.$store.commit("setHintergrundbild", "Mittags");
        } else if (stunden < 22) {
          this.$store.commit("setHintergrundbild", "Abends");
        } else {
          this.$store.commit("setHintergrundbild", "Nachts");
        }
        zeitArray[0] = stunden.toString();
        this.time = zeitArray.join(":");
      }
    },
    onUpload(id) {
      this.newWaehrung.einheiten[id].url = null;
      let spielid = this.$store.state.spiel;
      const storeageRef = storage()
        .ref(`Waehrungen/${spielid}/${this.newWaehrung.einheiten[id].imgData.name}`)
        .put(this.newWaehrung.einheiten[id].imgData);
      storeageRef.on(
        `state_changed`,
        snapshot => {
          this.newWaehrung.einheiten[id].uploadvalue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          this.$store.dispatch("setSnackbar", {
            text: "Etwas ist schiefgegangen: " + error.message,
            color: "error",
            time: 4
          });
        },
        () => {
          this.newWaehrung.einheiten[id].uploadvalue = 100;
          storeageRef.snapshot.ref.getDownloadURL().then(url => {
            this.newWaehrung.einheiten[id].url = url;
          });
        }
      );
    },
    getPhotoData(event, id) {
      console.log(event, id);
      this.newWaehrung.einheiten[id].uploadvalue = 0;
      this.newWaehrung.einheiten[id].url = null;
      if (event != undefined) {
        this.newWaehrung.einheiten[id].imgData = event;
        this.onUpload(id);
      }
    },
    saveNewWaehrung() {
      console.log("hoer");
      if (this.$refs.neueWaehrung.validate()) {
        let waehrungen = this.$store.getters["Geschichte/getWaehrungen"];
        let result = [];
        this.newWaehrung.einheiten.forEach(element => {
          result.push({ kurs: parseFloat(element.kurs), imgurl: element.url, name: element.name });
        });
        waehrungen[this.newWaehrung.name] = result;
        this.$store.dispatch("Geschichte/patch", { waehrungen: waehrungen }).then(() => {
          this.newWaehrung.name = "";
          this.newWaehrung.dialog = false;
          this.newWaehrung.einheiten = [{ name: "", imgData: undefined, url: "", uploadvalue: 0, kurs: 0 }];
        });
      }
    }
  }
};
</script>

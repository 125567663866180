<template>
  <v-container>
    <v-card>
      <v-card-title @click="open = !open">Manöver</v-card-title>
      <v-card-text v-if="open">
        <v-row>
          <v-col cols="7">
            <v-row dense>
              <v-col cols="8">
                <v-select
                  label="Spieler"
                  outlined
                  :items="spielerListe"
                  item-text="name"
                  return-object
                  v-model="aktSpieler"
                  @change="ergebnisLoeschen"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  outlined
                  label="Bonus"
                  v-model="manoeverBonus"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span v-html="manoeverInfoText"></span>
                </v-tooltip>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-select
                    label="Schwierigkeit"
                    outlined
                    :items="schwierigkeitsListe"
                    v-model="aktSchwierigkeit"
                    @change="ergebnisLoeschen"
                  ></v-select>
                  <v-subheader>{{ wertSchwierigkeit }}</v-subheader>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-select
                    label="Attribut"
                    outlined
                    :items="attributsListe"
                    v-model="aktAttribut"
                    @change="ergebnisLoeschen"
                    clearable
                  ></v-select>
                  <v-subheader>{{ wertAttribut }}</v-subheader>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-select
                    label="Fertigkeit"
                    outlined
                    :items="fertigkeitsListe"
                    item-text="text"
                    return-object
                    v-model="aktFertigkeit"
                    @change="ergebnisLoeschen"
                  ></v-select>
                  <v-subheader>{{ wertFertigkeit }}</v-subheader>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <WuerfelEingabe
              v-on:neuerWuerfelEingabe="getErgebnis"
              :key="resetWuerfelEingabe"
            />
            <v-card :color="ergebnisFarbe" class="mt-5">
              <v-card-text>Ergebnis: {{ ergebnis }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import WuerfelEingabe from "./WuerfelEingabe.vue";

export default {
  components: { WuerfelEingabe },
  data: () => ({
    open: false,
    resetWuerfelEingabe: true,

    aktSpieler: undefined,
    aktSchwierigkeit: 0,
    aktAttribut: undefined,
    aktFertigkeit: undefined,
    schwierigkeitsListe: [
      { text: "Routine", value: 5 },
      { text: "sehr einfach", value: 7 },
      { text: "einfach", value: 8 },
      { text: "schwer", value: 10 },
      { text: "sehr schwer", value: 12 },
      { text: "äußerst schwer", value: 14 },
      { text: "blanker Leichtsinn", value: 16 },
      { text: "absurd", value: 18 },
    ],
    spielerWerte: {},

    ergebnis: "",
    ergebnisFarbe: "",
  }),
  watch: {},
  computed: {
    spielerListe() {
      let listObject = this.$store.getters["Spieler/getAlleSpieler"];
      let ergebnis = [];
      for (let i in listObject) {
        ergebnis.push(listObject[i]);
      }
      return ergebnis;
    },
    attributsListe() {
      return this.$store.getters["Tabellen/getBoniAttribute"];
    },
    fertigkeitsListe() {
      if (this.aktSpieler) {
        let ergebnis = [];
        for (let i in this.aktSpieler.fertigkeitsraenge) {
          ergebnis.push({
            text: i,
            v: this.aktSpieler.fertigkeitsraenge[i].rang,
          });
        }
        return ergebnis;
      } else {
        return [];
      }
    },
    wertSchwierigkeit() {
      return this.aktSchwierigkeit;
    },
    wertAttribut() {
      if (this.aktSpieler) {
        return this.$store.getters["Spieler/getSpielerBoni"](
          this.aktSpieler.id,
          this.aktAttribut
        );
      } else {
        return 0;
      }
    },
    wertFertigkeit() {
      if (this.aktSpieler && this.aktFertigkeit) {
        return this.aktFertigkeit.v;
      } else {
        return 0;
      }
    },
    manoeverBonus: {
      get() {
        if (this.aktSpieler) {
          return this.aktSpieler.manoeverBonus;
        } else {
          return 0;
        }
      },
      set(val) {
        if (this.aktSpieler) {
          this.aktSpieler.manoeverBonus = parseInt(val);
          this.$store.dispatch("Spieler/set", this.aktSpieler);
        } else {
          // console.log("Kein Spieler ausgewählt");
        }
      },
    },
    manoeverInfoText (){
      let volksInfos=  {
        Gnom: "+1 Mechanik Manöver, <br>+1 Geheimtüren und Fallen",
        Halbling: "-",
        Zwerg: "+1 Geheimtüren und Fallen",
        Elf: "+1 Sicht",
        Mensch: "-"
      }
      let berufInfos = {
        Barde: "+1 Informationsbeschaffung (Einflussnahme, List, Wahrnehmung)",
        Dieb: "+1 Geheimtüren und Fallen,<br>+1 Schlösser knacken, <br>+1 Tarnen, <br>+1 Gifte, <br>Magische Gegenstände ohne Manöver",
        Krieger: "Magische Waffen und Ausrüstungen ohne Manöver, <br>+1 Einflussnahme (Nur bei entsprechendem Auftreten)",
        Priester: "+1 Heilen",
        Schamane: "-",
        Waldläufer: "+1 Auf alles mit und in der Natur",
        Zauberer: "+2 Bei Zaubermanöver mit Zauberbuch (+10min/Rang), <br>Metallrüstung erschwert zaubern",
      }
      let priesterDabei = false
      this.spielerListe.forEach(spieler => {
        if (spieler.beruf == "Priester"){
          priesterDabei = true
        }
      });
      if (this.aktSpieler != undefined){
        let text = ""
        text = text + "Volksboni: <br>" + volksInfos[this.aktSpieler.volk]
        text = text + "<br>Berufsboni: <br>" + berufInfos[this.aktSpieler.beruf]
        if (priesterDabei){
          text = text + "<br>Priester können ab Stufe 5 eine Messe halten (+1 für 4 Stunden)<br> Ab Stufe 10, Segnung +2 ganzer Tag"
        }
        return text
      } else {
        return ""
      }
    }
  },
  methods: {
    getErgebnis(wert) {
      // console.log("Würfelwert", wert);
      if (wert == 1) {
        this.ergebnis = "Kritischer Fehlschlag";
        this.ergebnisFarbe = "#AD0606";
      } else {
        var temp =
          this.aktSchwierigkeit -
          (this.wertAttribut +
            this.wertFertigkeit +
            wert +
            parseInt(this.manoeverBonus));
        if (temp <= 0) {
          this.ergebnis =
            "Manöver geschafft. Mit " + String(-temp) + " Übertroffen";
          this.ergebnisFarbe = "#02AB24";
        } else {
          this.ergebnis =
            "Manöver fehlgeschlagen. Um " + String(temp) + " Verfehlt";
          this.ergebnisFarbe = "#F6132E";
        }
      }
    },
    ergebnisLoeschen() {
      this.ergebnisFarbe = "";
      this.ergebnis = "";
      this.resetWuerfelEingabe = !this.resetWuerfelEingabe;
    },
  },
};
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import VuexEasyFirestore from 'vuex-easy-firestore'
Vue.use(Vuex)

import { Firebase, initFirebase } from './config/firebase.js'
import spieler from './modules/spieler.js'
import tabellen from './modules/tabellen.js'
import npcs from './modules/npcs.js'
import geschichte from './modules/geschichte.js'
// import test from './modules/test.js'
import spielerEinzel from './modules/SingleSpieler.js'
// import state from 'vuex-easy-firestore/types/module/state'

const easyFirestore = VuexEasyFirestore(
  [spieler,tabellen,npcs,geschichte,spielerEinzel],
  { logging: true, FirebaseDependency: Firebase }
)
const storeData = {
  //strict: process.env.NODE_ENV !== 'production',
  plugins: [easyFirestore],
  state:{
    spielleiter: undefined,
    spiel: undefined,
    spieler: undefined,
    alleSpieler: undefined,
    spielerMail: undefined,
    fertigErstellt: true,
    hintergrundbild: "",
    snackbar:{
      open: false,
      text: "",
      color: ""
    }
  },
  getters:{
    getSpielleiter: state => {
      return state.spielleiter
    },
    getSpiel: state => {
      return state.spiel
    },
    getSpieler: state => {
      return state.spieler
    },
    getHintergrundbild: state => {
      return state.hintergrundbild
    },
    getSnackbar: state => {
      return state.snackbar
    }
  },
  mutations:{
    setFertigErstellt: (state,val) =>{
      state.fertigErstellt = val
    },
    setSpielleiter: (state,val) => {
      state.spielleiter = val
    }, 
    setSpiel: (state, val) => {
      state.spiel = val
    },
    setSpieler: (state,val) => {
      state.spieler = val
    },
    setSpielerMail: (state, val) => {
      state.spielerMail = val
    },
    setHintergrundbild: (state, val) => {
      state.hintergrundbild = val
    },
    setSnackbarWerte: (state, val) => {
      state.snackbar = val
    }
  },
  actions:{
    setSnackbar (context, infos) {
      context.commit("setSnackbarWerte", {open: true, text: infos.text, color: infos.color})
      setTimeout(() => {
        context.commit("setSnackbarWerte", {open: false, text: "", color: ""})}, infos.time * 1000
      )
    }
  }
  // ... your other store data
}

// initialise Vuex
const store = new Vuex.Store(storeData)

// initFirebase
initFirebase()
  .catch(error => {
    console.error("store/index.js Error: ",error)
    // take user to a page stating an error occurred
    // (might be a connection error, or the app is open in another tab)
  })

export default store
<template>
  <v-container>
    <v-stepper v-model="step" vertical>
      <v-stepper-header>
        <v-stepper-step v-for="i in maxStep" :key="`${i}-step`" :editable="step > i" :step="i" :complete="step > i"
          >{{ schrittNamen[i] }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card color="grey lighten-1">
            <v-card-text>
              <v-form ref="nameEtc">
                <v-text-field v-model="spieler.level" label="Level" :rules="nichtLeer && groeßerNull"></v-text-field>
                <v-text-field v-model="spieler.name" label="Name" :rules="nichtLeer"></v-text-field>
                <v-text-field
                  v-model="spieler.ausbildungspunkte"
                  label="Ausbildungspunkte"
                  :rules="nichtLeer"
                  type="number"
                ></v-text-field>
                <v-select
                  v-model="spieler.volk"
                  :items="['Mensch', 'Zwerg', 'Elf', 'Gnom', 'Halbling']"
                  label="Volk"
                  :rules="nichtLeer"
                ></v-select>
                <v-select v-model="spieler.beruf" :items="berufeNamen" label="Beruf" :rules="nichtLeer"></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="weiterName()">Weiter</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card>
            <v-card-text>
              <v-form ref="boni">
                <v-text-field
                  v-model="spieler.boniWerte.st"
                  label="ST Ausgangswerte"
                  :rules="zwischen015"
                  @change="spieler.boni.st = werteZuBoni[spieler.boniWerte.st]"
                ></v-text-field>
                {{ werteZuBoni[spieler.boniWerte.st] }}
                <v-text-field
                  v-model="spieler.boniWerte.ge"
                  label="GE Ausgangswerte"
                  :rules="zwischen015"
                  @change="spieler.boni.ge = werteZuBoni[spieler.boniWerte.ge]"
                ></v-text-field>
                {{ werteZuBoni[spieler.boniWerte.ge] }}
                <v-text-field
                  v-model="spieler.boniWerte.ko"
                  label="KO Ausgangswerte"
                  :rules="zwischen015"
                  @change="spieler.boni.ko = werteZuBoni[spieler.boniWerte.ko]"
                ></v-text-field>
                {{ werteZuBoni[spieler.boniWerte.ko] }}
                <v-text-field
                  v-model="spieler.boniWerte.in"
                  label="IN Ausgangswerte"
                  :rules="zwischen015"
                  @change="spieler.boni.in = werteZuBoni[spieler.boniWerte.in]"
                ></v-text-field>
                {{ werteZuBoni[spieler.boniWerte.in] }}
                <v-text-field
                  v-model="spieler.boniWerte.ch"
                  label="CH Ausgangswerte"
                  :rules="zwischen015"
                  @change="spieler.boni.ch = werteZuBoni[spieler.boniWerte.ch]"
                ></v-text-field>
                {{ werteZuBoni[spieler.boniWerte.ch] }}
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="weiterBoni()">Weiter</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card>
            <v-card-text>
              <PreisEingabe v-model="geld" />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="weiterGeld()">Weiter</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="raenge"
                :items="fertigkeitsraenge"
                :mobile-breakpoint="0"
                :key="aktualisieren"
                hide-defaut-footer
                disable-sort
                calculate-widths
                :items-per-page="50"
              >
                <template v-for="(header, index) in raenge.slice(2)" v-slot:[generateName(header)]="{ item }">
                  <v-row :key="index" justify="center">
                    <v-checkbox
                      v-for="(wert, i) in item[header.value]"
                      :key="i"
                      v-model="item[header.value][i]"
                    ></v-checkbox>
                    <!-- :readonly="readonly(item,header.value,raenge[index+1].value,raenge[index].value,i)" -->
                  </v-row>
                </template>
              </v-data-table>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn @click="neueFertigkeitFunc('Neue Spruchliste')">Neue Spruchliste</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="neueFertigkeitFunc('Neue Waffe')">Neue Waffe</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="neueFertigkeitFunc('Neues Wissen')">Neues Wissen</v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="weiter()">Weiter</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card>
            <v-card-text>
              <v-select
                :items="ruestungen"
                item-text="name"
                item-value="name"
                multiple
                chips
                deletable-chips
                v-model="spieler.ruestungen"
                return-object
                label="Rüstungen"
              ></v-select>
              <v-select
                :items="waffen"
                item-text="name"
                item-value="name"
                multiple
                chips
                deletable-chips
                v-model="spieler.waffen"
                return-object
                label="Waffen"
              ></v-select>
              <v-select
                :items="sonstiges"
                item-text="name"
                item-value="name"
                multiple
                chips
                deletable-chips
                v-model="spieler.sonstigeGegenstaende"
                return-object
                label="Sonstiges"
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="fertig()">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!-- <v-stepper v-model="step" vertical>
      <template v-for="i in maxStep">
        <v-stepper-step
          :key="`${i}-step`"
          :editable="step > i"
          :step="i"
          :complete="step > i"
          >{{schrittNamen[i]}}
        </v-stepper-step>
        <v-stepper-content :key="`${i}-content`" :step="i">
          <v-card color="grey lighten-1">
            <v-card-text v-if="i == '1'">
              <v-form ref="nameEtc">
                <v-text-field
                  v-model="spieler.level"
                  label="Level"
                  :rules="nichtLeer && groeßerNull"
                ></v-text-field>
                <v-text-field v-model="spieler.name" label="Name" :rules="nichtLeer"></v-text-field>
                <v-text-field v-model="spieler.ausbildungspunkte" label="Ausbildungspunkte" :rules="nichtLeer" type="number"></v-text-field>
                <v-select
                  v-model="spieler.volk"
                  :items="['Mensch','Zwerg','Elf','Gnom','Halbling']"
                  label="Volk"
                  :rules="nichtLeer"
                ></v-select>
                <v-select
                  v-model="spieler.beruf"
                  :items="berufeNamen"
                  label="Beruf"
                  :rules="nichtLeer"
                ></v-select>
              </v-form>
            </v-card-text>

            <v-card-text v-if="i == '2'">
              <v-form ref="boni">
                <v-text-field
                  v-model="spieler.boniWerte.st"
                  label="ST Ausgangswerte"
                  :rules="zwischen015"
                  @change=" spieler.boni.st = werteZuBoni[spieler.boniWerte.st]"
                ></v-text-field>
                {{werteZuBoni[spieler.boniWerte.st]}}
                <v-text-field
                  v-model="spieler.boniWerte.ge"
                  label="GE Ausgangswerte"
                  :rules="zwischen015"
                  @change=" spieler.boni.ge = werteZuBoni[spieler.boniWerte.ge]"
                ></v-text-field>
                {{werteZuBoni[spieler.boniWerte.ge]}}
                <v-text-field
                  v-model="spieler.boniWerte.ko"
                  label="KO Ausgangswerte"
                  :rules="zwischen015"
                  @change=" spieler.boni.ko = werteZuBoni[spieler.boniWerte.ko]"
                ></v-text-field>
                {{werteZuBoni[spieler.boniWerte.ko]}}
                <v-text-field
                  v-model="spieler.boniWerte.in"
                  label="IN Ausgangswerte"
                  :rules="zwischen015"
                  @change=" spieler.boni.in = werteZuBoni[spieler.boniWerte.in]"
                ></v-text-field>
                {{werteZuBoni[spieler.boniWerte.in]}}
                <v-text-field
                  v-model="spieler.boniWerte.ch"
                  label="CH Ausgangswerte"
                  :rules="zwischen015"
                  @change=" spieler.boni.ch = werteZuBoni[spieler.boniWerte.ch]"
                ></v-text-field>
                {{werteZuBoni[spieler.boniWerte.ch]}}
              </v-form>
            </v-card-text>

            <v-card-text v-if="i == '3'">
              <v-row>
                <v-col cols="3">
                  <v-text-field v-model="geld[0]" label="GF" type="number"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model="geld[1]" label="TT" type="number"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model="geld[2]" label="KL" type="number"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model="geld[3]" label="MU" type="number"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="i == '4'">
              <v-data-table
                :headers="raenge"
                :items="fertigkeitsraenge"
                :mobile-breakpoint="0"
                :key="aktualisieren"
                hide-defaut-footer
                disable-sort
                calculate-widths
                :items-per-page="50"
              >
                <template
                  v-for="(header, index) in raenge.slice(2)"
                  v-slot:[generateName(header)]="{ item }"
                >
                  <v-row :key="index" justify="center">
                    <v-checkbox
                      v-for="(wert, i) in item[header.value]"
                      :key="i"
                      v-model="item[header.value][i]"
                    ></v-checkbox>
                  </v-row>
                </template>
              </v-data-table>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn @click="neueFertigkeitFunc('Neue Spruchliste')">Neue Spruchliste</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="neueFertigkeitFunc('Neue Waffe')">Neue Waffe</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="neueFertigkeitFunc('Neues Wissen')">Neues Wissen</v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-card-text>

            <v-card-text v-if="i == '5'">
              <v-select
                :items="ruestungen"
                item-text="Name"
                item-value="Name"
                multiple
                chips
                deletable-chips
                v-model="spieler.ruestungen"
                return-object
                label="Rüstungen"
              ></v-select>
              <v-select
                :items="waffen"
                item-text="Name"
                item-value="Name"
                multiple
                chips
                deletable-chips
                v-model="spieler.waffen"
                return-object
                label="Waffen"
              ></v-select>
              <v-select
                :items="sonstiges"
                item-text="Name"
                item-value="Name"
                multiple
                chips
                deletable-chips
                v-model="spieler.sonstigeGegenstaende"
                return-object
                label="Sonstiges"
              ></v-select>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="step == 1" @click="weiterName()">Weiter</v-btn>
              <v-btn v-else-if="step == 2" @click="weiterBoni()">Weiter</v-btn>
              <v-btn v-else-if="step == 3" @click="weiterGeld()"></v-btn>
              <v-btn v-else-if="step < maxStep" @click="weiter()">Weiter</v-btn>
              <v-btn v-else @click="fertig()">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </template>
    </v-stepper> -->

    <v-dialog v-model="neueFertigkeit.open" max-width="450">
      <v-card>
        <v-card-title>{{ neueFertigkeit.typ }}</v-card-title>
        <v-card-text v-if="neueFertigkeit.typ != 'Neues Wissen'">
          <v-select :items="neueFertigkeit.auswahl" v-model="neueFertigkeit.ausgewaehlt"></v-select>
        </v-card-text>

        <v-card-text v-else>
          <v-text-field v-model="neueFertigkeit.ausgewaehlt"></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="hinzufuegen(neueFertigkeit.typ != 'Neues Wissen' ? true : false)">Bestätigen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PreisEingabe from "./PreisEingabe";
export default {
  components: {
    PreisEingabe
  },
  data: () => ({
    schrittNamen: ["", "Allgemein", "Boni", "Geld", "Fertigkeiten", "Ausrüstung"],
    aktualisieren: false,
    step: 1,
    maxStep: 5,
    geld: 0,
    raenge: [
      {
        text: "Fertigkeit",
        value: "name",
        width: "170px",
        divider: true,
        fixed: true
      },
      {
        text: "Kosten",
        value: "kosten",
        align: "right",
        width: "100px",
        divider: true
      },
      {
        text: "1",
        value: "eins",
        align: "center",
        width: "50px",
        divider: true
      },
      {
        text: "2",
        value: "zwei",
        align: "center",
        width: "41px",
        divider: true
      },
      {
        text: "3",
        value: "drei",
        align: "center",
        width: "41px",
        divider: true
      },
      {
        text: "4",
        value: "vier",
        align: "center",
        width: "41px",
        divider: true
      },
      {
        text: "5",
        value: "fuenf",
        align: "center",
        width: "41px",
        divider: true
      },
      {
        text: "6",
        value: "sechs",
        align: "center",
        width: "73px",
        divider: true
      },
      {
        text: "7",
        value: "sieben",
        align: "center",
        width: "73px",
        divider: true
      },
      {
        text: "8",
        value: "acht",
        align: "center",
        width: "73px",
        divider: true
      },
      {
        text: "9",
        value: "neun",
        align: "center",
        width: "73px",
        divider: true
      },
      {
        text: "10",
        value: "zehn",
        align: "center",
        width: "73px",
        divider: true
      },
      {
        text: "11",
        value: "elf",
        align: "center",
        width: "140px",
        divider: true
      },
      {
        text: "12",
        value: "zwoelf",
        align: "center",
        width: "136px",
        divider: true
      }
    ],
    fertigkeitsraenge: undefined,
    werteZuBoni: {
      1: -3,
      2: -2,
      3: -1,
      4: -1,
      5: 0,
      6: 1,
      7: 1,
      8: 2,
      9: 2,
      10: 3,
      11: 3,
      12: 4,
      13: 4,
      14: 5
    },
    neueFertigkeit: {
      open: false,
      typ: "",
      auswahl: [],
      ausgewaehlt: "",
      kosten: 0
    },
    nichtLeer: [val => val.length > 0 || "Darf nicht leer sein"],
    groeßerNull: [val => parseInt(val) > 0 || "Muss größer null sein"],
    kleiner15: [val => parseInt(val) < 15 || "Muss kleiner 15 sein"],
    zwischen015: [val => (0 < parseInt(val) && parseInt(val) < 15) || "Zwischen 0 und 15"]
  }),
  created() {
    this.fertigkeitsraengeErstellen();
  },
  computed: {
    spieler() {
      return this.$store.getters["SpielerEinzeln/getSpieler"];
    },
    berufe() {
      return this.$store.getters["Tabellen/getBerufe"];
    },
    berufeNamen() {
      let list = [];
      this.berufe.forEach(beruf => {
        list.push(beruf.name);
      });
      return list;
    },
    ruestungen() {
      return this.$store.getters["Tabellen/getRuestungen"];
    },
    waffen() {
      return this.$store.getters["Tabellen/getWaffen"];
    },
    sonstiges() {
      return this.$store.getters["Tabellen/getAlleProdukte"];
    }
  },
  watch: {
    "spieler.beruf": function(val) {
      // console.log("Index Berufname", this.berufeNamen.indexOf(val));
      //eslint-disable-next-line
      // console.log("Beruf", this.berufe[this.berufeNamen.indexOf(val)]);
      let beruf = this.berufe[this.berufeNamen.indexOf(val)];
      //eslint-disable-next-line
      // console.log("Beruf gespeichert", beruf);
      this.spieler.berufTP = beruf.trefferpunkte;
      this.spieler.berufWaffe = beruf.kosten.Waffen.split("/");
      this.spieler.berufWissen = beruf.kosten.Wissen;
      this.spieler.berufZauber = beruf.kosten.Spruchliste.split("/");
      for (let i in beruf.kosten) {
        if (i != "Waffen" && i != "Wissen" && i != "Spruchliste") {
          this.spieler.fertigkeitsraenge[i].kosten = beruf.kosten[i];
        }
      }
      this.spieler.fertigkeitsraenge["Magie entwickeln"].kosten = beruf.kosten["Magie entwickeln"].split("/");
      this.fertigkeitsraengeErstellen();
    }
  },
  methods: {
    fertigkeitsraengeErstellen() {
      if (this.aktualisieren || !this.aktualisieren) {
        // console.log("Jetzt werden die neu berechnet")
      }
      let list = [];
      // console.log(this.spieler.fertigkeitsraenge)
      let names = Object.keys(this.spieler.fertigkeitsraenge);
      names.sort();
      names.forEach(name => {
        let fertigkeitsrang = this.spieler.fertigkeitsraenge[name];
        let temp = {
          name: name,
          kosten: fertigkeitsrang.kosten
        };
        let zahlZuWort = [
          "eins",
          "zwei",
          "drei",
          "vier",
          "fuenf",
          "sechs",
          "sieben",
          "acht",
          "neun",
          "zehn",
          "elf",
          "zwoelf"
        ];
        for (let i = 0; i < 12; i++) {
          if (fertigkeitsrang.rang > i) {
            if (i < 6) {
              temp[zahlZuWort[i]] = [true];
            } else if (i < 10) {
              temp[zahlZuWort[i]] = [true, true];
            } else {
              temp[zahlZuWort[i]] = [true, true, true, true];
            }
          } else if (fertigkeitsrang == i) {
            if (i < 6) {
              temp[zahlZuWort[i]] = [false];
            } else if (i < 10) {
              if (fertigkeitsrang.zwischenRang == 1) temp[zahlZuWort[i]] = [true, false];
            } else {
              if (fertigkeitsrang.zwischenRang == 1) {
                temp[zahlZuWort[i]] = [true, false, false, false];
              }
              if (fertigkeitsrang.zwischenRang == 2) {
                temp[zahlZuWort[i]] = [true, true, false, false];
              }
              if (fertigkeitsrang.zwischenRang == 3) {
                temp[zahlZuWort[i]] = [true, true, true, false];
              }
            }
          } else {
            if (i < 6) {
              temp[zahlZuWort[i]] = [false];
            } else if (i < 10) {
              temp[zahlZuWort[i]] = [false, false];
            } else {
              temp[zahlZuWort[i]] = [false, false, false, false];
            }
          }
        }
        list.push(temp);
      });
      this.fertigkeitsraenge = list;
    },
    neueFertigkeitFunc(typ) {
      this.neueFertigkeit.typ = typ;
      this.neueFertigkeit.auswahl = [];
      let temp = [];
      this.fertigkeitsraenge.forEach(element => {
        temp.push(element.name);
      });
      if (typ == "Neue Spruchliste") {
        this.neueFertigkeit.kosten = this.spieler.berufZauber;
        this.$store.getters["Tabellen/getSpruchlisten"].forEach(spruchliste => {
          if (
            spruchliste.fuer.indexOf(this.spieler.beruf) >= 0 &&
            temp.indexOf('Spruchliste "' + spruchliste.name + '"') == -1
          ) {
            this.neueFertigkeit.auswahl.push('Spruchliste "' + spruchliste.name + '"');
          }
        });
      }
      if (typ == "Neue Waffe") {
        this.neueFertigkeit.kosten = this.spieler.berufWaffe;
        this.$store.getters["Tabellen/getWaffengruppen"].forEach(waffe => {
          if (temp.indexOf('Waffe "' + waffe + '"') == -1) {
            this.neueFertigkeit.auswahl.push('Waffe "' + waffe + '"');
          }
        });
      }
      if (typ == "Neues Wissen") {
        this.neueFertigkeit.kosten = this.spieler.berufWissen;
        this.neueFertigkeit.auswahl = "";
      }
      this.neueFertigkeit.open = true;
    },
    weiter() {
      this.step++;
    },
    weiterName() {
      //eslint-disable-next-line
      // console.log(this.$refs.nameEtc);
      if (this.$refs.nameEtc.validate()) {
        this.step++;
      }
    },
    weiterBoni() {
      if (this.$refs.boni.validate()) {
        // console.log("Boni OK",this.$refs.boni)
        this.step++;
      }
    },
    weiterGeld() {
      this.spieler.geld = this.geld;
      this.step++;
    },
    fertig() {
      this.spieler.fertigErstellt = true;
      this.bestaetigenFertigkeiten();
    },
    hinzufuegen(bool) {
      let name = "";
      if (bool) {
        name = this.neueFertigkeit.ausgewaehlt;
      } else {
        name = 'Wissen "' + this.neueFertigkeit.ausgewaehlt + '"';
      }
      this.spieler.fertigkeitsraenge[name] = {
        kosten: this.neueFertigkeit.kosten,
        rang: 0,
        zwischenRang: 0
      };
      this.fertigkeitsraengeErstellen();
      this.neueFertigkeit.open = false;
      this.aktualisieren = !this.aktualisieren;
    },
    bestaetigenFertigkeiten() {
      // Waffen KB und so
      if (this.spieler.ausbildungspunkte >= 0) {
        let list = {};
        for (let i in this.fertigkeitsraenge) {
          let a = this.fertigkeitsraenge[i];
          let rang = "null";
          let zwischenRang = 0;
          for (let x in a) {
            zwischenRang = 0;
            if (a[x][a[x].length - 1] == true) {
              rang = x;
            } else {
              for (let y in a[x]) {
                if (a[x][y]) {
                  zwischenRang += 1;
                }
              }
            }
          }
          rang = this.stringNumberParseInt(rang);
          list[this.fertigkeitsraenge[i].name] = {
            kosten: a.kosten,
            rang: rang,
            zwischenRang: zwischenRang
          };
        }
        this.spieler.fertigkeitsraenge = list;
        let namenFertigkeiten = Object.keys(this.spieler.fertigkeitsraenge);
        namenFertigkeiten.forEach(element => {
          if (element.search("Spruchliste") >= 0) {
            let spruchlistenName = element.split('"')[1];
            if (this.spieler.spruchlisten[spruchlistenName] == undefined) {
              this.spieler.spruchlisten[spruchlistenName] = { zauber: [] };
            }
            if (
              this.spieler.spruchlisten[spruchlistenName].zauber.length < this.spieler.fertigkeitsraenge[element].rang
            ) {
              let spruch = this.$store.getters["Tabellen/getSpruchlisten"];
              spruch.forEach(element => {
                if (element.name == spruchlistenName) {
                  spruch = element;
                }
              });
              this.spieler.spruchlisten[spruchlistenName].zauber.push(
                spruch.zauber[this.spieler.spruchlisten[spruchlistenName].zauber.length]
              );
              if (
                this.spieler.spruchlisten[spruchlistenName].zauber.length < this.spieler.fertigkeitsraenge[element].rang
              ) {
                this.spieler.spruchlisten[spruchlistenName].zauber.push(
                  spruch.zauber[this.spieler.spruchlisten[spruchlistenName].zauber.length]
                );
              }
            }
          }
          // else if (element.search("Waffe") >= 0) {

          // }
        });
        let rangMagie = this.spieler.fertigkeitsraenge["Magie entwickeln"].rang;
        let berufeListe = ["Priester", "Priesterin", "Schamane", "Schamanin", "Barde", "Bardin"];
        if (berufeListe.indexOf(this.spieler.beruf) == -1) {
          this.spieler.magiepunkteGesamt = (this.spieler.boni.in + 3) * rangMagie;
        } else {
          this.spieler.magiepunkteGesamt = (this.spieler.boni.ch + 3) * rangMagie;
        }
        this.spieler.trefferpunkteGesamt = (this.spieler.berufTP + this.spieler.boni.ko) * this.spieler.level;
        this.spieler.levelAufstieg = false;

        this.spielerWerteUpdaten();

        // this.$store.dispatch("Spieler/set", this.spieler);
        this.$store.dispatch("SpielerEinzeln/set", this.spieler);
      } else {
        this.$store.dispatch("setSnackbar", { text: "Zu wenig AP", color: "error", time: 4 });
      }
    },
    spielerWerteUpdaten() {
      let waffen = this.spieler.waffen;
      for (let i in waffen) {
        // console.log(waffen[i])
        let bonus = -10;
        for (let wert in waffen[i].attribut) {
          if (this.spieler.boni[waffen[i].attribut[wert].toLowerCase()] > bonus) {
            bonus = this.spieler.boni[waffen[i].attribut[wert].toLowerCase()];
          }
        }
        let fertigkeit = -2;
        for (let wert in waffen[i].gruppe) {
          for (let zeile in this.spieler.fertigkeitsraenge) {
            if (zeile.search(waffen[i].gruppe[wert]) >= 0) {
              if (
                this.spieler.fertigkeitsraenge[zeile].rang > fertigkeit &&
                this.spieler.fertigkeitsraenge[zeile].rang > 0
              ) {
                fertigkeit = this.spieler.fertigkeitsraenge[zeile].rang;
              }
            }
          }
        }
        if (this.spieler.beruf == "Krieger") {
          if (fertigkeit < 0) {
            fertigkeit = 0;
          }
        }
        if (this.spieler.beruf == "Waldläufer") {
          if (waffen[i].gruppe == "Armbrust" || waffen[i].gruppe == "Bogen") {
            if (fertigkeit < 0) {
              fertigkeit = 0;
            }
          }
        }
        waffen[i].kb = parseInt(fertigkeit) + parseInt(bonus);
        waffen[i].ini = parseInt(waffen[i]["iniBonus"]) + parseInt(this.spieler.boni.ge);
      }
      let ruestungswert = 5;
      for (let i in this.spieler.ruestungen) {
        ruestungswert += parseInt(this.spieler.ruestungen[i].ruestungsbonus);
      }
      if (this.spieler.beruf == "Dieb") {
        if (ruestungswert == 5) {
          ruestungswert += this.fertigkeitsraenge.Athletik.rang;
        }
      }
      if (this.spieler.volk == "Gnom" || this.spieler.volk == "Halbling") {
        ruestungswert += 1;
      }
      this.spieler.ruestung = ruestungswert;
      this.spieler.waffen = waffen;
      // console.log("Hier werden dann letztendlich alle Sachen geupdated");
    },
    generateName(name) {
      return "item." + name.value;
    },
    stringNumberParseInt(name) {
      let db = {
        null: 0,
        eins: 1,
        zwei: 2,
        drei: 3,
        vier: 4,
        fuenf: 5,
        sechs: 6,
        sieben: 7,
        acht: 8,
        neun: 9,
        zehn: 10,
        elf: 11,
        zwoelf: 12
      };
      return db[name];
    }
  }
};
</script>

<template>
  <v-container>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step
          v-for="i in maxSteps"
          :key="`${i}-step`"
          :editable="step > i"
          :step="i"
          :complete="step > i"
          >{{ stepNamen[i] }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="volk.voelkerBoniHeader"
                :items="voelker"
                :expand.sync="volk.voelkerExpand"
                item-key="name"
                show-expand
                single-expand
                hide-default-footer
                dense
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-card flat>
                      <v-img
                        :src="bilderlinks[item.name]"
                        class="white--text align-end"
                      >
                        <v-card-title class="red darken-3"
                          >{{ item.name }}
                        </v-card-title>
                      </v-img>
                      <v-card-text id="article" v-html="item.beschreibung">
                      </v-card-text>
                    </v-card>
                  </td>
                </template>
              </v-data-table>
              <!-- Tabelle mit Stärken und Schwächen des Volkes (BoniTabelle) -->
              <v-select
                v-model="volk.ausgewaehlt"
                :items="voelker"
                item-text="name"
                return-object
                label="Volk auswählen"
                outlined
                class="mt-3"
              ></v-select>
              <!-- Je ausgewählt nochmal ausführlicher. Evtl Stärken und Schwächen. Sonst weglassen, weil extra in Übersicht -->
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="weiterVolk()"
                :disabled="volk.ausgewaehlt == undefined"
                >Weiter</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="beruf.berufeHeader"
                :items="berufe"
                hide-default-footer
                dense
                :expand.sync="beruf.berufExpand"
                item-key="name"
                show-expand
                single-expand
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-card flat>
                      <v-img
                        :src="bilderlinks[item.name]"
                        class="white--text align-end"
                      >
                        <v-card-title class="red darken-3"
                          >{{ item.name }}
                        </v-card-title>
                      </v-img>
                      <v-card-text id="article" v-html="item.beschreibung">
                      </v-card-text>
                    </v-card>
                  </td>
                </template>
              </v-data-table>
              <v-select
                v-model="beruf.ausgewaehlt"
                :items="berufe"
                item-text="name"
                return-object
                label="Beruf auswählen"
                outlined
                class="mt-3"
              ></v-select>
              <!-- Übersichtstabelle (Werte) von den Berufen -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="weiterBeruf()"
                :disabled="beruf.ausgewaehlt == undefined"
                >Weiter</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card>
            <v-card-text>
              <v-img src="../assets/Attributsbonustabelle.jpeg"></v-img>

              <v-card v-for="(kat,index) in Attributsbonus.boniWerte" :key="index">
                <v-card-title>
                  {{kat.name}}
                </v-card-title>
                <v-card-subtitle>
                  {{kat.beschreibung}}
                  <v-spacer></v-spacer>
                  Volksbonus: {{kat.boni}}
                </v-card-subtitle>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <v-chip>Wert: {{kat.wert}}</v-chip>
                    </v-col>
                    <v-col cols="4">
                      <v-chip :color="attributskosten > 35 ? 'error' : ''">Kosten: {{Attributsbonus.boniTabelle[kat.wert].Kaufpreis}}</v-chip>
                    </v-col>
                    <v-col cols="4">
                      <v-chip>Bonus: {{Attributsbonus.boniTabelle[kat.wert + kat.boni].Attributsbonus}}</v-chip>
                    </v-col>
                  </v-row>
                  <v-slider :min="kat.min" :max="kat.max" v-model="kat.wert"></v-slider>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              {{attributskosten}} / 35
              <v-spacer></v-spacer>
              <v-btn
                @click="weiterAttributsboni()"
                :disabled="attributskosten > 35"
              >
                Weiter</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card>
            <v-card-text>
              <v-text-field
                v-model="spieler.name"
                label="Name"
                outlined
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="fertig()">Fertig</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { storage } from "../store/config/firebase";
export default {
  data: () => ({
    refresh: false,
    step: 1,
    maxSteps: 4,
    stepNamen: ["", "Volk", "Beruf", "Attribute", "Name"],
    volk: {
      voelkerBoniHeader: [
        {
          text: "Volk",
          value: "name",
        },
        {
          text: "Stärke",
          value: "staerke",
        },
        {
          text: "Geschicklichkeit",
          value: "geschicklichkeit",
        },
        {
          text: "Konstitution",
          value: "konstitution",
        },
        {
          text: "Intelligenz",
          value: "intelligenz",
        },
        {
          text: "Charisma",
          value: "charisma",
        },
      ],
      volksBonus: {
        st: 0,
        ge: 0,
        ko: 0,
        in: 0,
        ch: 0,
      },
      voelkerExpand: [],
      ausgewaehlt: undefined,
    },
    beruf: {
      berufeHeader: [
        { text: "Beruf", value: "name" },
        { text: "Athletik", value: "kosten.Athletik" },
        { text: "Einflussnahme", value: "kosten.Einflussnahme" },
        { text: "Gezielte Sprüche", value: "kosten.Gezielte Sprüche" },
        { text: "Kunst", value: "kosten.Kunst" },
        { text: "List", value: "kosten.List" },
        { text: "Magie entwickeln", value: "kosten.Magie entwickeln" },
        { text: "Natur", value: "kosten.Natur" },
        { text: "Reiten", value: "kosten.Reiten" },
        { text: "Schwimmen", value: "kosten.Schwimmen" },
        { text: "Spruchliste", value: "kosten.Spruchliste" },
        { text: "Waffen", value: "kosten.Waffen" },
        { text: "Wahrnehmung", value: "kosten.Wahrnehmung" },
        { text: "Wissen", value: "kosten.Wissen" },
        { text: "Trefferpunkte", value: "trefferpunkte" },
      ],
      berufExpand: [],
      ausgewaehlt: undefined,
    },
    Attributsbonus: {
      boniTabelle: [
        { Kaufpreis: 0, Attributswert: 0, Attributsbonus: undefined },
        { Kaufpreis: 1, Attributswert: 1, Attributsbonus: -3 },
        { Kaufpreis: 2, Attributswert: 2, Attributsbonus: -2 },
        { Kaufpreis: 3, Attributswert: 3, Attributsbonus: -1 },
        { Kaufpreis: 4, Attributswert: 4, Attributsbonus: -1 },
        { Kaufpreis: 5, Attributswert: 5, Attributsbonus: 0 },
        { Kaufpreis: 6, Attributswert: 6, Attributsbonus: 1 },
        { Kaufpreis: 8, Attributswert: 7, Attributsbonus: 1 },
        { Kaufpreis: 10, Attributswert: 8, Attributsbonus: 2 },
        { Kaufpreis: 12, Attributswert: 9, Attributsbonus: 2 },
        { Kaufpreis: 16, Attributswert: 10, Attributsbonus: 3 },
        { Kaufpreis: 16, Attributswert: 11, Attributsbonus: 3 },
        { Kaufpreis: 16, Attributswert: 12, Attributsbonus: 4 },
      ],
      gesamtpreis: 0,
      boniHeader: {},
      boniWerte: [],
    },
    bilderlinks: {
      Mensch: "",
      Elf: "",
      Zwerg: "",
      Halbling: "",
      Gnom: "",
      Barde: "",
      Dieb: "",
      Krieger: "",
      Priester: "",
      Schamane: "",
      Waldläufer: "",
      Zauberer: "",
    },
  }),
  created() {
    this.bildlink();
  },
  computed: {
    attributskosten(){
      let ergebnis = 0
      this.Attributsbonus.boniWerte.forEach(element => {
        ergebnis += this.Attributsbonus.boniTabelle[element.wert].Kaufpreis
      });
      return ergebnis
    },
    spieler() {
      return this.$store.getters["SpielerEinzeln/getSpieler"];
    },
    voelker() {
      return this.$store.getters["Tabellen/getVoelker"];
    },
    berufe() {
      return this.$store.getters["Tabellen/getBerufe"];
    },
  },
  watch: {},
  methods: {
    weiterVolk() {
      let temp = this.volk.ausgewaehlt;
      this.Attributsbonus.boniWerte = []
      this.volk.volksBonus = {
        st: temp.staerke,
        ge: temp.geschicklichkeit,
        ko: temp.konstitution,
        in: temp.intelligenz,
        ch: temp.charisma,
      };
      let abkToWort = {
        st: "Stärke",
        ge: "Geschicklichkeit",
        ko: "Konstitution",
        in: "Intelligenz",
        ch: "Charisma"
      }
      let minWert = 0
      for (let kategorie in this.volk.volksBonus){
        minWert = this.volk.volksBonus[kategorie] < 0 ? this.volk.volksBonus[kategorie]*-1 +1 : this.volk.volksBonus[kategorie] +1
        this.Attributsbonus.boniWerte.push({
          abk: kategorie,
          name: abkToWort[kategorie],
          beschreibung: "",
          min: minWert,
          max: 10,
          wert: minWert,
          boni: this.volk.volksBonus[kategorie]
        })
      }
      if (temp.name == "Mensch") {
        this.spieler.ausbildungspunkte = 10;
      } else {
        this.spieler.ausbildungspunkte = 8;
      }
      this.spieler.volk = temp.name;
      this.step++;
    },
    weiterBeruf() {
      let temp = Object.keys(this.spieler.fertigkeitsraenge);
      temp.forEach((rangName) => {
        this.spieler.fertigkeitsraenge[
          rangName
        ].kosten = this.beruf.ausgewaehlt.kosten[rangName];
      });
      this.spieler.berufTP = this.beruf.ausgewaehlt.trefferpunkte;
      this.spieler.berufWaffe = this.beruf.ausgewaehlt.kosten.Waffen.split("/");
      this.spieler.berufWissen = this.beruf.ausgewaehlt.kosten.Wissen;
      this.spieler.berufZauber = this.beruf.ausgewaehlt.kosten.Spruchliste.split(
        "/"
      );
      this.spieler.beruf = this.beruf.ausgewaehlt.name;
      this.spieler.fertigkeitsraenge[
        "Magie entwickeln"
      ].kosten = this.beruf.ausgewaehlt.kosten["Magie entwickeln"].split("/");
      this.step++;
    },
    fertig() {
      this.spieler.levelAufstieg = true;
      this.spieler.fertigErstellt = true;
      this.spieler.geld = this.getZufallszahl() * 500;
      this.$store.dispatch("SpielerEinzeln/set", this.spieler);
      // console.log("Hier dann fertigstellen");
    },
    bildlink() {
      let storeageRef = storage.ref();
      let imageRef = storeageRef.child("Bilddateien");
      let self = this;
      imageRef.listAll().then((res) => {
        res.items.forEach((item) => {
          // console.log("Item", item.name);
          let name = item.name.split(".")[0];
          item.getDownloadURL().then((url) => {
            //eslint-disable-next-line
            // console.log("Url", url);
            self.bilderlinks[name] = url;
            self.refresh = !self.refresh;
          });
        });
      });
      // console.log("ImageRef", imageRef)
      //   let self = this;
      //   imageRef
      //     .getDownloadURL()
      //     .then(function (url) {
      //       // Insert url into an <img> tag to "download"
      //       //eslint-disable-next-line
      //       console.log("Dieser Fall", url);
      //       self.bilderlinks.Mensch = url;
      //     })
      //     .catch(function (error) {
      //       // A full list of error codes is available at
      //       // https://firebase.google.com/docs/storage/web/handle-errors
      //       //eslint-disable-next-line
      //       console.error(error);
      //       self.bilderlinks.Mensch = "";
      //     });
      // },
    },
    weiterAttributsboni() {
      this.Attributsbonus.boniWerte.forEach(element => {
        this.spieler.boniWerte[element.abk] = element.wert
        this.spieler.boni[element.abk] = this.Attributsbonus.boniTabelle[element.wert + element.boni].Attributsbonus
      });
      this.step++;
    },
    getZufallszahl() {
      let temp = Math.ceil(Math.random() * 10);
      while (temp % 10 == 0) {
        temp += Math.ceil(Math.random() * 10);
      }
      return temp;
    },
  },
};
</script>
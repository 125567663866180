<template>
  <v-app v-if="authTrue" id="main" :style="hintergrundbild">
    <!-- <div id="main" :style="hintergrundbild"> -->
    <Navbar />
    <!-- <v-img src="https://firebasestorage.googleapis.com/v0/b/aborea-pen-and-paper.appspot.com/o/Spielleiter-Geschichte%2FAboreaSchriftZug.jpg?alt=media&token=88c7e221-aef1-4ce1-ac68-4674e5893c24" aspect-ratio=""></v-img> -->
    <!-- Hier könnte noch eine Login-Komponente hin. Diese könnte aus dem anderen kopiert werden, oder selber neu -->
    <v-main>
      <!-- <v-btn @click="openSpielleiter">sPIELLEITER</v-btn>
        <v-btn @click="openSpieler">Spieler</v-btn> -->
      <v-btn @click="push" v-if="dev">Push</v-btn>
      <router-view></router-view>
      <v-snackbar v-model="snackbar.open" :color="snackbar.color">{{ snackbar.text }} </v-snackbar>
    </v-main>
    <!-- </div> -->
  </v-app>
  <v-app v-else>
    <Home :loading="loading" @loginWait="setLogin" />
    <v-snackbar v-model="snackbar.open" :color="snackbar.color">{{ snackbar.text }} </v-snackbar>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";
import { auth } from "./store/config/firebase.js";
import { db } from "./store/config/firebase.js";
import Home from "./views/Home";

import { storage } from "./store/config/firebase";
// import Produkte from "../DatenJson/Produkte.json"
// import AllgemeineSpielerdaten from "../DatenJson/AllgemeineSpielerdaten.json"

export default {
  name: "App",

  components: {
    Navbar,
    Home
  },

  data: () => ({
    //spieler: {trefferpunkteAkt:0},
    dev: process.env.NODE_ENV !== "production",
    authTrue: false,
    loading: false,
    userData: undefined,
    namensListe: {
      emiras: "/#/spieler/Emiras",
      forhonor: "/#/spieler/For Honor",
      spielleiter: "/#/spielleiter"
    },
    hintergrundbildListe: {}
  }),
  beforeCreate() {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.hintergrundbilder();
        if (this.$store.state.fertigErstellt) {
          this.onLoginComplete(user);
        } else {
          this.userData = user;
        }
      } else {
        this.$store.dispatch("Spieler/closeDBChannel");
        this.$store.dispatch("NPCs/closeDBChannel");
        this.$store.dispatch("Tabellen/closeDBChannel");
        this.$store.dispatch("Geschichte/closeDBChannel");
        this.$store.commit("setSpielleiter", undefined);
        this.$store.commit("setSpiel", undefined);
        this.$store.commit("setSpielerMail", undefined);
        window.open("/#/", "_self");
        this.authTrue = false;
        this.loading = false;
        console.log("not logged");
      }
    });
  },
  beforeDestroy() {
    auth.signOut();
  },
  computed: {
    loginComplete() {
      return this.$store.state.fertigErstellt;
    },
    hintergrundbild() {
      return "background-image: url('" + this.hintergrundbildListe[this.$store.state.hintergrundbild] + "');";
    },
    snackbar() {
      return this.$store.getters["getSnackbar"];
    }
  },
  watch: {
    loginComplete: function(val) {
      if (val && this.userData != undefined && auth.currentUser != undefined) {
        this.onLoginComplete(this.userData);
      }
    }
  },
  methods: {
    onLoginComplete(user) {
      this.loading = true;
      db.collection("Spieler")
        .doc(user.uid)
        .get()
        .then(snapshot => {
          // console.log("Aufgeschlüsselt",snapshot.data())
          let spieler = snapshot.data();
          this.$store.commit("setSpielleiter", spieler.spielleiter);
          this.$store.commit("setSpiel", spieler.spiel);
          this.$store.commit("setSpielerMail", user.email);
          let spiel = this.$store.getters.getSpiel;
          if (spieler.spielleiter) {
            this.$store
              .dispatch("Spieler/openDBChannel", {
                pathVariables: { spielID: spiel }
              })
              .then(() => {
                this.$store
                  .dispatch("NPCs/openDBChannel", {
                    pathVariables: { spielID: spiel }
                  })
                  .then(() => {
                    this.$store
                      .dispatch("Geschichte/openDBChannel", {
                        pathVariables: { spielID: spiel }
                      })
                      .then(() => {
                        this.$store
                          .dispatch("Tabellen/openDBChannel", {
                            pathVariables: { spielID: spiel }
                          })
                          .then(() => {
                            this.loading = false;
                            this.authTrue = true;
                            window.open("/#/spielleiter/", "_self");
                          });
                      });
                  });
              });
          } else {
            this.$store
              .dispatch("SpielerEinzeln/openDBChannel", {
                pathVariables: { spielID: spiel, userID: user.uid }
              })
              .then(() => {
                this.$store
                  .dispatch("Tabellen/openDBChannel", {
                    pathVariables: { spielID: spiel }
                  })
                  .then(() => {
                    this.$store
                      .dispatch("Geschichte/openDBChannel", {
                        pathVariables: { spielID: spiel }
                      })
                      .then(() => {
                        this.loading = false;
                        this.authTrue = true;
                        let temp = this.$store.getters["SpielerEinzeln/getSpieler"].name;
                        temp = "/#/spieler/" + temp;
                        console.log("Beim Einloggen", temp);
                        window.open(temp, "_self");
                      });
                  });
              });
          }
        });
      // this.$store.dispatch('Spieler/openDBChannel').then(() => {
      //   this.$store.dispatch('NPCs/openDBChannel').then(() => {
      //     this.$store.dispatch("Geschichte/openDBChannel").then(() => {
      //       this.$store.dispatch('Tabellen/openDBChannel').then(()=>{
      //         let tempName = user.email.split("@")[0]
      //         if (tempName == "spielleiter"){
      //           tempName = "/#/spielleiter"
      //         } else {
      //           tempName = "/#/spieler/" + user.email
      //         }
      //         // tempName = this.namensListe[tempName]
      //         // console.log("temp", tempName)
      //         setTimeout(()=>{
      //           this.authTrue = true
      //           this.loading = false
      //           window.open(tempName, "_self")
      //         },400)

      //       })
      //     })
      //   })
      // }).catch(err => {
      //   console.log(err)
      //   this.authTrue = true
      //   let tempName = user.email.split("@")[0]
      //   if (tempName == "spielleiter"){
      //     tempName = "/#/spielleiter"
      //   } else {
      //     tempName = "/#/spieler/" + user.email
      //   }
      //   // tempName = this.namensListe[tempName]
      //   // console.log("temp", tempName)
      //   window.open(tempName, "_self")
      // })

      //console.log("logged in")
    },
    openSpielleiter() {
      window.open("/#/spielleiter/", "_self");
    },
    openSpieler() {
      window.open("/#/spieler/For Honor", "_self");
    },
    saveLive() {
      this.spieler.trefferpunkteAkt = 20;
      //console.log(this.$store.getters['Spieler/getAlleSpieler'])
      //this.$store.dispatch('Spieler/set',this.spieler)
    },
    push() {
      // this.$store.dispatch("setSnackbar", { text: "Push geklickt", color: "success", time: 4 });
      // db.collection("Spiele").doc("DevSpiel").collection("Tabellen").doc("AllgemeineSpielerdaten").set(AllgemeineSpielerdaten)
      // db.collection("Spiele").doc("A01KDeIedOoLMxlNhEpK").collection("Tabellen").doc("AllgemeineSpielerdaten").set(AllgemeineSpielerdaten)
      // db.collection("Tabellen").doc("AllgemeineSpielerdaten").set(AllgemeineSpielerdaten)
      // db.collection("Spiele").doc("DevSpiel").collection("Tabellen").doc("Produkte").set(Produkte)
      // db.collection("Tabellen").doc("Produkte").set(Produkte)
      //let temp = this.$store.getters["Tabellen/getProdukte"]
      // temp.Ruestungen = ruestungen
      // db.collection("Tabellen").doc("Produkte").set(temp)
      // db.collection("Spiele").doc("DevSpiel").collection("Tabellen").doc("Produkte").set(temp)
      // db.collection("Spiele").doc("OnstuikhcdlTdhK9euSg").collection("Tabellen").doc("Produkte").set(temp)
      // temp.Berufe = berufe
      // console.log(berufe)
      // console.log(temp)
      // db.collection("Tabellen").doc("AllgemeineSpielerdaten").set(temp)
      // console.log("Spielleiter", this.$store.state.spielleiter)
      // console.log("Spieler", this.$store.state.spielermail)
      // console.log("Spiel", this.$store.state.spiel)
      // console.log("Spielervorlage unbehandelt", SpielerVorlage)
      // console.log("Spielervorlage geparst")
      // let spieler = undefined
      // db.collection("Tabellen").doc("AllgemeineSpielerdaten").get().then(doc => {
      //   spieler = doc.data()
      //   db.collection("Spiele").doc("OnstuikhcdlTdhK9euSg").collection("Tabellen").doc("AllgemeineSpielerdaten").set(spieler)
      // })
      // db.collection("Spiele").doc("DevSpiel").collection("Tabellen").doc("Gegner").set(spieler.Gegner)
      // db.collection("Spiele").doc("DevSpiel").collection("Tabellen").doc("Produkte").set(spieler.Produkte)
      // this.$store.dispatch("Spieler/set",spieler)
      // let spruchlisten = listen
      // let temp = this.$store.getters["Tabellen/getAlle"].Produkte
      // temp.Waffen = spruchlisten
      // this.$store.dispatch("Tabellen/set",temp)
      // let temp = this.$store.getters["Tabellen/getGegner"]
      // console.log(temp)
      // let liste = Object.keys(temp)
      // let ergebnis = []
      // liste.forEach(elem => {
      //   console.log(elem)
      //   ergebnis.push(temp[elem])
      // });
      // console.log(ergebnis.slice(0,2))
      // temp = this.$store.getters["Tabellen/getAlle"].Gegner
      // temp.gegner = ergebnis.slice(0,2)
      // this.$store.dispatch("Tabellen/set",temp)
      // funktioniert so noch nicht
    },
    setLogin(payload) {
      this.loading = payload;
    },
    hintergrundbilder() {
      let self = this;
      let storeageRef = storage.ref();
      storeageRef
        .child("Hintergrund")
        .listAll()
        .then(res => {
          res.items.forEach(item => {
            item
              .getDownloadURL()
              .then(function(url) {
                // Insert url into an <img> tag to "download"
                //eslint-disable-next-line
                // console.log("Dieser Fall", url);
                let name = item.location.path.split("/")[1].split(".")[0];
                self.hintergrundbildListe[name] = url;
              })
              .catch(function(error) {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                //eslint-disable-next-line
                console.error(error);
              });
          });
        });
    }
  }
};
</script>

<style>
#main {
  /* background-image: url("https://cdn.discordapp.com/attachments/626499151621783573/789868970172743680/trace.png"); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.theme--light.v-card {
  background-color: rgb(255, 255, 255, 0.82);
  color: rgba(0, 0, 0, 0.8);
}
.theme--light.v-sheet {
  background-color: rgb(255, 255, 255, 0.8);
  color: rgba(0, 0, 0, 0.8);
}
</style>

<template>
  <v-container>
    <v-row dense>
      <v-col :cols="12 / daten.length" v-for="(item, index) in daten" :key="index">
        <v-text-field v-model="item.zahl" @input="handleInput" outlined :label="item.name" type="number"></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["value"],
  data: function() {
    return {};
  },
  computed: {
    daten() {
      let waehrung = this.$store.getters["Geschichte/getAktWaehrung"];
      waehrung.forEach((item, index) => {
        item.zahl =
          index != 0 ? parseInt((this.value % waehrung[index - 1].kurs) / item.kurs) : parseInt(this.value / item.kurs);
      });
      return waehrung;
    }
  },
  methods: {
    handleInput() {
      let ergebnis = 0;
      this.daten.forEach(element => {
        ergebnis += (isNaN(parseInt(element.zahl)) ? 0 : parseInt(element.zahl)) * element.kurs;
      });
      this.$emit("input", ergebnis);
    }
  }
};
</script>

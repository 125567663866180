const npcs = {
  firestorePath: 'Spiele/{spielID}/NPCs',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'NPCs',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getAlle: state => {
      return state.data
    },
    getNPC: state => name => {
      return state.data[name]
    }
  },
  mutations: {},
  actions: {},
}

export default npcs
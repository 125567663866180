<template>
  <v-container fluid fill-height>
    <v-card width="400" class="ma-auto" :loading="loading" v-if="variante == 'login'">
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-text-field v-model="username" label="Username"></v-text-field>
        <v-text-field
          v-model="passwort"
          label="Passwort"
          :type="showPasswort ? '' : 'password'"
          :append-outer-icon="showPasswort ? 'mdi-eye-off-outline' : 'mdi-eye-outline '"
          @click:append-outer="showPasswort = !showPasswort"
          @keypress.enter="login"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="login">Login</v-btn>
      </v-card-actions>
      <v-row no-gutters>
        <v-col cols="6">
          <v-btn block tile outlined small @click="variante = 'createGame'">Spiel erstellen</v-btn>
        </v-col>
        <v-col cols="6"
          ><v-btn block tile outlined small @click="variante = 'createPlayer'">Spieler erstellen</v-btn></v-col
        >
      </v-row>
    </v-card>

    <v-card width="400" class="ma-auto" :loading="loading" v-if="variante == 'createGame'">
      <v-card-title>Erstelle Spiel</v-card-title>
      <v-card-text>
        <v-text-field v-model="username" label="Username"></v-text-field>
        <v-text-field
          v-model="passwort"
          label="Passwort"
          :type="showPasswort ? '' : 'password'"
          :append-outer-icon="showPasswort ? 'mdi-eye-off-outline' : 'mdi-eye-outline '"
          @click:append-outer="showPasswort = !showPasswort"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="createGame">Create</v-btn>
      </v-card-actions>
      <v-row no-gutters>
        <v-col cols="6"><v-btn block tile outlined small @click="variante = 'login'">Anmelden</v-btn></v-col>
        <v-col cols="6"
          ><v-btn block tile outlined small @click="variante = 'createPlayer'">Spieler erstellen</v-btn></v-col
        >
      </v-row>
    </v-card>

    <v-card width="400" class="ma-auto" :loading="loading" v-if="variante == 'createPlayer'">
      <v-card-title>Neuer Spieler</v-card-title>
      <v-card-text>
        <v-text-field v-model="spielID" label="Game ID"></v-text-field>
        <v-text-field v-model="username" label="Username"></v-text-field>
        <v-text-field
          v-model="passwort"
          label="Passwort"
          :type="showPasswort ? '' : 'password'"
          :append-outer-icon="showPasswort ? 'mdi-eye-off-outline' : 'mdi-eye-outline '"
          @click:append-outer="showPasswort = !showPasswort"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="createPlayer">Create</v-btn>
      </v-card-actions>
      <v-row no-gutters>
        <v-col cols="6"><v-btn block tile outlined small @click="variante = 'login'">Anmelden</v-btn></v-col>
        <v-col cols="6">
          <v-btn block tile outlined small @click="variante = 'createGame'">Spiel erstellen</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { auth } from "../store/config/firebase";
import { db } from "../store/config/firebase";
import SpielerVorlage from "../../DatenJson/SpielerBlanko.json";

export default {
  props: ["loading"],
  data: () => ({
    variante: "login",
    spielID: "",
    username: "",
    passwort: "",
    showPasswort: false,
    namensListe: {
      emiras: "/#/spieler/Emiras",
      forhonor: "/#/spieler/For Honor",
      spielleiter: "/#/spielleiter"
    }
  }),
  created() {
    this.spielID = window.location.href.split("/").reverse()[0];
    if (this.spielID.length == 20) {
      this.variante = "createPlayer";
    }
  },
  computed: {},
  methods: {
    login() {
      this.$emit("loginWait");
      let username = this.username + "@margel.me";
      auth
        .signInWithEmailAndPassword(username, this.passwort)
        .then(cred => {
          console.log("Logged in: ", cred.user.email);
          // this.$store.dispatch('Spieler/openDBChannel')
          // this.$store.dispatch('NPCs/openDBChannel')
          // this.$store.dispatch('Tabellen/openDBChannel').then(()=>{
          //   let tempName = cred.user.email.split("@")[0]
          //   tempName = this.namensListe[tempName]
          //   console.log("temp", tempName)
          //   this.load = false
          //   window.open(tempName, "_self")
          // })
        })
        .catch(err => {
          this.$emit("loginWait", false);
          this.$store.dispatch("setSnackbar", { text: err.code.split("/")[1], color: "error", time: 4 });
        });
    },
    createGame() {
      this.$store.commit("setFertigErstellt", false);
      this.$emit("loginWait", true);
      let username = this.username + "@margel.me";
      auth
        .createUserWithEmailAndPassword(username, this.passwort)
        .then(cred => {
          auth.currentUser.sendEmailVerification();
          db.collection("Spiele")
            .add({
              geschichte: "",
              name: "",
              aktiveWaehrung: "Trion",
              waehrungen: {
                Trion: [
                  {
                    kurs: 1000,
                    imgurl:
                      "https://firebasestorage.googleapis.com/v0/b/aborea-pen-and-paper.appspot.com/o/Waehrungen%2FGoldfalke.png?alt=media&token=672108f2-43f2-4460-9cb8-da7ba28927e0",
                    name: "Goldfalke"
                  },
                  {
                    imgurl:
                      "https://firebasestorage.googleapis.com/v0/b/aborea-pen-and-paper.appspot.com/o/Waehrungen%2FTriontaler.png?alt=media&token=d54b69bb-85e9-4153-8b08-982181fe5e5b",
                    name: "Triontaler",
                    kurs: 100
                  },
                  {
                    imgurl:
                      "https://firebasestorage.googleapis.com/v0/b/aborea-pen-and-paper.appspot.com/o/Waehrungen%2FKupferling.png?alt=media&token=215968ca-6c35-40ad-9189-845dc29b4ce0",
                    name: "Kupferling",
                    kurs: 10
                  },
                  {
                    kurs: 1,
                    imgurl:
                      "https://firebasestorage.googleapis.com/v0/b/aborea-pen-and-paper.appspot.com/o/Waehrungen%2FMuena.png?alt=media&token=0506e325-cbdc-4157-9126-0bd4961f7c98",
                    name: "Muena"
                  }
                ]
              }
            })
            .then(docRef => {
              let gameID = docRef.id;
              this.$store.commit("setSpielleiter", true);
              this.$store.commit("setSpiel", gameID);
              this.$store.commit("setSpielerMail", username);
              db.collection("Tabellen")
                .get()
                .then(docs => {
                  docs.forEach(doc => {
                    db.collection("Spiele")
                      .doc(gameID)
                      .collection("Tabellen")
                      .doc(doc.id)
                      .set(doc.data());
                  });
                });
              db.collection("Spieler")
                .doc(cred.user.uid)
                .set({
                  spiel: gameID,
                  spielleiter: true,
                  email: cred.user.email,
                  passwort: this.passwort
                })
                .then(() => {
                  this.$store.commit("setFertigErstellt", true);
                });
            });
        })
        .catch(err => {
          this.$emit("loginWait", false);
          this.$store.dispatch("setSnackbar", { text: err.code.split("/")[1], color: "error", time: 4 });
          this.$store.commit("setFertigErstellt", true);
        });
    },
    createPlayer() {
      this.$emit("loginWait", true);
      this.$store.commit("setFertigErstellt", false);
      let username = this.username + "@margel.me";
      auth
        .createUserWithEmailAndPassword(username, this.passwort)
        .then(cred => {
          this.$store.commit("setSpielleiter", false);
          this.$store.commit("setSpiel", this.spielID);
          this.$store.commit("setSpielerMail", username);
          // console.log("Bla",cred.user.uid)
          db.collection("Spieler")
            .doc(cred.user.uid)
            .set({
              spiel: this.spielID,
              spielleiter: false,
              passwort: this.passwort,
              email: cred.user.email
            })
            .then(() => {
              let vorlage = SpielerVorlage;
              vorlage.id = cred.user.uid;
              db.collection("Spiele")
                .doc(this.spielID)
                .collection("Spieler")
                .doc(cred.user.uid)
                .set(vorlage)
                .then(() => {
                  this.$store.commit("setFertigErstellt", true);
                });
            });
        })
        .catch(err => {
          this.$emit("loginWait", false);
          this.$store.dispatch("setSnackbar", { text: err.code.split("/")[1], color: "error", time: 4 });
          this.$store.commit("setFertigErstellt", true);
        });
    }
  }
};
</script>

<template>
  <v-container>
    <v-card>
      <v-card-title
        >{{ spieler.name }}
        <v-spacer></v-spacer>
        <v-btn @click="allesNeuBerechnen">Save</v-btn>
      </v-card-title>
      <v-card-text>
        <v-chip>
          <v-icon x-small left @click="spieler.level = parseInt(spieler.level) - 1">mdi-minus</v-icon>
          Level: {{ spieler.level }}
          <v-icon x-small right @click="spieler.level = parseInt(spieler.level) + 1">mdi-plus</v-icon>
        </v-chip>

        <v-row>
          <!-- Name, Beruf, Volk -->
          <v-col class="col-4 col-sm-4">
            <v-text-field v-model="spieler['name']" label="Name" outlined></v-text-field>
          </v-col>
          <v-col class="col-4 col-sm-4">
            <v-text-field v-model="spieler['volk']" label="Volk" outlined></v-text-field>
          </v-col>
          <v-col class="col-4 col-sm-4">
            <v-text-field v-model="spieler['beruf']" label="Beruf" outlined></v-text-field>
          </v-col>

          <v-col cols="6" id="Attribute">
            <v-card>
              <v-card-title>Attribute</v-card-title>
              <v-card-text>
                <v-row dense v-for="(wert, index) in boniWerteTabelle" :key="index">
                  <v-col cols="4">
                    <h1>{{ wert }}</h1>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field type="number" v-model="spieler.boniWerte[index]" outlined></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-icon x-large>mdi-arrow-right-bold</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field type="number" v-model="spieler.boni[index]" outlined readonly></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6" id="Geld">
            <!-- TODO -->
          </v-col>

          <v-col cols="6" id="Stats">
            <v-card>
              <v-card-title>Stats</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Maximale Trefferpunkte"
                      v-model="spieler.trefferpunkteGesamt"
                      outlined
                    ></v-text-field
                  ></v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Maximale Magiepunkte"
                      v-model="spieler.magiepunkteGesamt"
                      outlined
                    ></v-text-field
                  ></v-col>
                  <v-col cols="6">
                    <v-text-field label="Rüstung" v-model="spieler.ruestung" outlined></v-text-field
                  ></v-col>
                  <v-col cols="6">
                    <v-text-field label="Ausbildungspunkte" v-model="spieler.ausbildungspunkte" outlined></v-text-field
                  ></v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col id="Fertigkeiten">
            <v-card>
              <v-card-title
                >Fertigkeiten
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  small
                  @click="
                    spieler.fertigkeitsraenge['Neu'] = { kosten: 0, rang: 0, zwischenRang: 0 };
                    refreshFertigkeiten = !refreshFertigkeiten;
                  "
                >
                  <v-icon>mdi-plus</v-icon></v-btn
                >
              </v-card-title>
              <v-card-text key="refreshFertigkeiten">
                <v-row dense v-for="(fert, name) in fertigkeitsraenge" :key="name">
                  <v-col cols="3">
                    <v-text-field
                      dense
                      label="Name"
                      v-model="fert.name"
                      outlined
                      @blur="toSaveFertigkeiten"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      dense
                      label="Kosten"
                      v-model="fert.kosten"
                      outlined
                      @blur="toSaveFertigkeiten"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      dense
                      label="Rang"
                      type="number"
                      v-model="fert.rang"
                      outlined
                      @blur="toSaveFertigkeiten"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      dense
                      label="Zwischenrang"
                      type="number"
                      v-model="fert.zwischenRang"
                      outlined
                      @blur="toSaveFertigkeiten"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-icon
                      @click="
                        delete spieler.fertigkeitsraenge[fert.name];
                        refreshFertigkeiten = !refreshFertigkeiten;
                      "
                      >mdi-delete</v-icon
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-dialog>
              <v-card>
                <v-card-title>Neue Fertigkeit</v-card-title>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    boniWerteTabelle: {
      st: "Stärke",
      ge: "Geschicklichkeit",
      ko: "Konstitution",
      in: "Intelligenz",
      ch: "Charisma"
    },
    refreshFertigkeiten: true
  }),
  computed: {
    spieler() {
      let name = this.$route.params.id;
      return this.$store.getters["Spieler/getSpielerByName"](name);
    },
    fertigkeitsraenge() {
      //eslint-disable-next-line
      this.refreshFertigkeiten = !this.refreshFertigkeiten;
      let keys = Object.keys(this.spieler.fertigkeitsraenge);
      let result = [];
      keys.forEach(key => {
        let elem = this.spieler.fertigkeitsraenge[key];
        elem.name = key;
        result.push(elem);
      });
      result.sort((a, b) => (a.name < b.name ? -1 : 1));
      return result;
    }
  },
  methods: {
    toSaveFertigkeiten() {
      let result = {};
      this.fertigkeitsraenge.forEach(element => {
        let kosten = element.kosten.toString();
        kosten = kosten.split(",");
        if (kosten.length > 1) {
          if (kosten[1] == "") {
            kosten = isNaN(parseInt(kosten[0])) ? 0 : parseInt(kosten[0]);
          } else {
            kosten = [
              isNaN(parseInt(kosten[0])) ? 0 : parseInt(kosten[0]),
              isNaN(parseInt(kosten[1])) ? 0 : parseInt(kosten[1])
            ];
          }
        } else {
          kosten = isNaN(parseInt(kosten[0])) ? 0 : parseInt(kosten[0]);
        }
        result[element.name] = {
          kosten: kosten,
          rang: isNaN(parseInt(element.rang)) ? 0 : parseInt(element.rang),
          zwischenRang: isNaN(parseInt(element.zwischenRang)) ? 0 : parseInt(element.zwischenRang)
        };
      });
      this.spieler.fertigkeitsraenge = result;
      this.refreshFertigkeiten = !this.refreshFertigkeiten;
    },
    allesNeuBerechnen() {
      this.waffenUpdaten();
      this.ruestungenUpdaten();
      this.spruchlistenUpdaten();
      this.werteUpdaten();

      this.$store.dispatch("Spieler/set", this.spieler);
    },
    waffenUpdaten() {
      let waffen = this.spieler.waffen;
      for (let i in waffen) {
        // console.log(waffen[i]);
        let bonus = -10;
        for (let wert in waffen[i].attribut) {
          if (this.spieler.boni[waffen[i].attribut[wert].toLowerCase()] > bonus) {
            bonus = this.spieler.boni[waffen[i].attribut[wert].toLowerCase()];
          }
        }
        let fertigkeit = -2;
        for (let wert in waffen[i].gruppe) {
          for (let zeile in this.spieler.fertigkeitsraenge) {
            if (zeile.search(waffen[i].gruppe[wert]) >= 0) {
              if (
                this.spieler.fertigkeitsraenge[zeile].rang > fertigkeit &&
                this.spieler.fertigkeitsraenge[zeile].rang > 0
              ) {
                fertigkeit = this.spieler.fertigkeitsraenge[zeile].rang;
              }
            }
          }
        }
        if (this.spieler.beruf == "Krieger") {
          if (fertigkeit < 0) {
            fertigkeit = 0;
          }
        }
        if (this.spieler.beruf == "Waldläufer") {
          if (waffen[i].gruppe == "Armbrust" || waffen[i].gruppe == "Bogen") {
            if (fertigkeit < 0) {
              fertigkeit = 0;
            }
          }
        }
        waffen[i].kb = parseInt(fertigkeit) + parseInt(bonus);
        waffen[i].ini = parseInt(waffen[i]["iniBonus"]) + parseInt(this.spieler.boni.ge);
      }
      this.spieler.waffen = waffen;
    },
    ruestungenUpdaten() {
      let ruestungswert = 5;
      for (let i in this.spieler.ruestungen) {
        ruestungswert += parseInt(this.spieler.ruestungen[i].ruestungsbonus);
      }
      if (this.spieler.beruf == "Dieb") {
        if (ruestungswert == 5) {
          ruestungswert += this.spieler.fertigkeitsraenge.Athletik.rang;
        }
      }
      if (this.spieler.volk == "Gnom" || this.spieler.volk == "Halbling" || this.spieler.volk == "Zwerg") {
        ruestungswert += 1;
      }
      this.spieler.ruestung = ruestungswert;
    },
    spruchlistenUpdaten() {
      let namenFertigkeiten = Object.keys(this.spieler.fertigkeitsraenge);
      namenFertigkeiten.forEach(element => {
        if (element.search("Spruchliste") >= 0) {
          let spruchlistenName = element.split('"')[1];
          if (this.spieler.spruchlisten[spruchlistenName] == undefined) {
            this.spieler.spruchlisten[spruchlistenName] = { zauber: [] };
          }
          if (
            this.spieler.spruchlisten[spruchlistenName].zauber.length < this.spieler.fertigkeitsraenge[element].rang
          ) {
            let spruch = this.$store.getters["Tabellen/getSpruchlisten"];
            spruch.forEach(element => {
              if (element.name == spruchlistenName) {
                spruch = element;
              }
            });
            this.spieler.spruchlisten[spruchlistenName].zauber.push(
              spruch.zauber[this.spieler.spruchlisten[spruchlistenName].zauber.length]
            );
            if (
              this.spieler.spruchlisten[spruchlistenName].zauber.length < this.spieler.fertigkeitsraenge[element].rang
            ) {
              this.spieler.spruchlisten[spruchlistenName].zauber.push(
                spruch.zauber[this.spieler.spruchlisten[spruchlistenName].zauber.length]
              );
            }
          }
        }
      });
    },
    werteUpdaten() {
      let rangMagie = this.spieler.fertigkeitsraenge["Magie entwickeln"].rang;
      let berufeListe = ["Priester", "Priesterin", "Schamane", "Schamanin", "Barde", "Bardin"];
      if (berufeListe.indexOf(this.spieler.beruf) == -1) {
        this.spieler.magiepunkteGesamt = (this.spieler.boni.in + 3) * rangMagie;
      } else {
        this.spieler.magiepunkteGesamt = (this.spieler.boni.ch + 3) * rangMagie;
      }
      this.spieler.trefferpunkteGesamt = (this.spieler.berufTP + this.spieler.boni.ko) * this.spieler.level;
      if (this.spieler.volk == "Zwerg") {
        this.spieler.trefferpunkteGesamt += 2;
      }
    }
  }
};
</script>

<template>
  <div>
    <v-btn @click="open = true">Geschichte</v-btn>
    <v-dialog v-model="open" :persistent="bearbeiten" width="unset">
      <v-container v-if="!bearbeiten" ref="container">
        <v-app-bar>
          <v-toolbar-title>Meine Geschichte</v-toolbar-title>
        </v-app-bar>
        <v-sheet max-height="1000" class="overflow-y-auto" id="cont">
          <v-container v-html="story" id="text"></v-container>
        </v-sheet>
        <!-- <img src="https://i.stack.imgur.com/owD5K.png" alt="Tada" style="display: inline-block"> -->
        <v-bottom-navigation>
          <v-spacer></v-spacer>
          <v-btn @click="bearbeiten = true">Bearbeiten</v-btn>
        </v-bottom-navigation>
      </v-container>

      <v-container v-else ref="container">
        <v-app-bar>
          <v-toolbar-title>Meine Geschichte</v-toolbar-title><v-spacer></v-spacer>
          <v-btn @click="einfuegen(0)">Fett</v-btn>
          <v-btn @click="einfuegen(1)">Kursiv</v-btn>
          <v-btn @click="einfuegen(2)">Ü1</v-btn>
          <v-btn @click="einfuegen(3)">Ü2</v-btn>
          <v-btn @click="einfuegen(4)">Bild</v-btn>
        </v-app-bar>
        <v-sheet max-height="1000" class="overflow-y-auto">
          <v-container>
            <v-textarea v-model="geschichteBearbeiten" id="ta" autofocus ref="taRef" auto-grow></v-textarea>
          </v-container>
        </v-sheet>
        <v-bottom-navigation scroll-target="ta" >
          <v-btn @click="geschichteBearbeiten = story; bearbeiten = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="bearbeiten = false">Speichern</v-btn>
        </v-bottom-navigation>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    open: false,
    bearbeiten: false,
    geschichteBearbeiten: "Diesen String (bearbeiten)",
    width: 0
  }),
  computed:{
    story(){
      return this.$store.getters["Geschichte/getGeschichte"]
    }
  },
  watch:{
    bearbeiten: function(val){
      if (val){
        this.geschichteBearbeiten = this.story.split("<br>").join("\n")
        this.geschichteBearbeiten = this.geschichteBearbeiten.split("<p>").join("")
        this.geschichteBearbeiten = this.geschichteBearbeiten.split("</p>").join("")
      } else {
        this.geschichteBearbeiten = this.geschichteBearbeiten.split("\n").join("<br>")
        this.geschichteBearbeiten = "<p>" + this.geschichteBearbeiten + "</p>"
        this.$store.dispatch("Geschichte/patch",{geschichte: this.geschichteBearbeiten})
        this.geschichteBearbeiten = ""
      }
    }
  },
  methods:{
    splitten(){
      let temp = document.getElementById("ta")
      let curser = temp.selectionStart
      let davorString = this.geschichteBearbeiten.slice(0,curser)
      let danachString = this.geschichteBearbeiten.slice(curser)
      return [davorString,danachString,curser]
    },
    einfuegen(typ){
      let typen = ["<b></b>","<i></i>","<h1></h1>","<h2></h2>",'<img src="" align="left" style="margin: 5px 15px 5px 0px" width="200" height="300">']
      let strArray = this.splitten()
      this.geschichteBearbeiten = strArray[0] + typen[typ] + strArray[1]
      this.$refs.taRef.focus(strArray[2])
      let posAdd = strArray[2] + ( (typen[typ].length-1) / 2)
      if (typ == 4) {
        posAdd = strArray[2] + 10
      }
      setTimeout(() => {
        document.getElementById("ta").selectionStart = posAdd
        document.getElementById("ta").selectionEnd = posAdd
      },10)
      
    },
  }
}
</script>

<template>
  <v-container>
    <v-card>
      <v-card-title @click="open = !open">Marktplatz</v-card-title>
      <v-card-text v-if="open">
        <v-tabs v-model="tab" grow>
          <v-tab>Verkaufen</v-tab>
          <v-tab>Kaufen</v-tab>
          <v-tab>Zwischen Spielern</v-tab>
          <v-tab>Geld</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item ref="Verkaufen">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="verkaufen.neuesElement"
                      label="Neu"
                      @change="verkaufen.neuesElement ? neuVerkaufenEinrichten() : (verkaufen.element = undefined)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      v-model="verkaufen.spieler"
                      label="Spieler"
                      outlined
                      multiple
                      clearable
                      chips
                      :items="alleSpieler"
                      item-text="name"
                      return-object
                    ></v-select>
                  </v-col>
                </v-row>
                <v-card flat>
                  <v-card-subtitle v-if="!verkaufen.neuesElement">
                    <v-row>
                      <v-col cols="6">
                        <v-select
                          v-model="verkaufen.kategorie"
                          label="Filter Kategorie"
                          outlined
                          clearable
                          :items="kategorien"
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          label="Suche"
                          outlined
                          v-model="verkaufen.element"
                          clearable
                          return-object
                          :items="produkteGefiltert"
                          item-text="name"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                  <v-card-subtitle v-else>
                    <v-radio-group v-model="verkaufenNeu.kategorie" row @change="neuVerkaufenEinrichten()">
                      <v-radio label="Waffe" value="waffen"></v-radio>
                      <v-radio label="Rüstung" value="ruestungen"></v-radio>
                      <v-radio label="Sonstiges" value="sonstiges"></v-radio>
                    </v-radio-group>
                  </v-card-subtitle>

                  <v-card-text v-if="verkaufen.element != undefined && verkaufen.element.bereich == 'Waffen'">
                    <v-text-field v-model="verkaufen.element.name" label="Name" outlined></v-text-field>
                    <v-select
                      v-model="verkaufen.element.attribut"
                      label="Attribut"
                      outlined
                      multiple
                      :items="attribute"
                    ></v-select>
                    <v-select
                      v-model="verkaufen.element.gruppe"
                      label="Waffengruppe"
                      outlined
                      multiple
                      :items="waffenGruppen"
                    ></v-select>
                    <v-text-field v-model="verkaufen.element.iniBonus" label="Ini Bonus" outlined></v-text-field>
                    <v-text-field v-model="verkaufen.element.schaden" label="Schaden" outlined></v-text-field>
                    <v-text-field v-model="verkaufen.element.minST" label="Mind. Stärke" outlined></v-text-field>
                    <v-text-field
                      v-model="verkaufen.element.kg"
                      label="Gewicht (kg)"
                      outlined
                      type="number"
                    ></v-text-field>
                    <v-text-field v-model="verkaufen.element.beschreibung" outlined label="Anmerkung"></v-text-field>
                    <PreisEingabe v-model="verkaufen.element.preis" :key="verkaufen.element.name" />
                  </v-card-text>
                  <v-card-text v-else-if="verkaufen.element != undefined && verkaufen.element.bereich == 'Ruestungen'">
                    <v-text-field v-model="verkaufen.element.name" label="Name" outlined></v-text-field>
                    <v-text-field
                      v-model="verkaufen.element.ruestungsbonus"
                      label="Rüstungsbonus"
                      outlined
                      type="number"
                    ></v-text-field>
                    <v-text-field
                      v-model="verkaufen.element.minST"
                      label="Mind. Stärke"
                      outlined
                      type="number"
                    ></v-text-field>
                    <v-text-field
                      v-model="verkaufen.element.manoever"
                      label="Manöver"
                      outlined
                      type="number"
                    ></v-text-field>
                    <v-text-field v-model="verkaufen.element.beschreibung" outlined label="Anmerkung"></v-text-field>
                    <PreisEingabe v-model="verkaufen.element.preis" :key="verkaufen.element.name" />
                  </v-card-text>
                  <v-card-text v-else-if="verkaufen.element != undefined">
                    <v-text-field v-model="verkaufen.element.name" label="Name" outlined></v-text-field>
                    <v-textarea
                      v-model="verkaufen.element.beschreibung"
                      label="Beschreibung"
                      outlined
                      :auto-grow="true"
                    ></v-textarea>
                    <PreisEingabe v-model="verkaufen.element.preis" :key="verkaufen.element.name" />
                  </v-card-text>
                  <v-card-text v-if="verkaufen.element">
                    <v-checkbox
                      v-model="verkaufen.alsSonstiges"
                      label="Als Sonstiges verkaufen"
                      v-if="verkaufen.element.bereich == 'Ruestungen' || verkaufen.element.bereich == 'Waffen'"
                    ></v-checkbox>
                    <v-row dense v-if="verkaufen.neuesElement">
                      <v-col>
                        <v-checkbox v-model="verkaufen.speichern" label="Speichern?"></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-select
                          v-model="verkaufen.bereichFuerSpeichern"
                          label="Kategorie"
                          outlined
                          :items="kategorien"
                          :disabled="!verkaufen.speichern"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="4">
                        <v-text-field label="Anzahl" type="number" v-model="verkaufen.anzahl" outlined></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-checkbox v-model="moneyPool.use" label="Moneypool"></v-checkbox>
                      </v-col>
                      <v-col cols="4">
                        Gesamtpreis: <br />
                        {{ endPreis }}
                      </v-col>
                    </v-row>
                    <div v-if="moneyPool.use">
                      <v-card>
                        <v-card-title>Preis Gesamt {{ endPreisAlleZusammen }} </v-card-title>
                        <v-card-text>
                          <v-select
                            label="Wer bezahlt?"
                            multiple
                            :items="alleSpieler"
                            item-text="name"
                            return-object
                            v-model="moneyPool.moneyPoolSpielerAuswahl"
                            chips
                            deletable-chips
                            clearable
                            @change="updateMoneyPoolSpielerListe"
                          ></v-select>
                          <div v-for="(spieler, index) in moneyPool.moneyPoolSpieler" :key="index">
                            {{ spieler.name }}
                            <PreisEingabe
                              v-model="moneyPool.moneyPoolSpieler[index].geld"
                              :key="spieler.name"
                              v-on:input="moneyPoolHandler(index, spieler.name, $event)"
                            />
                          </div>
                          {{ moneyPool.moneyPoolSpieler }}
                        </v-card-text>
                        <v-card-actions>
                          <v-chip>Zusammen: {{ moneyPool.zusammenGerechnet }} </v-chip>
                          <v-spacer></v-spacer>
                          <v-chip
                            label
                            flat
                            :color="moneyPool.zusammenGerechnet == endPreisAlleZusammen ? 'success' : 'error'"
                            >{{
                              moneyPool.zusammenGerechnet == endPreisAlleZusammen ? "Ausführbar" : "Nicht Ausführbar"
                            }}</v-chip
                          >
                        </v-card-actions>
                      </v-card>
                    </div>
                  </v-card-text>
                  <v-card-actions v-if="verkaufen.element != undefined">
                    <v-spacer></v-spacer>
                    <v-btn @click="verkaufenClick()">Verkaufen</v-btn>
                  </v-card-actions>
                </v-card>

                <!-- <v-card v-else>
                  <v-card-subtitle>
                    <v-radio-group v-model="verkaufenNeu.kategorie" row>
                      <v-radio label="Waffe" value="waffen"></v-radio>
                      <v-radio label="Rüstung" value="ruestungen"></v-radio>
                      <v-radio label="Sonstiges" value="sonstiges"></v-radio>
                    </v-radio-group>
                  </v-card-subtitle>
                  <v-card-text cols="9" v-if="verkaufenNeu.kategorie == 'waffen'">
                    <v-text-field v-model="verkaufenNeu.waffeNeu.name" label="Name" outlined></v-text-field>
                    <v-select v-model="verkaufenNeu.waffeNeu.attribut" label="Attribut" outlined multiple :items="attribute"></v-select>
                    <v-select v-model="verkaufenNeu.waffeNeu.gruppe" label="Waffengruppe" outlined multiple :items="waffenGruppen"></v-select>
                    <v-text-field v-model="verkaufenNeu.waffeNeu.iniBonus" label="Ini Bonus" outlined type="number"></v-text-field>
                    <v-text-field v-model="verkaufenNeu.waffeNeu.schaden" label="Schaden" outlined type="number"></v-text-field>
                    <v-text-field v-model="verkaufenNeu.waffeNeu.minST" label="Mind. Stärke" outlined type="number"></v-text-field>
                    <v-text-field v-model="verkaufenNeu.waffeNeu.kg" label="Gewicht (kg)" outlined type="number"></v-text-field>
                    <PreisEingabe v-model="verkaufenNeu.waffeNeu.preis"/>
                  </v-card-text>
                  <v-card-text cols="9" v-else-if="verkaufenNeu.kategorie == 'ruestungen'">
                    <v-text-field v-model="verkaufenNeu.ruestungNeu.name" label="Name" outlined></v-text-field>
                    <v-text-field v-model="verkaufenNeu.ruestungNeu.ruestungsbonus" label="Rüstungsbonus" outlined type="number"></v-text-field>
                    <v-text-field v-model="verkaufenNeu.ruestungNeu.minST" label="Mind. Stärke" outlined type="number"></v-text-field>
                    <v-text-field v-model="verkaufenNeu.ruestungNeu.manoever" label="Manöver" outlined type="number"></v-text-field>
                    <PreisEingabe v-model="verkaufenNeu.ruestungNeu.preis"/>
                  </v-card-text>
                  <v-card-text cols="9" v-else>
                    <v-text-field v-model="verkaufenNeu.sonstigesNeu.name" label="Name" outlined></v-text-field>
                    <v-textarea v-model="verkaufenNeu.sonstigesNeu.beschreibung" label="Beschreibung" outlined auto-grow></v-textarea>
                    <PreisEingabe v-model="verkaufenNeu.sonstigesNeu.preis"/>
                  </v-card-text>
                  <v-card-text >
                    <v-row dense>
                      <v-col cols="4">
                        <v-text-field label="Anzahl" type="number" v-model="verkaufenNeu.anzahl" outlined></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-checkbox v-model="verkaufenNeu.moneyPool" label="Moneypool" disabled></v-checkbox>
                      </v-col>
                      <v-col cols="4">
                        Gesamtpreis: <br>
                        {{endPreis}}
                      </v-col>
                    </v-row>
                    <div v-if="verkaufenNeu.moneyPool">
                      <v-card v-for="(spieler,index) in alleSpieler" :key="index">
                        <v-card-actions>
                          {{spieler.name}}
                          <PreisEingabe v-model="verkaufen.moneyPoolSpieler[spieler.id]" :key="endPreis"/>
                        </v-card-actions>
                      </v-card>
                    </div>
                  </v-card-text>
                  <v-card-actions v-if="verkaufen.element != undefined">
                    <v-spacer></v-spacer>
                    <v-btn>Kaufen</v-btn>
                  </v-card-actions>
                </v-card> -->
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item ref="Kaufen">
            <v-card>
              <v-card-text>
                <v-select
                  v-model="kaufen.spieler"
                  label="Spieler"
                  outlined
                  clearable
                  chips
                  :items="alleSpieler"
                  item-text="name"
                  return-object
                ></v-select>

                <v-select
                  v-model="kaufen.produktAuswahl"
                  label="Produkte"
                  outlined
                  clearable
                  chips
                  :items="produkteSpieler"
                  item-text="name"
                  return-object
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.bereich }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
                <v-card v-if="kaufen.produktAuswahl != undefined">
                  <v-card-title>
                    {{ kaufen.produktAuswahl.name }}
                  </v-card-title>
                  <v-card-text>
                    Bereich: {{ kaufen.produktAuswahl.bereich }} <br />
                    UVP: {{ kaufen.produktAuswahl.preisEmpfohlen }}
                    <PreisEingabe v-model="kaufen.produktAuswahl.preis" :key="kaufen.produktAuswahl" />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="kaufenClick()">Kaufen</v-btn>
                  </v-card-actions>
                </v-card>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item ref="Zwischen Spielern"> Zwischen Spielern </v-tab-item>

          <v-tab-item ref="Geld">
            <v-card>
              <v-card-text>
                <v-select
                  v-model="geld.spieler"
                  label="Spieler"
                  multiple
                  outlined
                  clearable
                  chips
                  :items="alleSpieler"
                  item-text="name"
                  return-object
                ></v-select>
                <v-checkbox v-model="geld.nehmen" label="Geld abziehen?"></v-checkbox>
                <PreisEingabe v-model="geld.preis" :key="geld.preisEingabeRefresh" />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="geldClick()" :disabled="geld.spieler.length == 0">Geld</v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PreisEingabe from "./PreisEingabe";

export default {
  components: {
    PreisEingabe
  },
  data: () => ({
    open: false,
    tab: null,
    attribute: ["ST", "GE"],
    waffenGruppen: [
      "Lange Klingenwaffe",
      "Kurze Klingenwaffe",
      "Äxte",
      "Wurfwaffe",
      "Bogen",
      "Armbrust",
      "Wuchtwaffe",
      "Stichwaffe",
      "Stangenwaffe",
      "Schleuder"
    ],
    verkaufen: {
      alsSonstiges: false,
      neuesElement: false,
      spieler: [],
      kategorie: undefined,
      suche: "",
      anzahl: 1,
      element: undefined,
      speichern: false,
      bereichFuerSpeichern: undefined
    },
    moneyPool: {
      use: false,
      moneyPoolSpielerAuswahl: [],
      moneyPoolSpieler: {},
      zusammenGerechnet: 0
    },
    verkaufenNeu: {
      kategorie: "waffen",
      anzahl: 1,
      moneyPool: false,
      waffeNeu: {
        attribut: [],
        gruppe: [],
        iniBonus: 0,
        minST: "",
        preis: 0,
        schaden: 0,
        kg: 0,
        bereich: "Waffen"
      },
      waffeNeuVorlage: {
        attribut: [],
        gruppe: [],
        iniBonus: 0,
        minST: "",
        preis: 0,
        schaden: 0,
        kg: 0
      },
      ruestungNeu: {
        manoever: 0,
        minST: 0,
        name: "",
        preis: 0,
        ruestungsbonus: 0,
        kg: 0,
        bereich: "Ruestungen"
      },
      ruestungNeuVorlage: {
        manoever: 0,
        minST: 0,
        name: "",
        preis: 0,
        ruestungsbonus: 0,
        kg: 0
      },
      sonstigesNeu: {
        name: "",
        beschreibung: "",
        preis: 0,
        bereich: "Sonstiges"
      },
      sonstigesNeuVorlage: {
        name: "",
        beschreibung: "",
        preis: 0
      }
    },
    kaufen: {
      spieler: undefined,
      produktAuswahl: undefined
    },
    geld: {
      preis: 0,
      nehmen: false,
      spieler: [],
      preisEingabeRefresh: true
    }
  }),
  watch: {},
  computed: {
    produkte() {
      return this.$store.getters["Tabellen/getWirklichAlleProdukte"];
    },
    produkteGefiltert() {
      let ergebnis = [];
      this.produkte.forEach(element => {
        if (this.verkaufen.kategorie) {
          if (element.bereich == this.verkaufen.kategorie) {
            ergebnis.push(JSON.parse(JSON.stringify(element)));
          }
        } else {
          ergebnis.push(JSON.parse(JSON.stringify(element)));
        }
      });
      return ergebnis;
    },
    kategorien() {
      let temp = Object.keys(this.$store.getters["Tabellen/getProdukte"]);
      temp = temp.filter(i => {
        return i != "created_at" && i != "created_by" && i != "id" && i != "updated_at" && i != "updated_by";
      });
      return temp;
    },
    alleSpieler() {
      let temp = Object.values(this.$store.getters["Spieler/getAlleSpieler"]);
      return temp;
    },
    endPreis() {
      if (this.verkaufen.element) {
        return this.verkaufen.element.preis * this.verkaufen.anzahl;
      } else {
        return 0;
      }
    },
    endPreisAlleZusammen() {
      let temp = 0;
      this.verkaufen.spieler.forEach(() => {
        temp += this.endPreis;
      });
      return temp;
    },
    produkteSpieler() {
      let produkte = [];
      let index = 0;
      if (this.kaufen.spieler != undefined) {
        this.kaufen.spieler.sonstigeGegenstaende.forEach(element => {
          element.bereich = "Sonstiges";
          element.bereichZugriff = "sonstigeGegenstaende";
          element.index = index;
          index++;
          produkte.push(element);
        });
        index = 0;
        this.kaufen.spieler.waffen.forEach(element => {
          element.bereich = "Waffen";
          element.bereichZugriff = "waffen";
          element.index = index;
          index++;
          produkte.push(element);
        });
        index = 0;
        this.kaufen.spieler.ruestungen.forEach(element => {
          element.bereich = "Rüstungen";
          element.bereichZugriff = "ruestungen";
          element.index = index;
          index++;
          produkte.push(element);
        });
      }
      return produkte;
    }
  },
  methods: {
    verkaufenClick() {
      if (this.moneyPool.use && this.moneyPool.zusammenGerechnet != this.endPreisAlleZusammen) {
        this.$store.dispatch("setSnackbar", {
          text: "Kosten stimmen nicht mit Bezahlteilen überein",
          color: "error",
          time: 4
        });
      } else if (this.verkaufen.spieler != undefined) {
        let verkaufenDurchfuehren = true;
        let zuWenigGeldSpieler = [];
        let moneyPool = this.moneyPool.use;

        //Schauen ob alle genug Geld haben
        let moneyPoolSpielerIDs = Object.keys(this.moneyPool.moneyPoolSpieler);
        if (moneyPool) {
          moneyPoolSpielerIDs.forEach(id => {
            if (this.$store.getters["Spieler/getSpieler"](id).geld < this.moneyPool.moneyPoolSpieler[id].geld) {
              verkaufenDurchfuehren = false;
              zuWenigGeldSpieler.push(this.$store.getters["Spieler/getSpieler"](id).name);
            }
          });
        } else {
          this.verkaufen.spieler.forEach(spieler => {
            if (spieler.geld < this.endPreis) {
              verkaufenDurchfuehren = false;
              zuWenigGeldSpieler.push(spieler.name);
            }
          });
        }
        // Durchführen des Kaufens wenn bezahlbar
        if (verkaufenDurchfuehren) {
          // Bezahlen
          if (moneyPool) {
            moneyPoolSpielerIDs.forEach(id => {
              let spieler = this.$store.getters["Spieler/getSpieler"](id);
              spieler.geld -= this.moneyPool.moneyPoolSpieler[id].geld;
              this.$store.dispatch("Spieler/set", spieler);
            });
          } else {
            this.verkaufen.spieler.forEach(spieler => {
              spieler.geld -= this.endPreis;
              this.$store.dispatch("Spieler/set", spieler);
            });
          }
          this.verkaufen.spieler.forEach(spieler => {
            if (this.verkaufen.element.bereich == "Waffen" && !this.verkaufen.alsSonstiges) {
              //eslint-disable-next-line
              console.log("Waffenzweig");
              for (let i = 0; i < this.verkaufen.anzahl; i++) {
                spieler = this.waffenUpdaten(spieler, this.verkaufen.element);
              }
            } else if (this.verkaufen.element.bereich == "Ruestungen" && !this.verkaufen.alsSonstiges) {
              //eslint-disable-next-line
              console.log("Ruestungszeig", this, typeof this.ruestungenUpdaten);
              for (let i = 0; i < this.verkaufen.anzahl; i++) {
                spieler = this.ruestungenUpdaten(spieler, this.verkaufen.element);
              }
            } else {
              //eslint-disable-next-line
              console.log("Restzweig");
              if (this.verkaufen.element.bereich != "Dienste") {
                for (let i = 0; i < this.verkaufen.anzahl; i++) {
                  spieler.sonstigeGegenstaende.push(this.verkaufen.element);
                }
              }
            }
            this.$store.dispatch("Spieler/set", spieler);
          });

          if (this.verkaufen.speichern) {
            let produkte = this.$store.getters["Tabellen/getSonstiges"];
            produkte[this.verkaufen.bereichFuerSpeichern].push(this.verkaufen.element);
            this.$store.dispatch("Tabellen/set", produkte);
            this.$store.dispatch("setSnackbar", {
              text: "Erfolgreich gespeichert und verkauft",
              color: "success",
              time: 4
            });
          }
          this.$store.dispatch("setSnackbar", { text: "Erfolgreich verkauft", color: "success", time: 4 });
          this.verkaufen.element = undefined;
          this.verkaufen.spieler = undefined;
          this.verkaufen.kategorie = undefined;
          this.verkaufen.neuesElement = false;
          this.verkaufen.speichern = false;
        } else {
          this.$store.dispatch("setSnackbar", {
            text: zuWenigGeldSpieler + " haben zu wenig Geld",
            color: "error",
            time: 4
          });
        }
      } else if (this.verkaufen.speichern) {
        let produkte = this.$store.getters["Tabellen/getSonstiges"];
        produkte[this.verkaufen.bereichFuerSpeichern].push(this.verkaufen.element);
        this.$store.dispatch("Tabellen/set", produkte);
        this.$store.dispatch("setSnackbar", { text: "Erfolgreich gespeichert", color: "success", time: 4 });
        this.verkaufen.element = undefined;
        this.verkaufen.spieler = undefined;
        this.verkaufen.kategorie = undefined;
        this.verkaufen.speichern = false;
        this.verkaufen.neuesElement = false;
      } else {
        this.$store.dispatch("setSnackbar", { text: "Kein Spieler ausgewählt", color: "error", time: 4 });
      }
    },
    waffenUpdaten(spieler, waffe) {
      let bonus = -10;
      for (let wert in waffe.attribut) {
        if (spieler.boni[waffe.attribut[wert].toLowerCase()] > bonus) {
          bonus = spieler.boni[waffe.attribut[wert].toLowerCase()];
        }
      }
      let fertigkeit = -2;
      for (let wert in waffe.gruppe) {
        for (let zeile in spieler.fertigkeitsraenge) {
          if (zeile.search(waffe.gruppe[wert]) >= 0) {
            if (spieler.fertigkeitsraenge[zeile].rang > fertigkeit) {
              fertigkeit = spieler.fertigkeitsraenge[zeile].rang;
            }
          }
        }
      }
      if (spieler.beruf == "Krieger") {
        if (fertigkeit < 0) {
          fertigkeit = 0;
        }
      }
      if (spieler.beruf == "Waldläufer") {
        if (waffe.gruppe == "Armbrust" || waffe.gruppe == "Bogen") {
          if (fertigkeit < 0) {
            fertigkeit = 0;
          }
        }
      }
      waffe.kb = parseInt(fertigkeit) + parseInt(bonus);
      waffe.ini = parseInt(waffe["iniBonus"]) + parseInt(spieler.boni.ge);
      spieler.waffen.push(waffe);
      return spieler;
    },
    ruestungenUpdaten(spieler, ruestung) {
      let ruestungswert = 5;
      spieler.ruestungen.push(ruestung);
      for (let i in spieler.ruestungen) {
        ruestungswert += parseInt(spieler.ruestungen[i].ruestungsbonus);
      }
      if (spieler.beruf == "Dieb") {
        if (ruestungswert == 5) {
          ruestungswert += spieler.fertigkeitsraenge.Athletik.rang;
        }
      }
      if (spieler.volk == "Gnom" || spieler.volk == "Halbling") {
        ruestungswert += 1;
      }
      spieler.ruestung = ruestungswert;
      return spieler;
    },
    kaufenClick() {
      if (this.kaufen.spieler != undefined) {
        if (this.kaufen.produktAuswahl != undefined) {
          let produkt = this.kaufen.produktAuswahl;
          this.kaufen.spieler[produkt.bereichZugriff].splice(produkt.index, 1);
          this.kaufen.spieler.geld += produkt.preis;
          this.kaufen.produktAuswahl = undefined;
          this.$store.dispatch("Spieler/set", this.kaufen.spieler);
        } else {
          (this.snackText = "Kein Produkt ausgewählt"),
            (this.snackOpen = true),
            setTimeout(() => {
              this.snackOpen = false;
              this.snackText = "";
            }, 4000);
        }
      } else {
        (this.snackText = "Kein Spieler ausgewählt"),
          (this.snackOpen = true),
          setTimeout(() => {
            this.snackOpen = false;
            this.snackText = "";
          }, 4000);
      }
    },
    geldClick() {
      if (this.geld.nehmen) {
        this.geld.spieler.forEach(spieler => {
          spieler.geld -= this.geld.preis;
          this.$store.dispatch("Spieler/set", spieler);
        });
      } else {
        this.geld.spieler.forEach(spieler => {
          spieler.geld += this.geld.preis;
          this.$store.dispatch("Spieler/set", spieler);
        });
      }
      this.geld.spieler = [];
      this.geld.preis = 0;
      this.geld.nehmen = false;
      this.geld.preisEingabeRefresh = !this.geld.preisEingabeRefresh;
    },
    neuVerkaufenEinrichten() {
      if (this.verkaufenNeu.kategorie == "waffen") {
        this.verkaufen.element = this.verkaufenNeu.waffeNeu;
        this.verkaufen.bereichFuerSpeichern = "Waffen";
      } else if (this.verkaufenNeu.kategorie == "ruestungen") {
        this.verkaufen.element = this.verkaufenNeu.ruestungNeu;
        this.verkaufen.bereichFuerSpeichern = "Ruestungen";
      } else {
        this.verkaufen.element = this.verkaufenNeu.sonstigesNeu;
      }
    },
    updateMoneyPoolSpielerListe() {
      console.log("Update Liste in Marktplatz");
      this.moneyPool.moneyPoolSpieler = {};
      this.moneyPool.moneyPoolSpielerAuswahl.forEach(element => {
        this.moneyPool.moneyPoolSpieler[element.id] = {
          name: element.name,
          geld: 0
        };
      });
    },
    moneyPoolHandler(id, name, event) {
      console.log("MoneyPool", this.moneyPool.moneyPoolSpieler[id]);
      console.log("Preiseingabe Änderung", name, event);
      console.log(this.moneyPool.moneyPoolSpieler);
      this.moneyPoolZusammenrechnen();
    },
    moneyPoolZusammenrechnen() {
      let temp = 0;
      for (let [, obj] of Object.entries(this.moneyPool.moneyPoolSpieler)) {
        //eslint-disable-next-line
        console.log(obj);
        temp += obj.geld;
      }
      this.moneyPool.zusammenGerechnet = temp;
    }
  }
};
</script>

import Vue from 'vue'
import Router from 'vue-router'
// import Home from '../views/Home.vue'
import Spielleiter from '../views/Spielleiter.vue'
import Spieler from '../views/Spieler.vue'
import SpielleiterSpieler from '../views/SpielleiterSpieler.vue'
import Data from "../views/Data.vue"
import {auth} from "../store/config/firebase"
import store from "../store/index.js"
//import { compile } from 'vue-template-compiler'

Vue.use(Router)
/*eslint-disable */
const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: Home
    // },
    {
      path: '/spielleiter',
      name: 'Spielleiter',
      component: Spielleiter
    },
    {
      path: '/spieler/:id',
      name: "Spieler",
      component: Spieler
    },
    {
      path: '/data/:id',
      name: "Data",
      component: Data
    },
    {
      path: '/spielleiter/:id',
      name: 'Spielleiter_Spieler',
      component: SpielleiterSpieler
    }
  ]
})

router.beforeEach(function(to,from,next) {
  // console.log("From:",from.name," To:",to.name)
  // console.log(store.state.spielleiter)
  if (auth.currentUser){
    if (to.name == null){
      if (store.state.spielleiter){
        next({name: "Spielleiter"})
      } else {
        let name = auth.currentUser.email.split("@")[0]
        let path = "/spieler/" + name
        next({name: "Spieler"})
      }
    } else if (to.name == "Spielleiter"){
      if (store.state.spielleiter){
        // console.log("Email Verified",auth.currentUser.emailVerified)
        if (auth.currentUser.emailVerified){
          next()
        } else {
        next({name: "Home"});
        }
      } else {
        let name = auth.currentUser.email.split("@")[0]
        let path = "/spieler/" + name
        next({ name: "Spieler" })
      }
    } else if (to.name == "Spielleiter_Spieler") {
            if (store.state.spielleiter){
        // console.log("Email Verified",auth.currentUser.emailVerified)
        if (auth.currentUser.emailVerified){
          next()
        } else {
        next({name: "Home"});
        }
      } else {
        let name = auth.currentUser.email.split("@")[0]
        let path = "/spieler/" + name
        next({ name: "Spieler" })
      }
    } else if (to.name == "Spieler"){
      if (store.state.spielleiter){
        next({name: "Spielleiter"});
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    if (from.name == null){
      next()
    }else if (to.name != "Home"){
      next({name: "Home"})
    }else{
      next()
    }
  }
})


export default router
<template>
  <v-card>
    <v-card-title>Neuer Gegner</v-card-title>
    <v-card-text>
      <v-form ref="FormGegner">
        <v-row dense>
          <v-col cols="5">
            <v-text-field v-model="gegnerNeu.name" outlined label="Name" :rules="regeln.nichtLeer"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="gegnerNeu.volk" outlined label="Volk"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="gegnerNeu.sg" outlined label="SG" :rules="regeln.zahl" type="number"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="gegnerNeu.tp"
              label="Trefferpunkte"
              outlined
              :rules="regeln.zahl"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="gegnerNeu.ini" label="Initiative" outlined :rules="regeln.zahl" type="number">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="gegnerNeu.mp"
              label="Magiepunkte"
              outlined
              :rules="regeln.zahl"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="gegnerNeu.ruestung"
              label="Rüstung"
              outlined
              :rules="regeln.zahl"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
            <v-card>
              <v-card-title>Waffen</v-card-title>
              <v-card-text>
                <v-row class="ml-4 pr-4">
                  <v-col cols="4">
                    Name
                  </v-col>
                  <v-col class="pl-1" cols="3">
                    Kampfbonus
                  </v-col>
                  <v-col class="pl-1" cols="3">
                    Schaden
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-virtual-scroll height="150" :item-height="30" :items="gegnerNeu.waffen">
                  <template width="200" v-slot:default="{ item, index }">
                    <v-row class=" ma-0 ml-4">
                      <v-col cols="4" class="ma-0">
                        <v-text-field v-model="item.name" dense></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field v-model="item.kb" dense type="number"></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field v-model="item.schaden" dense type="number"></v-text-field>
                      </v-col>
                      <v-col cols="2" class="ma-0">
                        <v-btn icon @click="gegnerNeu.waffen.splice(index, 1)" class="ma-0" small>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-virtual-scroll>
                <v-divider></v-divider>
                <v-btn class="my-2 ml-7" @click="waffeHinzufuegen()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title>Infos</v-card-title>

              <v-card-text>
                <v-row class="ml-4 pr-4">
                  <v-col cols="3">
                    Name
                  </v-col>
                  <v-col class="pl-1" cols="7">
                    Beschreibung
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-virtual-scroll height="150" :item-height="30" :items="infos">
                  <template width="200" v-slot:default="{ item, index }">
                    <v-row class=" ma-0 ml-4">
                      <v-col cols="3" class="ma-0">
                        <v-text-field v-model="item.name" dense></v-text-field>
                      </v-col>
                      <v-col cols="7">
                        <v-textarea v-model="item.info" rows="1" height="14"></v-textarea>
                      </v-col>
                      <v-col cols="2" class="ma-0">
                        <v-btn icon class="ma-0" small @click="infos.splice(index, 1)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-virtual-scroll>
                <v-divider></v-divider>
                <v-btn class="my-2 ml-7" @click="infos.push(JSON.parse(JSON.stringify(infoNeu)))">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="GegnerHinzufuegen()">Hinzufügen</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    gegnerNeu: {
      infos: {},
      waffen: [],
      sg: "",
      name: "",
      tp: "",
      mp: "",
      ruestung: "",
      ini: "",
      volk: ""
    },
    gegnerNeuVorlage: {
      infos: {},
      waffen: [],
      sg: "",
      name: "",
      tp: "",
      mp: "",
      ruestung: "",
      ini: "",
      volk: ""
    },
    regeln: {
      nichtLeer: [val => val.length > 0 || "Darf nicht leer sein"],
      zahl: [val => !isNaN(parseInt(val)) || "Muss eine ganze Zahl sein"]
    },
    waffeNeu: {
      name: "",
      kb: 0,
      schaden: 0
    },
    infoNeu: {
      name: "",
      info: ""
    },
    infos: []
  }),
  methods: {
    waffeHinzufuegen() {
      this.gegnerNeu.waffen.push(this.waffeNeu);
      this.waffeNeu = {
        name: "",
        kb: 0,
        schaden: 0
      };
    },
    GegnerHinzufuegen() {
      if (this.$refs.FormGegner.validate()) {
        //console.log("Validate")
        let temp = this.$store.getters["Tabellen/getAlle"];
        this.infos.forEach(element => {
          this.gegnerNeu.infos[element.name] = element.info;
        });
        temp.Gegner.gegner.push(this.gegnerNeu);
        this.gegnerNeu = this.gegnerNeuVorlage;
        this.infos = [];
        this.$emit("schliessen");
        this.$store.dispatch("Tabellen/set", temp.Gegner);
      }
      // console.log("Sonstigs ")
    }
  }
};
</script>

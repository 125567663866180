const spieler = {
  firestorePath: 'Spiele/{spielID}/Spieler',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'Spieler',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getAlleSpieler: state => {
      return state.data
    },
    getAlleSpielerNamen: state => {
      let temp = []
      for (let key in state.data){
        temp.push(state.data[key].name)
      }
      return temp
    },
    getSpieler: state => name => {
      // console.log("Spieler ",name)
      return state.data[name]
    },
    getSpielerByName: state => name => {
      let keys = Object.values(state.data)
      return keys.find(elem => elem.name == name)
    },
    getSpielerBoni: state => (spieler, attribut) => {
      if (spieler != undefined && attribut != undefined) {
        return state.data[spieler].boni[attribut]
      } else {
        return 0
      }
    },
    getSpielerFertigkeit: state => (spieler,fertigkeit) => {
      if(spieler != undefined && fertigkeit != undefined){
        return state.data[spieler].fertigkeitsraenge[fertigkeit].rang
      }else{
        return 0
      }
    },
    getManoeverBonus: state => spieler =>{
      if (spieler != undefined){
        return state.data[spieler].manoeverBonus
      }else{
        return 0
      }
    },
    getAlleSpielerLeben: state => {
      let liste = []
      for (let key in state.data){
        liste.push(state.data[key].trefferpunkteAkt)
      }
      return liste
    }
  },
  mutations: {
    setManoeverBonus(state,{spieler,value}){
      // console.log("setManoeverbonus: ", spieler, value)
      if(value != undefined && spieler != undefined){
        state.data[spieler].manoeverBonus = parseInt(value)
      }
    }
  },
  actions: {},
}

export default spieler
const spieler = {
  firestorePath: 'Spiele/{spielID}/Spieler/{userID}',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'SpielerEinzeln',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getSpieler: state => {
      return state.data
    },
    getSpielerBoni: state => (attribut) => {
      if (spieler != undefined && attribut != undefined) {
        return state.boni[attribut]
      } else {
        return 0
      }
    },
    getSpielerFertigkeit: state => (fertigkeit) => {
      if (spieler != undefined && fertigkeit != undefined) {
        return state.fertigkeitsraenge[fertigkeit].rang
      } else {
        return 0
      }
    },
  },
  mutations: {
  },
  actions: {},
}

export default spieler
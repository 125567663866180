<template>
  <v-container>
    <v-card>
      <v-card-title>{{ name }}</v-card-title>
      <v-card-text>
        <v-expansion-panels v-if="name == 'Produkte'">
          <v-expansion-panel v-for="(item, index) in sonstiges" :key="index">
            <v-expansion-panel-header>{{ item }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table :headers="headers(daten[item])" :items="daten[item]">
                <template v-slot:item.preis="{ item }">
                  {{ costs(item.preis) }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels v-else-if="name == 'Spruchlisten'">
          <v-expansion-panel v-for="(data, index) in daten" :key="index">
            <v-expansion-panel-header>{{ data.name }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headerSpruchlistenTabelle"
                :items="data.zauber"
                :expanded.sync="zauberExpanded"
                item-key="name"
                show-expand
              >
                <template v-slot:item.beschreibung="{ item }">
                  {{ einsetzen(item, item.beschreibung) }}
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-data-table
                      :headers="[
                        { text: 'Name', value: 'name' },
                        { text: 'Verbesserung', value: 'verbesserung' },
                        { text: 'Kosten', value: 'kosten' }
                      ]"
                      :items="effektTabelle(item)"
                      hide-default-footer
                      dense
                    >
                      <template v-slot:item.name="{ item }">
                        <v-icon small>{{ item.icon }} </v-icon>
                        {{ item.name }}
                      </template>
                      <!-- <template v-slot:item.name = "itemDings">
                        <v-icon small>{{icon(itemDings.item)}}</v-icon>
                        {{item[itemDings.item].einheit}}
                      </template>
                      <template v-slot:item.verbesserung = "itemDings">
                        + {{item[itemDings.item].extraWirkung}} {{item[itemDings.item].einheit}}
                      </template>
                      <template v-slot:item.kosten = "itemDings">
                        {{item[itemDings.item].mp}} MP
                      </template> -->
                    </v-data-table>
                  </td>
                </template>

                <template v-slot:item.gezielt="{ item }">
                  <v-checkbox readonly v-model="item.gezielt"></v-checkbox>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels v-else-if="name == 'Völker' || name == 'Berufe'">
          <v-expansion-panel v-for="(item, index) in daten" :key="index">
            <v-expansion-panel-header class="grey lighten-2">{{ item.name }} </v-expansion-panel-header>
            <!-- <v-expansion-panel-content>{{ item }}</v-expansion-panel-content> -->
            <v-expansion-panel-content>
              <v-card>
                <v-img :src="bilderlinks[item.name]" class="white--text align-end">
                  <v-card-title class="red darken-3">{{ item.name }}</v-card-title>
                </v-img>
                <v-card-text id="article" v-html="item.beschreibung"> </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-data-table
          v-else-if="name == 'Waffen' || name == 'Rüstungen'"
          :headers="headers(daten)"
          :items="daten"
          :items-per-page="50"
        >
          <template v-slot:item.preis="{ item }">
            {{ costs(item.preis) }}
          </template>
        </v-data-table>

        <div v-else>
          <v-row dense>
            <v-col cols="5">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Name"
                    v-model="FilterName"
                    outlined
                    placeholder="Soldat"
                    @change="activeGegner = undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Volk"
                    clearable
                    v-model="FilterVolk"
                    outlined
                    placeholder="Mensch"
                    @change="activeGegner = undefined"
                    :items="GegnerVoelker"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        type="number"
                        label="SG von "
                        v-model="FilterSGMin"
                        outlined
                        @change="activeGegner = undefined"
                        placeholder="0"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        type="number"
                        label="bis"
                        v-model="FilterSGMax"
                        outlined
                        @change="activeGegner = undefined"
                        placeholder="20"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-list two-line>
                <v-list-item-group v-model="activeGegner">
                  <v-list-item v-for="(item, index) in GegnerGefiltert" :key="index">
                    <v-list-item-icon>
                      <v-avatar color="red darken-3">{{ item.sg }}</v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"> </v-list-item-title>
                      <v-list-item-subtitle v-text="item.volk"> </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col>
              <v-card outlined v-if="activeGegner != undefined">
                <v-card-title>
                  {{ Gegner.name }}
                  <v-spacer></v-spacer>
                  <v-avatar color="red darken-3">{{ Gegner.sg }}</v-avatar>
                  <v-btn fab @click="deleteGegner(Gegner)"><v-icon>mdi-delete</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>{{ Gegner.volk }}</v-card-subtitle>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="3">
                      <v-card>
                        <v-card-title>
                          {{ Gegner.tp }}
                        </v-card-title>
                        <v-card-subtitle> Trefferpunkte </v-card-subtitle>
                      </v-card>
                    </v-col>
                    <v-col cols="3">
                      <v-card>
                        <v-card-title>
                          {{ Gegner.mp }}
                        </v-card-title>
                        <v-card-subtitle> Magiepunkte </v-card-subtitle>
                      </v-card>
                    </v-col>
                    <v-col cols="3">
                      <v-card>
                        <v-card-title>
                          {{ Gegner.ruestung }}
                        </v-card-title>
                        <v-card-subtitle> Rüstung </v-card-subtitle>
                      </v-card>
                    </v-col>
                    <v-col cols="3">
                      <v-card>
                        <v-card-title>
                          {{ Gegner.ini }}
                        </v-card-title>
                        <v-card-subtitle> Initiative </v-card-subtitle>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card>
                        <v-card-title>Waffen</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-card v-for="(waffe, index) in Gegner.waffen" :key="index" outlined rounded max-width="200">
                            <v-card-title>{{ waffe.name }}</v-card-title>
                            <v-card-subtitle>
                              <v-chip> {{ waffe.kb }} KB </v-chip>
                              <v-chip> {{ waffe.schaden }} Schaden </v-chip>
                            </v-card-subtitle>
                          </v-card>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card>
                        <v-card-title>Infos</v-card-title>
                        <v-card-text>
                          <v-card v-for="(info, index) in Gegner.infos" :key="index" outlined rounded>
                            <v-card-title @click="openInfo == index ? (openInfo = '') : (openInfo = index)">{{
                              index
                            }}</v-card-title>
                            <v-card-text v-if="index == openInfo">
                              {{ info }}
                            </v-card-text>
                          </v-card>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions v-if="name == 'Gegner'">
        <v-spacer></v-spacer>
        <v-btn large fab @click="GegnerDialog = true">
          <v-icon x-large>mdi-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-dialog v-model="GegnerDialog">
        <GegnerErstellen v-on:schliessen="GegnerDialog = false" />
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import GegnerErstellen from "../components/GegnerErstellen";
import { storage } from "../store/config/firebase";
export default {
  components: {
    GegnerErstellen
  },
  data: () => ({
    GegnerDialog: false,
    activeGegner: undefined,
    zauberExpanded: [],
    selected: [],
    namensListe: {
      Voelker: "Völker",
      Ruestungen: "Rüstungen",
      Berufe: "Berufe",
      Spruchlisten: "Spruchlisten",
      Waffen: "Waffen",
      Gegner: "Gegner",
      Sonstiges: "Produkte"
    },
    headerSpruchlistenTabelle: [
      { text: "Rang", value: "rang" },
      { text: "Name", value: "name" },
      { text: "Beschreibung", value: "beschreibung" },
      { text: "Reichweite", value: "reichweite" },
      { text: "Gezielt", value: "gezielt" }
    ],
    FilterName: "",
    FilterVolk: "",
    FilterSGMin: 0,
    FilterSGMax: 100,
    openInfo: "",
    bilderlinks: {
      Mensch: "",
      Elf: "",
      Zwerg: "",
      Halbling: "",
      Gnom: "",
      Barde: "",
      Dieb: "",
      Krieger: "",
      Priester: "",
      Schamane: "",
      Waldläufer: "",
      Zauberer: ""
    }
  }),
  created() {
    this.bildlink();
  },
  computed: {
    daten() {
      // let string = "Tabellen/get"  + window.location.href.split("/").reverse()[0]
      let temp = this.$store.getters["Tabellen/get" + this.urlName];
      // if (this.urlName == "Gegner"){
      //   let liste = Object.keys(temp)
      //   liste = liste.slice(0,liste.length-1)
      //   let ergebnis = []
      //   for (let i in liste){
      //     ergebnis.push(temp[liste[i]])
      //   }
      //   this.headersGegner(ergebnis)
      //   temp = ergebnis
      // }
      return temp;
    },
    filteredDaten() {
      let temp = this.daten.filter(val => {
        console.log(val);
        if (val != "id" && val != "updated_at" && val != "updated_by") {
          return true;
        } else {
          return false;
        }
      });
      return temp;
    },
    GegnerGefiltert() {
      let temp = [];
      if (this.urlName == "Gegner") {
        this.daten.forEach(element => {
          if (
            element.name.search(this.FilterName) > -1 &&
            element.volk.search(this.FilterVolk) > -1 &&
            element.sg > this.FilterSGMin &&
            element.sg < this.FilterSGMax
          ) {
            temp.push(element);
          }
        });
      }
      return temp;
    },
    GegnerVoelker() {
      let temp = [];
      this.daten.forEach(element => {
        temp.push(element.volk);
      });
      return temp;
    },
    Gegner() {
      return this.GegnerGefiltert[this.activeGegner];
    },
    name() {
      return this.namensListe[this.urlName];
    },
    urlName() {
      return this.$route.params.id;
    },
    sonstiges() {
      let temp = Object.keys(this.daten);
      return temp.filter(elem => {
        return elem != "id" && elem != "Waffen" && elem != "Ruestungen" && elem != "updated_at" && elem != "updated_by";
      });
    },
    waehrung() {
      let data = this.$store.getters["Geschichte/getAlle"];
      let aktiv = data.aktiveWaehrung;
      let waehrung = data.waehrungen[aktiv];
      waehrung.sort((a, b) => (a.kurs < b.kurs ? 1 : -1));
      return waehrung;
    }
  },
  methods: {
    bildlink() {
      let storeageRef = storage.ref();
      let imageRef = storeageRef.child("Bilddateien/Menschen.jpg");
      let self = this;
      imageRef
        .getDownloadURL()
        .then(function(url) {
          // Insert url into an <img> tag to "download"
          //eslint-disable-next-line
          // console.log("Dieser Fall", url);
          self.bilderlinks.Mensch = url;
        })
        .catch(function(error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          //eslint-disable-next-line
          console.error(error);
          self.bilderlinks.Mensch = "";
        });
    },
    effektTabelle(item) {
      let liste = this.werteListe(item.beschreibung);
      let ergebnis = [];
      for (let i in liste) {
        ergebnis.push({
          icon: this.icon(liste[i]),
          name: item[liste[i]].einheit,
          verbesserung: item[liste[i]].extraWirkung,
          kosten: item[liste[i]].mp
        });
      }
      return ergebnis;
    },
    einsetzen(item, text) {
      let liste = this.werteListe(text);
      for (let i in liste) {
        // console.log(liste, liste[i], item);
        text = text.replace("{{" + liste[i] + "}}", item[liste[i]].startWirkung);
      }
      return text;
    },
    werteListe(text) {
      let ergebnis = [];
      let liste = text.split("{{");
      let laenge = liste.length;
      for (let i = 1; i < laenge; i++) {
        ergebnis.push(liste[i].split("}}")[0]);
      }
      return ergebnis;
    },
    icon(name) {
      if (name == "effekt") {
        return "mdi-lightning-bolt";
      } else if (name == "anzahl") {
        return "mdi-account";
      } else {
        return "mdi-";
      }
    },
    headers() {
      let ergebnis = undefined;
      if (this.name == "Rüstungen") {
        ergebnis = [
          { text: "Name", value: "name" },
          { text: "Mindeststärke", value: "minST" },
          { text: "Rüstungsbonus", value: "ruestungsbonus" },
          { text: "Gewicht (kg)", value: "kg" },
          { text: "Preis", value: "preis" }
        ];
      } else if (this.name == "Waffen") {
        ergebnis = [
          { text: "Name", value: "name" },
          { text: "Mindeststärke", value: "minST" },
          { text: "Schaden", value: "schaden" },
          { text: "Waffengruppe", value: "gruppe" },
          { text: "Bonus Initiative", value: "iniBonus" },
          { text: "Attribut", value: "attribut" },
          { text: "Gewicht (kg)", value: "kg" },
          { text: "Preis", value: "preis" }
        ];
      } else {
        ergebnis = [
          { text: "Name", value: "name" },
          { text: "Beschreibung", value: "beschreibung" },
          { text: "Preis", value: "preis" }
        ];
      }
      return ergebnis;
    },
    headersGegner(arr) {
      let temp = Object.keys(arr[0]);
      temp = temp.filter(val => {
        return val != "infos" && val != "waffen";
      });
      temp.sort((a, b) => {
        if (a == "name") {
          return -1;
        } else if (b == "name") {
          return 1;
        } else {
          return 0;
        }
      });
      let dataset = {
        name: "Name",
        ruestung: "Rüstung",
        ini: "Ini",
        tp: "Trefferpunkte",
        mp: "Magiepunkte",
        volk: "Volk"
      };
      let ergebnis = [];
      for (let i in temp) {
        ergebnis.push({
          text: dataset[temp[i]],
          value: temp[i]
        });
      }
      return ergebnis;
    },
    costs(number) {
      let result = "";
      let waehrungAnders = JSON.parse(JSON.stringify(this.waehrung));
      waehrungAnders.sort((a, b) => (a.kurs > b.kurs ? 1 : -1));
      waehrungAnders.forEach(element => {
        if (parseInt(number) % element.kurs == 0) {
          console.log(number / element.kurs, element.name);
          let temp = parseInt(number / element.kurs).toString() + " " + element.name;
          console.log(temp);
          result = temp;
        }
      });
      return result;
    },
    deleteGegner(gegner) {
      let result = undefined;
      this.daten.forEach((element, index) => {
        if (element == gegner) {
          result = index;
        }
      });
      this.daten.splice(result, 1);
      let temp = this.$store.getters["Tabellen/getAlle"];
      temp.Gegner.gegner = this.daten;
      this.$store.dispatch("Tabellen/set", temp.Gegner);
      this.activeGegner = undefined;
    }
  }
};
</script>

<style>
#article {
  margin: 0 auto;
  width: 95%;
  column-count: 3;
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-gap: 30px;
  -moz-column-gap: 30px;
  -webkit-column-gap: 30px;
}
</style>

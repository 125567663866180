<template>
  <v-container v-if="false">
    <v-toolbar :height="height" v-if="$vuetify.breakpoint.lgAndUp">
        <v-card :height="height" class="elevation-0"><v-img src="@/assets/aborea.png" alt="Aborea" :max-height="height" :width="height*2.2"></v-img></v-card>

        <v-menu offset-y open-on-hover bottom >
          <template v-slot:activator="{on}">
            <v-card class="mt-10 ml-10"><v-btn v-on="on" v-if="!disableMonster">Monster</v-btn></v-card><!-- Hier war ein @click="test" Sollte irgendwas mit Logout sein -->
          </template>
          <v-card min-width="450px">
            <v-container>
              <v-row>
                <v-col>
                  <v-card elevation="0">
                    <v-card-title>Nach Völkern</v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item>Fliegend</v-list-item>
                        <v-list-item>Laufend</v-list-item>
                        <v-list-item>Wasser</v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col>
                  <v-card elevation="0">
                    <v-list><!-- Hier müssen noch die Verlinkungen zu den einzelnen Seiten hin, sobald es sie gibt -->
                      <v-list-item>Alle Monster</v-list-item> 
                      <v-list-item>Neue Monster</v-list-item>
                      <v-list-item>Erstelle Monster</v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-menu>

        <v-menu offset-y open-on-hover bottom>
          <template v-slot:activator="{on}">
            <v-card class="mt-10 ml-10"><v-btn v-on="on">Völker und Berufe</v-btn></v-card><!-- Hier war ein @click="test" Sollte irgendwas mit Logout sein -->
          </template>
          <v-card min-width="450px">
            <v-container>
              <v-row>
                <v-col>
                  <v-card elevation="0">
                    <v-card-title>Völker</v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in voelker"
                          :key="index"
                        >
                          <v-list-item-title>{{ item.name }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col>
                  <v-card elevation="0">
                    <v-card-title>Berufe</v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in alleDavon.sYiB8uiMHHuaqM18YrHY.Berufe"
                          :key="index"
                        >
                          <v-list-item-title>{{ item.name }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-menu>


        <v-menu offset-y open-on-hover bottom>
          <template v-slot:activator="{on}">
            <v-card class="mt-10 ml-10"><v-btn v-on="on">Spruchlisten</v-btn></v-card><!-- Hier war ein @click="test" Sollte irgendwas mit Logout sein -->
          </template>
          <v-card>
            <v-container>
              <v-list>
                <v-list-item
                  v-for="(item, index) in alleDavon.sYiB8uiMHHuaqM18YrHY.Spruchlisten"
                  :key="index"
                >
                  <v-list-item-title>{{ item.name }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                </v-list-item>
              </v-list>
            </v-container>
          </v-card>
        </v-menu>

        <v-menu offset-y open-on-hover bottom>
          <template v-slot:activator="{on}">
            <v-card class="mt-10 ml-10"><v-btn v-on="on">Waffen</v-btn></v-card> <!-- Hier war ein @click="test" Sollte irgendwas mit Logout sein -->
          </template>
          <v-card min-width="600px">
            <v-container>
              <v-row>
                <v-col>
                  <v-card elevation="0">
                    <v-card-title>Nach Waffengruppe</v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item
                          v-for="item in alleDavon.Waffen"
                          :key="item.waffengruppe"
                        >
                          <v-list-item-title>{{ item.waffengruppe }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                  
                </v-col>
                <v-divider vertical></v-divider>
                <v-col>
                  <v-card elevation="0">
                    <v-card-text>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in alleDavon.Waffen"
                          :key="index"
                          >
                          <v-list-item-title>{{ item.name }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-menu>

        <v-menu offset-y open-on-hover bottom>
          <template v-slot:activator="{on}">
            <v-card class="mt-10 ml-10"><v-btn v-on="on">Rüstungen</v-btn></v-card> <!-- Hier war ein @click="test" Sollte irgendwas mit Logout sein -->
          </template>
          <v-card min-width="600px">
            <v-container>
              <v-row>
                <v-col>
                  <v-card elevation="0">
                    <v-card-title>Nach Rüstungsgruppe</v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item
                          v-for="item in alleDavon.sYiB8uiMHHuaqM18YrHY.Ruestungen"
                          :key="item.ruestungsgruppe"
                        >
                          <v-list-item-title>{{ item.ruestungsgruppe }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                  
                </v-col>
                <v-divider vertical></v-divider>
                <v-col>
                  <v-card elevation="0">
                    <v-card-text>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in alleDavon.sYiB8uiMHHuaqM18YrHY.Ruestungen"
                          :key="index"
                          >
                          <v-list-item-title>{{ item.name }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-menu>

        <v-menu offset-y open-on-hover bottom>
          <template v-slot:activator="{on}">
            <v-card class="mt-10 ml-10"><v-btn v-on="on">Sonstiges</v-btn></v-card>
          </template>
        </v-menu>
        <v-spacer></v-spacer>

        <v-btn icon top> <!-- Hier war ein @click="test" Sollte irgendwas mit Logout sein -->
          <v-icon>mdi-logout</v-icon>
        </v-btn>
    </v-toolbar>

    <v-toolbar v-if="$vuetify.breakpoint.mdAndDown">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-card :height="50" class="elevation-0"><v-img src="@/assets/aborea.png" alt="Aborea" :max-height="height" :width="50*2.2"></v-img></v-card>
      <v-spacer></v-spacer>
      <v-btn icon top @click="loginDialog = !loginDialog">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer app v-model="drawer" v-if="$vuetify.breakpoint.mdAndDown" temporary>
      <v-card :height="height" class="elevation-0"><v-img src="@/assets/aborea.png" alt="Aborea" :height="100" :width="height*2.2"></v-img></v-card>

      <v-menu offset-y offset-x open-on-hover bottom>
        <template v-slot:activator="{on}">
          <v-card class="mt-10 ml-10"><v-btn v-on="on" v-if="!disableMonster">Gegner</v-btn></v-card>
        </template>
        <v-card min-width="450px" :max-width="$vuetify.breakpoint.width" :max-height="$vuetify.breakpoint.height/2" class="overflow-y-auto">
          <v-container fluid>
            <v-row>
              <v-col class="col-sm-6 col-12">
                <v-card elevation="0">
                  <v-card-title>Nach Völkern</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item>Fliegend</v-list-item>
                      <v-list-item>Laufend</v-list-item>
                      <v-list-item>Wasser</v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <v-card elevation="0">
                  <v-list><!-- Hier müssen noch die Verlinkungen zu den einzelnen Seiten hin, sobald es sie gibt -->
                    <v-list-item>Alle Monster</v-list-item> 
                    <v-list-item>Neue Monster</v-list-item>
                    <v-list-item>Erstelle Monster</v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-menu>

      <v-menu offset-y offset-x open-on-hover bottom>
        <template v-slot:activator="{on}">
          <v-card class="mt-10 ml-10"><v-btn v-on="on">Völker und Berufe</v-btn></v-card>
        </template>
        <v-card min-width="450px" :max-width="$vuetify.breakpoint.width" :max-height="$vuetify.breakpoint.height/2" class="overflow-y-auto">
          <v-container>
            <v-row>
              <v-col  class="col-sm-6 col-12">
                <v-card elevation="0">
                  <v-card-title>Völker</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in voelker"
                        :key="index"
                      >
                        <v-list-item-title>{{ item.name }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
                <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <v-card elevation="0">
                  <v-card-title>Berufe</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in alleDavon.sYiB8uiMHHuaqM18YrHY.Berufe"
                        :key="index"
                      >
                        <v-list-item-title>{{ item.name }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-menu>

      <v-menu offset-y offset-x open-on-hover bottom>
        <template v-slot:activator="{on}">
          <v-card class="mt-10 ml-10"><v-btn v-on="on">Spruchlisten</v-btn></v-card>
        </template>
        <v-card :max-height="$vuetify.breakpoint.height/2" class="overflow-y-auto">
          <v-container>
            <v-list>
              <v-list-item
                v-for="(item, index) in alleDavon.sYiB8uiMHHuaqM18YrHY.Spruchlisten"
                :key="index"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
              </v-list-item>
            </v-list>
          </v-container>
        </v-card>
      </v-menu>

      <v-menu offset-y offset-x open-on-hover bottom>
        <template v-slot:activator="{on}">
          <v-card class="mt-10 ml-10"><v-btn v-on="on">Waffen</v-btn></v-card>
        </template>
        <v-card min-width="600px" :max-width="$vuetify.breakpoint.width" :max-height="$vuetify.breakpoint.height/2" class="overflow-y-auto">
          <v-container>
            <v-row>
              <v-col class="col-sm-6 col-12">
                <v-card elevation="0">
                  <v-card-title>Nach Waffengruppe</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item
                        v-for="item in alleDavon.Waffen"
                        :key="item.waffengruppe"
                      >
                        <v-list-item-title>{{ item.waffengruppe }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
                
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <v-card elevation="0">
                  <v-card-text>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in alleDavon.Waffen"
                        :key="index"
                        >
                        <v-list-item-title>{{ item.name }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-menu>

      <v-menu offset-y offset-x open-on-hover bottom>
        <template v-slot:activator="{on}">
          <v-card class="mt-10 ml-10"><v-btn v-on="on">Rüstungen</v-btn></v-card>
        </template>
        <v-card min-width="600px"  :max-width="$vuetify.breakpoint.width" :max-height="$vuetify.breakpoint.height/2" class="overflow-y-auto">
          <v-container>
            <v-row>
              <v-col class="col-sm-6 col-12">
                <v-card elevation="0">
                  <v-card-title>Nach Rüstungsgruppe</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item
                        v-for="item in alleDavon.sYiB8uiMHHuaqM18YrHY.Ruestungen"
                        :key="item.ruestungsgruppe"
                      >
                        <v-list-item-title>{{ item.ruestungsgruppe }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
                
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <v-card elevation="0">
                  <v-card-text>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in alleDavon.sYiB8uiMHHuaqM18YrHY.Ruestungen"
                        :key="index"
                        >
                        <v-list-item-title>{{ item.name }}</v-list-item-title><!-- Hier muss noch die Verlinkung zu den einzelnen Seiten hin, sobald es sie gibt -->
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-menu>

      <v-menu offset-y offset-x open-on-hover bottom>
        <template v-slot:activator="{on}">
          <v-card class="mt-10 ml-10"><v-btn v-on="on">Sonstiges</v-btn></v-card>
        </template>
      </v-menu>

    </v-navigation-drawer>

    <!-- <iframe src="http://www.aborea.de/" width="2000" height="1000"></iframe> Später eventuell möglich diese Seite einzubetten. Dazu müsste ich eine Anfrage an das Aborea Team schicken, damit diese Meine Webseite für iframe Zugriffe zulassen(x-frame-options) -->

  </v-container>
  <v-container v-else>
    <v-app-bar color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-card :height="50" class="elevation-0" @click="start()"><v-img src="@/assets/aborea.png" alt="Aborea" :max-height="height" :width="50*2.2"></v-img></v-card>
      <v-spacer></v-spacer>
      <v-icon @click="logged ? logout() : login()">{{logged ? "mdi-logout" : "mdi-login"}}</v-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app temporary>
      <v-card :height="height" class="elevation-0"><v-img src="@/assets/aborea.png" alt="Aborea" :height="100" :width="height*2.2"></v-img></v-card>
      <v-list nav>
        <v-list-item-group>
          <v-list-item @click="openData('Voelker')">
            <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
            <v-list-item-title>Völker</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openData('Berufe')">
            <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
            <v-list-item-title>Berufe</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openData('Spruchlisten')">
            <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
            <v-list-item-title>Spruchlisten</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openData('Ruestungen')">
            <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
            <v-list-item-title>Rüstungen</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openData('Waffen')">
            <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
            <v-list-item-title>Waffen</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="spielleiter"  @click="openData('Gegner')">
            <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
            <v-list-item-title>Gegner</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openData('Sonstiges')">
            <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
            <v-list-item-title>Sonstiges</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
/* eslint-disable */
import {auth} from '../store/config/firebase'

export default {
  data: () => ({
    height:100,
    drawer:false,
  }),

  computed:{
    spielleiter(){
      return this.$store.getters.getSpielleiter
    },
    logged(){
      if (auth.currentUser){
        return true
      } else {
        return false
      }
    },
    disableMonster: function(){
      return !(this.$route.name == "spielleiter")
    },
    alleDavon: function(){
      return this.$store.getters['Tabellen/getAlle']
    },
    // voelker: function(){
    //    return this.$store.getters['Tabellen/getVoelker']
    //  },
    // berufe: function(){
    //   return this.$store.getters.getBerufe
    // },
    // monster: function(){
    //   return this.$store.getters.getGegner
    // },
    // spruchlisten: function(){
    //   return this.$store.getters.getSpruchlisten
    // },
    // ruestungen: function(){
    //   return this.$store.getters.getRuestungen
    // },
    // waffen: function(){
    //   return this.$store.getters.getWaffen
    // }
    
  },

  methods:{
    login(){
      window.open("/","_self")
    },
    logout(){
      // console.log("logout")
      auth.signOut()
    },
    openData(name){
      // console.log("Name", name)
      let string = "/#/data/"+name
      window.open(string,"_self")
      // console.log("Ende")
    },
    start(){
      window.open("/#/","_self")
    }

    // test(){
    //   if (this.$route.name == 'home'){
    //     console.log("Hier würde man dann rausgeworfen werden")
    //   }else if(this.$route.name == 'spieler'){
    //   database.collection('Spiele').doc('OnstuikhcdlTdhK9euSg').collection('Spieler').doc(this.$route.params.name).get().then(doc =>{
    //     var temp = doc.data()
    //     temp.aktiv = false
    //     console.log(temp)
    //     database.collection('Spiele').doc('OnstuikhcdlTdhK9euSg').collection('Spieler').doc(this.$route.params.name).set(temp).then(()=>{window.open('/','_self')})
    //     })
    //   }else if(this.$route.name == 'spielleiter'){
    //     window.open('/','_self')
    //   }else{
    //     window.open('/','_self')
    //   }
    // },
  }
};
</script>

import * as Firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const config = {
  apiKey: "AIzaSyAsmSsxRu3E7KVGFKj96WjMneV1v99FOL4",
  authDomain: "aborea-pen-and-paper.firebaseapp.com",
  databaseURL: "https://aborea-pen-and-paper.firebaseio.com",
  projectId: "aborea-pen-and-paper",
  storageBucket: "aborea-pen-and-paper.appspot.com",
  messagingSenderId: "948323467581",
  appId: "1:948323467581:web:98fcaeaa92fe96d1ab8554"
}

Firebase.initializeApp(config)

const storage = Firebase.storage()
export {storage}

const auth = Firebase.auth()
export {auth}

const db = Firebase.firestore()
export {db}

function initFirebase() {
  return new Promise((resolve, reject) => {
    Firebase.firestore().enablePersistence({ synchronizeTabs: true})
      .then(resolve)
      .catch(err => {
        if (err.code === 'failed-precondition') {
          reject(err)
          // Multiple tabs open, persistence can only be
          // enabled in one tab at a a time.
        } else if (err.code === 'unimplemented') {
          reject(err)
          // The current browser does not support all of
          // the features required to enable persistence
        }
      })
  })
}



export { Firebase, initFirebase }


const tabellen = {
  firestorePath: 'Spiele/{spielID}/Tabellen',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'Tabellen',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getLoaded: state => {
      return state.data.AllgemeineSpielerdaten
    },

    getProdukte: state => {
      return state.data.Produkte
    },

    getAlleProdukte: state => {
      let list = []
      for (let i in state.data.Produkte){
        if (i != "created_at" && i != "created_by" && i != "id" && i != "updated_at" && i != "updated_by" && i!= "Waffen" && i != "Ruestungen"){
          state.data.Produkte[i].forEach(element => {
            element.bereich = i
            element.preisEmpfohlen = element.preis
            list.push(element)
          });
        }
      }
      return list
    },
    getWirklichAlleProdukte: state => {
      let list = []
      for (let i in state.data.Produkte){
        if (i != "created_at" && i != "created_by" && i != "id" && i != "updated_at" && i != "updated_by"){
          state.data.Produkte[i].forEach(element => {
            element.bereich = i
            element.preisEmpfohlen = element.preis
            list.push(element)
          });
        }
      }
      return list
    },
    getSonstiges: state => {
      return state.data.Produkte
    },

    getAlle: state => {
      return state.data
    },
    getVoelker: state => {
      return state.data.AllgemeineSpielerdaten.Voelker
    },
    getBerufe: state => {
      return state.data.AllgemeineSpielerdaten.Berufe
    },
    getWaffen: state => {
      return state.data.Produkte.Waffen
    },
    getRuestungen: state => {
      return state.data.Produkte.Ruestungen
    },
    getSpruchlisten: state => {
      return state.data.AllgemeineSpielerdaten.Spruchlisten
    },
    getSpruchliste: state => name => {
      state.data.AllgemeineSpielerdaten.Spruchlisten.forEach(element => {
        if (element.name == name){
          return element
        }
      });
    },
    getGegner: state => {
      return state.data.Gegner.gegner
    },
    getAttribute: state => {
      return state.data.AllgemeineSpielerdaten.Attribute
    },
    getBoniAttribute: state => {
      return state.data.AllgemeineSpielerdaten.Bonusattribute
    },
    getWaffengruppen: state => {
      return state.data.AllgemeineSpielerdaten.waffengruppen
    }
  },
  mutations: {},
  actions: {},
}

export default tabellen
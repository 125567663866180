<template>
  <v-container>
    <v-card>
      <v-card-title @click="open = !open">Kampf</v-card-title>
      <v-card-text v-if="open">
        <v-autocomplete
          :disabled="!kampfStatus == 0"
          chips
          clearable
          deletable-chips
          multiple
          label="Spielerauswahl"
          :items="spielerFuerKaempfen"
          item-value="id"
          item-text="name"
          v-model="spielerAuswahl"
          @input="spielerInput"
        ></v-autocomplete>
        <v-autocomplete
          :disabled="!kampfStatus == 0"
          chips
          clearable
          deletable-chips
          multiple
          label="NPCauswahl"
          :items="npcFuerKaempfen"
          item-text="name"
          item-value="id"
          v-model="npcAuswahl"
          @input="npcInput"
        ></v-autocomplete>
        <v-combobox
          :disabled="!kampfStatus == 0"
          chips
          clearable
          deletable-chips
          multiple
          label="Gegnerauswahl"
          :items="alleGegner"
          return-object
          item-text="name"
          v-model="gegnerAuswahl"
          @input="neuerGegner"
          append-outer-icon="mdi-plus"
          @click:append-outer="
            gegnerAuswahl.push('');
            neuerGegner(gegnerAuswahl);
          "
        ></v-combobox>
        <v-card v-if="kaempfend.length != 0">
          <v-card-text>
            <v-row dense>
              <v-col v-for="(item, index) in kaempfend" :key="index" cols="4">
                <v-card>
                  <v-card-title
                    >{{ item.name }}
                    <v-tooltip right v-if="item.infos" max-width="700">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="grey lighten-1" dark v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span v-html="gegnerInfos(item.infos)"></span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-chip>
                      <v-icon x-small v-if="item.typ == 'gegner'" @click="item.tp -= 1" left>mdi-minus</v-icon>
                      TP: {{ getTP(item) }}
                      <v-icon x-small v-if="item.typ == 'gegner'" @click="item.tp += 1" right>mdi-plus</v-icon>
                    </v-chip>
                    <v-chip>Ini: {{ item.ini }}</v-chip>
                    <v-btn
                      v-if="kampfStatus == 0 && kaempfend.length - 1 != index && item.ini == kaempfend[index + 1].ini"
                      @click="switchKaempfer(index)"
                    >
                      <v-icon>mdi-swap-horizontal</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="kampfStatus == 0">
                    <v-text-field
                      type="number"
                      v-model="item.anzahlWaffenwechsel"
                      label="IniMalus"
                      @blur="weaponChange(index)"
                    ></v-text-field>
                    <v-select
                      :items="item.waffen"
                      v-model="item.aktiveWaffe"
                      item-text="name"
                      return-object
                      label="Waffenauswahl"
                      @input="weaponChange(index)"
                    >
                      <template v-slot:append-outer v-if="item.aktiveWaffe.beschreibung">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="grey lighten-1" dark v-bind="attrs" v-on="on">
                              mdi-information
                            </v-icon>
                          </template>
                          <span>{{ item.aktiveWaffe.beschreibung }}</span>
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-card-text>

                  <v-card-text v-if="kampfStatus == 1">
                    <v-row>
                      <v-checkbox label="Fliehen" v-model="item.fliehen"></v-checkbox>
                      <v-spacer></v-spacer>
                      <v-checkbox label="Überspringen" v-model="item.ueberspringen"></v-checkbox>
                    </v-row>
                    <v-slider
                      :disabled="item.fliehen"
                      :min="item.aktiveWaffe.kb < 0 ? item.aktiveWaffe.kb : 0"
                      :max="item.aktiveWaffe.kb"
                      v-model="item.sliderAuswahlKB"
                      @input="
                        item.db = item.aktiveWaffe.kb - item.sliderAuswahlKB;
                        item.ob = item.sliderAuswahlKB;
                      "
                    >
                      <template v-slot:prepend> DB: {{ item.db }} </template>
                      <template v-slot:append> OB: {{ item.ob }} </template>
                    </v-slider>
                    <v-chip v-for="(i, index) in item.dbAufteilung" :key="index" class="mb-1" :disabled="item.fliehen">
                      <v-icon x-small left @click="i.db != 0 ? (i.db -= 1) : i.db">mdi-minus</v-icon>
                      {{ i.name }}: {{ i.db }}
                      <v-icon x-small right @click="item.db > sumDPs(item.dbAufteilung) ? (i.db += 1) : i.db"
                        >mdi-plus</v-icon
                      >
                    </v-chip>
                    <v-select
                      :disabled="item.fliehen || item.ueberspringen"
                      label="Wen Angreifen"
                      :items="item.dbAufteilung"
                      item-text="name"
                      item-value="id"
                      v-model="item.angreifen"
                    ></v-select>
                  </v-card-text>

                  <v-card-text v-if="kampfStatus == 2">
                    <v-text-field label="Blutung" v-model="item.blutung" outlined></v-text-field>
                    <WuerfelEingabe
                      v-on:neuerWuerfelEingabe="getErgebnis($event, item)"
                      v-if="angreiferIndex == kaempfend.findIndex(el => el.id == item.id)"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="kampfStatus == 0" @click="startRunde">Start Ansagen</v-btn>
            <v-btn v-else-if="kampfStatus == 1" @click="startKampf">Start Kämpfen</v-btn>
            <v-btn v-else :disabled="angreiferIndex != kaempfend.length" @click="kampfrundeBeenden"
              >Kampfrunde beenden</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-card-text>
      <v-card-actions v-if="open">
        <v-btn @click="$emit('reset')">Reset</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogAngriff.open" persistent max-width="600" v-if="kampfStatus == 2">
      <v-card>
        <v-card-title> {{ dialogAngriff.angreifer.name }} greift {{ dialogAngriff.opfer.name }} an </v-card-title>
        <v-card-text>
          <v-chip>Würfelergebnis: {{ dialogAngriff.wuerfelErgebnis }} </v-chip>
          <v-chip>
            <v-icon
              x-small
              left
              @click="
                dialogAngriff.angriffswert = parseInt(dialogAngriff.angriffswert) - 1;
                ermittleSchaden();
              "
              >mdi-minus</v-icon
            >
            Angriffswert: {{ dialogAngriff.angriffswert }}
            <v-icon
              x-small
              right
              @click="
                dialogAngriff.angriffswert = parseInt(dialogAngriff.angriffswert) + 1;
                ermittleSchaden();
              "
              >mdi-plus</v-icon
            >
          </v-chip>
          <v-chip>
            <v-icon
              x-small
              left
              @click="
                dialogAngriff.verteidigungswert = parseInt(dialogAngriff.verteidigungswert) - 1;
                ermittleSchaden();
              "
              >mdi-minus</v-icon
            >
            Verteidigungswert: {{ dialogAngriff.verteidigungswert }}
            <v-icon
              x-small
              right
              @click="
                dialogAngriff.verteidigungswert = parseInt(dialogAngriff.verteidigungswert) + 1;
                ermittleSchaden();
              "
              >mdi-plus</v-icon
            >
          </v-chip>
          <v-chip>Waffenschaden: {{ dialogAngriff.angreifer.aktiveWaffe.schaden }} </v-chip>
          <v-row dense>
            <v-text-field label="Schaden" v-model="dialogAngriff.schaden" outlined type="number"></v-text-field>
            <v-text-field label="Blutung" v-model="dialogAngriff.blutung" outlined type="number"></v-text-field>
          </v-row>
          <p>{{ dialogAngriff.opfer.name }} verbleiben noch {{ dialogAngriff.opfer.tp - dialogAngriff.schaden }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="angriffAusfuehren">Ausführen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGegner.open" max-width="800" v-if="dialogGegner.open">
      <v-card>
        <v-card-title>Gegner</v-card-title>
        <v-card-text>
          <v-form ref="FormGegner">
            <v-row dense>
              <v-col cols="9">
                <v-text-field
                  v-model="dialogGegner.v.name"
                  outlined
                  label="Name"
                  :rules="regeln.nichtLeer"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="dialogGegner.v.sg" outlined label="SG" :rules="regeln.nichtLeer"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="dialogGegner.v.tp"
                  label="Trefferpunkte"
                  outlined
                  :rules="regeln.zahl"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="dialogGegner.v.ini" label="ini" outlined :rules="regeln.zahl"> </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="dialogGegner.v.ruestung"
                  label="Rüstung"
                  outlined
                  :rules="regeln.zahl"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-card>
              <v-card-title>Waffen</v-card-title>
              <v-card-text>
                <v-row v-for="(item, index) in dialogGegner.v.waffen" :key="index">
                  <v-text-field v-model="item.name" label="Name" dense outlined></v-text-field>
                  <v-text-field v-model="item.kb" label="KB" dense outlined></v-text-field>
                  <v-text-field v-model="item.schaden" label="Schaden" dense outlined></v-text-field>
                  <v-icon @click="dialogGegner.v.waffen.splice(index, 1)" class="mb-6">mdi-delete</v-icon>
                </v-row>
                <v-row>
                  <v-text-field v-model="dialogGegner.neueWaffe.name" label="Name" dense outlined></v-text-field>
                  <v-text-field
                    v-model="dialogGegner.neueWaffe.kb"
                    label="KB"
                    dense
                    outlined
                    type="number"
                  ></v-text-field>
                  <v-text-field
                    v-model="dialogGegner.neueWaffe.schaden"
                    label="Schaden"
                    dense
                    outlined
                    type="number"
                  ></v-text-field>
                  <v-icon
                    class="mb-6"
                    @click="
                      dialogGegner.v.waffen.push(dialogGegner.neueWaffe);
                      dialogGegner.neueWaffe = { name: '', schaden: 0, kb: 0 };
                    "
                    >mdi-plus</v-icon
                  >
                </v-row>
              </v-card-text>
            </v-card>

            <v-row class="mt-5">
              <v-col cols="7">
                <v-text-field
                  v-model="dialogGegner.anzahlHinzufuegen"
                  label="Wie of willst du diesen Gegner haben"
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn @click="neueGegnerHinzufuegen">Gegner hinzufügen</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import WuerfelEingabe from "./WuerfelEingabe";

export default {
  components: { WuerfelEingabe },
  data: () => ({
    open: false,
    kaempferVorlage: {
      typ: "",
      name: "",
      id: "",
      anzahlWaffenwechsel: 0,
      waffeGewechselt: false,
      waffen: [],
      aktiveWaffe: {},
      vorherigeAktiveWaffe: 0,
      tp: 0,
      ruestung: 0,
      blutung: 0,
      ini: 0,
      sliderAuswahlKB: 0,
      db: 0,
      ob: 0,
      dbAufteilung: [],
      angreifen: undefined,
      fliehen: false,
      ueberspringen: false
    },
    dialogAngriff: {
      open: false,
      schaden: 0,
      blutung: 0,
      angreifer: {},
      opfer: {},
      angriffswert: 0,
      verteidigungswert: 0,
      wuerfelErgebnis: 0
    },
    dialogGegner: {
      open: false,
      anzahlGegner: 0,
      anzahlHinzufuegen: 1,
      neueWaffe: {
        name: "",
        kb: 0,
        schaden: 0
      },
      v: {}
    },
    kaempfend: [],
    angreiferIndex: 0,
    spielerAuswahl: [],
    npcAuswahl: [],
    gegnerAuswahl: [],
    gegnerZaehler: 0,
    kampfStatus: 0,
    regeln: {
      nichtLeer: [val => val.length > 0 || "Darf nicht leer sein"],
      zahl: [val => !isNaN(parseInt(val)) || "Muss eine ganze Zahl sein"]
    }
  }),
  computed: {
    alleSpieler() {
      return this.$store.getters["Spieler/getAlleSpieler"];
    },
    spielerFuerKaempfen() {
      let spielerListe = Object.keys(this.alleSpieler);
      let ergebnis = [];
      spielerListe.forEach(id => {
        let spieler = this.alleSpieler[id];
        let v = JSON.parse(JSON.stringify(this.kaempferVorlage));
        let waffen = JSON.parse(JSON.stringify(spieler.waffen));
        if (spieler.magiepunkteGesamt > 0) {
          let kb = 0;
          if (spieler.beruf == "Barde" || spieler.beruf == "Prister" || spieler.beruf == "Schamane") {
            kb += spieler.boni.ch;
          } else {
            kb += spieler.boni.in;
          }
          waffen.push({
            name: "Gezielter Zauber",
            ini: spieler.boni.ge + 1,
            schaden: 0,
            kb: spieler.fertigkeitsraenge["Gezielte Sprüche"].rang + kb
          });
        }
        let waffenlos = spieler.fertigkeitsraenge['Waffe "Waffenlos"'];
        waffenlos = waffenlos ? waffenlos.rang : 0;
        waffen.push({
          ini: spieler.boni.ge + 1,
          kb: waffenlos + spieler.boni.st,
          schaden: -2,
          name: "Fäuste"
        });
        v.typ = "spieler";
        v.name = spieler.name;
        v.id = id;
        v.waffen = waffen;
        v.anzahlWaffenwechsel = 0;
        v.aktiveWaffe = v.waffen[0];
        v.vorherigeAktiveWaffe = v.aktiveWaffe;
        v.tp = spieler.trefferpunkteAkt;
        v.blutung = 0;
        v.ini = v.aktiveWaffe.ini;
        v.sliderAuswahlKB = 0;
        v.db = v.aktiveWaffe.kb;
        v.ob = 0;
        v.angreifen = undefined;
        v.fliehen = false;
        v.ruestung = spieler.ruestung;
        ergebnis.push(v);
      });
      return ergebnis;
    },
    alleNPC() {
      return this.$store.getters["NPCs/getAlle"];
    },
    npcFuerKaempfen() {
      let npcListe = Object.keys(this.alleNPC);
      let ergebnis = [];
      npcListe.forEach(id => {
        let npc = this.alleNPC[id];
        let v = JSON.parse(JSON.stringify(this.kaempferVorlage));
        let waffen = [];
        npc.waffen.forEach(element => {
          waffen.push({
            kb: element.kb,
            name: element.name,
            schaden: element.schaden,
            ini: npc.ini
          });
        });
        waffen.push({
          ini: npc.ini,
          kb: 1,
          schaden: -2,
          name: "Fäuste"
        });
        v.typ = "npc";
        v.name = npc.name;
        v.id = npc.name;
        v.waffen = waffen;
        v.anzahlWaffenwechsel = 0;
        v.aktiveWaffe = v.waffen[0];
        v.vorherigeAktiveWaffe = v.aktiveWaffe;
        v.tp = npc.trefferpunkteAkt;
        v.blutung = 0;
        v.ini = npc.ini;
        v.sliderAuswahlKB = 0;
        v.db = v.aktiveWaffe.kb;
        v.ob = 0;
        v.angreifen = undefined;
        v.fliehen = false;
        v.ruestung = npc.ruestung;
        ergebnis.push(v);
      });
      return ergebnis;
    },
    alleGegner() {
      return this.$store.getters["Tabellen/getGegner"];
    }
  },
  methods: {
    spielerInput(e) {
      // Spieler zu kaempfend hinzufuegen
      e.forEach(spieler => {
        if (this.kaempfend.findIndex(e => e.id == spieler) == -1) {
          let obj = this.spielerFuerKaempfen.find(e => e.id == spieler);
          this.kaempfend.push(obj);
          this.sortiereKaempfend();
        }
      });
      // Spieler aus kaempfend entfernen
      this.kaempfend.forEach((kaempfer, index) => {
        console.log(kaempfer.name, index, this.kaempfend.length);
        if (kaempfer.typ == "spieler") {
          if (this.spielerAuswahl.findIndex(e => e == kaempfer.id) == -1) {
            this.kaempfend.splice(index, 1);
          }
        }
      });
    },
    npcInput(e) {
      // Spieler zu kaempfend hinzufuegen
      e.forEach(npc => {
        if (this.kaempfend.findIndex(e => e.id == npc) == -1) {
          let obj = this.npcFuerKaempfen.find(e => e.id == npc);
          this.kaempfend.push(obj);
          this.sortiereKaempfend();
        }
      });
      // Spieler aus kaempfend entfernen
      this.kaempfend.forEach((kaempfer, index) => {
        if (kaempfer.typ == "npc") {
          if (this.npcAuswahl.findIndex(e => e == kaempfer.id) == -1) {
            this.kaempfend.splice(index, 1);
          }
        }
      });
    },
    neuerGegner(event) {
      console.log(event);
      if (this.dialogGegner.anzahlGegner < event.length) {
        console.log("Es wurde ein neuer Gegner hinzugefügt");
        let tempGegner = event[event.length - 1];
        this.gegnerAuswahl.pop();
        this.dialogGegner.v = JSON.parse(JSON.stringify(this.kaempferVorlage));
        if (typeof tempGegner == "string") {
          this.dialogGegner.v.name = tempGegner;
          this.dialogGegner.v.sg = 0;
          this.dialogGegner.v.waffen = [];
        } else {
          console.log("Hier");
          this.dialogGegner.v.sg = tempGegner.sg;
          this.dialogGegner.v.name = tempGegner.name;
          this.dialogGegner.v.ini = tempGegner.ini;
          this.dialogGegner.v.ruestung = tempGegner.ruestung;
          this.dialogGegner.v.tp = tempGegner.tp;
          this.dialogGegner.v.typ = "gegner";
          this.dialogGegner.v.waffen = JSON.parse(JSON.stringify(tempGegner.waffen));
          console.log("Ende");
        }
        this.dialogGegner.v.infos = tempGegner.infos;
        this.dialogGegner.open = true;
      } else {
        this.dialogGegner.anzahlGegner = event.length;
        console.log("Gegner muss aus kaempfend gelöscht werden");
        this.kaempfend.forEach((kaempfer, index) => {
          if (kaempfer.typ == "gegner") {
            if (this.gegnerAuswahl.findIndex(e => e.id == kaempfer.id) == -1) {
              this.kaempfend.splice(index, 1);
            }
          }
        });
      }
    },
    neueGegnerHinzufuegen() {
      let types = [
        { tp: 1, kb: 0, ini: 0, name: "Normal" },
        { tp: 1.2, kb: 0, ini: -2, name: "Tank" },
        { tp: 0.75, kb: 0, ini: 2, name: "Schnell" },
        { tp: 0.85, kb: 2, ini: -1, name: "DPS" }
      ];
      if (this.$refs.FormGegner.validate()) {
        this.dialogGegner.v.waffen.push({
          kb: 0,
          schaden: -2,
          name: "Fäuste"
        });

        for (let i = 0; i < this.dialogGegner.anzahlHinzufuegen; i++) {
          let type = types[i % types.length];
          let temp = JSON.parse(JSON.stringify(this.dialogGegner.v));
          temp.infos[type.name] = "";
          temp.ini = parseInt(temp.ini) + type.ini;
          temp.waffen.forEach(el => {
            el.ini = temp.ini;
            el.kb = parseInt(el.kb) + type.kb;
          });
          temp.tp = parseInt(parseInt(temp.tp) * type.tp);
          temp.name = temp.name + " - " + this.gegnerZaehler;
          temp.id = temp.name;
          temp.aktiveWaffe = temp.waffen[0];
          temp.db = temp.aktiveWaffe.kb;
          this.gegnerZaehler += 1;
          this.gegnerAuswahl.push(temp);
          this.kaempfend.push(temp);
        }
        this.dialogGegner.anzahlGegner = this.gegnerAuswahl.length;
        this.sortiereKaempfend();
        this.dialogGegner.open = false;
      }
    },
    sortiereKaempfend() {
      this.kaempfend.sort((a, b) => b.ini - a.ini);
    },
    switchKaempfer(a) {
      let obj1 = this.kaempfend[a];
      this.kaempfend.splice(a, 1);
      this.kaempfend.splice(a + 1, 0, obj1);
    },
    weaponChange(index) {
      if (
        this.kaempfend[index].aktiveWaffe == this.kaempfend[index].vorherigeAktiveWaffe &&
        this.kaempfend[index].waffeGewechselt
      ) {
        this.kaempfend[index].waffeGewechselt = false;
        this.kaempfend[index].anzahlWaffenwechsel = parseInt(this.kaempfend[index].anzahlWaffenwechsel) - 1;
      }
      if (
        this.kaempfend[index].aktiveWaffe != this.kaempfend[index].vorherigeAktiveWaffe &&
        !this.kaempfend[index].waffeGewechselt
      ) {
        this.kaempfend[index].waffeGewechselt = true;
        this.kaempfend[index].anzahlWaffenwechsel = parseInt(this.kaempfend[index].anzahlWaffenwechsel) + 1;
      }
      this.kaempfend[index].ini = this.kaempfend[index].aktiveWaffe.ini - this.kaempfend[index].anzahlWaffenwechsel;
      this.kaempfend[index].db = this.kaempfend[index].aktiveWaffe.kb;
      this.kaempfend[index].ob = 0;
      this.kaempfend[index].sliderAuswahlKB = 0;
      this.sortiereKaempfend();
    },
    getTP(item) {
      if (item.typ == "spieler") {
        return this.alleSpieler[item.id].trefferpunkteAkt;
      } else if (item.typ == "npc") {
        return this.alleNPC[item.id].trefferpunkteAkt;
      } else {
        return item.tp;
      }
    },
    startRunde() {
      this.kaempfend.forEach(element => {
        element.dbAufteilung = this.kaempferDBUebersicht(element);
        element.angreifen = undefined;
      });
      this.kampfStatus = 1;
    },
    kaempferDBUebersicht(kaempfer) {
      let ergebnis = [];
      this.kaempfend.forEach(element => {
        if (element.id != kaempfer.id) {
          ergebnis.push({
            id: element.id,
            name: element.name,
            db: 0
          });
        }
      });
      return ergebnis;
    },
    startKampf() {
      let temp = true;
      this.kaempfend.forEach(element => {
        console.log(element);
        if (!element.fliehen && !element.ueberspringen && element.angreifen == undefined) {
          temp = false;
        }
      });
      if (temp) {
        this.angreiferIndex = -1;
        this.naechsterAngreifer();
        this.kampfStatus = 2;
      } else {
        this.$store.dispatch("setSnackbar", { text: "Wähle aus wer angegriffen wird", color: "error", time: 4 });
      }
    },
    sumDPs(item) {
      let ergebnis = 0;
      item.forEach(element => {
        ergebnis += element.db;
      });
      return ergebnis;
    },
    getErgebnis(wert, angreifer) {
      this.dialogAngriff.wuerfelErgebnis = wert;
      this.dialogAngriff.opfer = this.kaempfend.filter(el => el.id == angreifer.angreifen)[0];
      this.dialogAngriff.angreifer = angreifer;
      this.dialogAngriff.angriffswert = wert + angreifer.ob;
      if (this.dialogAngriff.opfer.fliehen) {
        let iniBonus = parseInt(this.dialogAngriff.opfer.ini) - parseInt(this.dialogAngriff.angreifer.ini);
        iniBonus = iniBonus < 0 ? 0 : iniBonus;
        this.dialogAngriff.verteidigungswert = iniBonus + this.dialogAngriff.opfer.ruestung;
      } else {
        this.dialogAngriff.verteidigungswert =
          parseInt(this.dialogAngriff.opfer.dbAufteilung.find(el => el.id == angreifer.id).db) +
          parseInt(this.dialogAngriff.opfer.ruestung);
      }
      this.ermittleSchaden();
      this.dialogAngriff.blutung = Math.floor(this.dialogAngriff.schaden / 5);
      if (wert > 10 && this.dialogAngriff.blutung < 1) {
        this.dialogAngriff.blutung = 1;
      }
      this.dialogAngriff.open = true;
    },
    ermittleSchaden() {
      let differenz = parseInt(this.dialogAngriff.angriffswert) - parseInt(this.dialogAngriff.verteidigungswert);
      if (differenz > 0 && this.dialogAngriff.wuerfelErgebnis != 1) {
        this.dialogAngriff.schaden =
          differenz +
          parseInt(this.dialogAngriff.angreifer.aktiveWaffe.schaden) +
          Math.floor(this.dialogAngriff.wuerfelErgebnis / 10) *
            parseInt(this.dialogAngriff.angreifer.aktiveWaffe.schaden);
        this.dialogAngriff.schaden = this.dialogAngriff.schaden > 0 ? this.dialogAngriff.schaden : 1;
      } else {
        this.dialogAngriff.schaden = 0;
      }
    },
    angriffAusfuehren() {
      this.dialogAngriff.opfer.blutung += this.dialogAngriff.blutung;
      this.dialogAngriff.opfer.tp -= parseInt(this.dialogAngriff.schaden);
      if (this.dialogAngriff.opfer.typ == "spieler") {
        let spieler = this.alleSpieler[this.dialogAngriff.opfer.id];
        spieler.trefferpunkteAkt -= parseInt(this.dialogAngriff.schaden);
        this.dialogAngriff.opfer.tp = spieler.trefferpunkteAkt;
        this.$store.dispatch("Spieler/set", spieler);
      } else if (this.dialogAngriff.opfer.typ == "npc") {
        let npc = this.alleNPC[this.dialogAngriff.opfer.id];
        npc.trefferpunkteAkt -= parseInt(this.dialogAngriff.schaden);
        this.dialogAngriff.opfer.tp = npc.trefferpunkteAkt;
        this.$store.dispatch("NPCs/set", npc);
      }
      this.naechsterAngreifer();
    },
    naechsterAngreifer() {
      this.dialogAngriff.open = false;
      this.angreiferIndex += 1;
      if (this.angreiferIndex == this.kaempfend.length) {
        console.log("Vorbereitung Ende der Kampfrunde");
      } else {
        this.dialogAngriff.angreifer = this.kaempfend[this.angreiferIndex];
        this.dialogAngriff.opfer = this.kaempfend[this.angreiferIndex];
        if (this.kaempfend[this.angreiferIndex].fliehen || this.kaempfend[this.angreiferIndex].ueberspringen) {
          this.naechsterAngreifer();
        } else {
          if (this.kaempfend[this.angreiferIndex].typ != "spieler") {
            this.dialogAngriff.angreifer = this.kaempfend[this.angreiferIndex];
            let zufallszahl = Math.ceil(Math.random() * 10);
            while (zufallszahl % 10 == 0) {
              zufallszahl += Math.ceil(Math.random() * 10);
            }
            this.getErgebnis(zufallszahl, this.kaempfend[this.angreiferIndex]);
          }
        }
      }
    },
    kampfrundeBeenden() {
      this.kaempfend.forEach(element => {
        element.vorherigeAktiveWaffe = element.aktiveWaffe;
        element.waffeGewechselt = false;
        if (element.typ == "spieler") {
          let spieler = this.alleSpieler[element.id];
          spieler.trefferpunkteAkt -= parseInt(element.blutung);
          element.tp = spieler.trefferpunkteAkt;
          this.$store.dispatch("Spieler/set", spieler);
        } else if (element.typ == "npc") {
          let npc = this.alleNPC[element.id];
          npc.trefferpunkteAkt -= parseInt(element.blutung);
          element.tp = npc.trefferpunkteAkt;
          this.$store.dispatch("NPCs/set", npc);
        } else {
          element.tp -= element.blutung;
        }
      });
      this.angreiferIndex = 0;
      this.kampfStatus = 0;
    },
    gegnerInfos(obj) {
      let arr = Object.keys(obj);
      let ergebnis = "";
      arr.forEach(element => {
        ergebnis = ergebnis + "<p><h5>" + element + ":</h5>" + obj[element] + "</p>";
      });
      return ergebnis;
    }
  }
};
</script>

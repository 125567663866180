<template>
  <v-container>
    <v-card>
      <v-card-title @click="open = !open">Zaubern</v-card-title>
      <v-card-text v-if="open">
        <v-row>
          <v-col cols="6">
            <v-select
              :items="spielerAlsListe"
              item-text="name"
              return-object
              v-model="spieler"
              @change="zauber = undefined"
              clearable
              label="Spieler">
            </v-select>

            <div v-if="spieler">
              <v-autocomplete
                v-model="zauber"
                :items="zauberAuswahl"
                label="Zauber"
                item-text="name"
                clearable
                return-object
                >
                <template v-slot:item=" {item} ">
                  <v-list-item-content>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.liste}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <!-- {{item.name}} <v-spacer> <v-divider vertical></v-divider> </v-spacer> {{item.liste}} -->
                </template>
              </v-autocomplete>
            </div>
          </v-col>

          <v-col v-if="zauber">
            <v-card :key="refresh">
              <v-card-title> {{zauber.name}} <v-spacer></v-spacer> <v-avatar color="purple lighten-3" size="32">{{zauber.rang}}</v-avatar> </v-card-title>
              <v-card-subtitle> {{zauberBeschreibung}} </v-card-subtitle>
              <v-card-text>
                <div v-for="(item,index) in zauber.werte" :key="index">
                  <v-icon @click="kleiner(item,index); refresh = !refresh">mdi-minus</v-icon>
                  {{index}}
                  <v-icon @click="zauber.werte[index] += 1; refresh = !refresh">mdi-plus</v-icon>
                  Kosten: {{zauber[index].mp}}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-sheet>Kosten: {{kosten}}</v-sheet>
                <v-spacer></v-spacer>
                <v-btn @click="zaubern" :disabled="spieler.magiepunkteAkt < kosten">Zaubern</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
        Die Fähigkeit wird nicht ausgeführt (auch nicht beim Heal)
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    spieler: undefined,
    zauber:undefined,
    open: false,
    refresh: false,
  }),
  computed:{
    spielerListe(){
      return this.$store.getters["Spieler/getAlleSpieler"]
    },
    spielerAlsListe(){
      let temp = []
      Object.keys(this.spielerListe).forEach(element => {
        temp.push(this.spielerListe[element])
      });
      return temp
    },
    zauberAuswahl(){
      // console.log("Neu")
      let ergebnis = []
      for (let i in this.spieler.spruchlisten){
        // console.log(i, this.spieler.spruchlisten)
        this.spieler.spruchlisten[i].zauber.forEach(element => {
          let temp = element
          temp.liste = i
          temp.spieler = name
          let werte = this.werteListe(element.beschreibung)
          temp.werte = {}
          for (let wert in werte){
            temp.werte[werte[wert]] = 0
          }
          ergebnis.push(temp)
        });
      }
      // for (let liste in this.dataSpruchlisten){
      //   for (let z in this.dataSpruchlisten[liste].zauber){
      //     let temp = this.dataSpruchlisten[liste].zauber[z]
      //     temp["liste"] = this.dataSpruchlisten[liste].name
      //     temp["spieler"] = name
      //     let werte = this.werteListe(this.dataSpruchlisten[liste].zauber[z].beschreibung)
      //     temp["werte"] = {}
      //     for (let wert in werte){
      //       temp.werte[werte[wert]] = 0
      //     }
      //     ergebnis.push(temp)
      //   }
      // }
      return ergebnis
    },
    zauberBeschreibung(){
      //eslint-disable-next-line
      let unwichtig = this.refresh
      let temp = this.zauber.werte
      let text = this.zauber.beschreibung
      for (let i in temp) {
        let ergebnis = this.zauber[i].startWirkung + this.zauber[i].extraWirkung * temp[i]
        text = text.replace("{{"+i+"}}",ergebnis)
      }
      return text

    },
    kosten(){
      //eslint-disable-next-line
      let unwichtig = this.refresh
      let ergebnis = this.zauber.rang
      for (let i in this.zauber.werte){
        ergebnis += this.zauber[i].mp * this.zauber.werte[i]
      }
      return ergebnis
    }
  },
  methods:{
    werteListe(text){
      let ergebnis = []
      let liste = text.split("{{")
      let laenge = liste.length
      for (let i = 1; i < laenge; i++) {
        ergebnis.push(liste[i].split("}}")[0])
      }
      return ergebnis
    },
    zaubern(){
      this.spieler.magiepunkteAkt -= this.kosten
      for (let i in this.zauber.werte){
        this.zauber.werte[i] = 0
      }
      this.refresh = !this.refresh
      this.$store.dispatch("Spieler/set",this.spieler)
    },
    kleiner(item,index){
      if (item != 0){
        this.zauber.werte[index] -= 1
      }
    }
  }
}
</script>
